import { FORM_CONFIGURATION_STORAGE_KEY } from 'config/env';
import { loadString, saveString } from 'utils/storage';

const FORM_CONFIGURATION_DATA = {
    samples: {
        sampleName: true,
        country: true,
        sampleGrade: true,
        sampleType: true,
        purchaseGrade: true,
        supplierOrManufacturer: true,
        producerName: true,
        purchaseContractReference: true,
        strategy: true,
        customer: true,
        customerCode: true,
        salesContractReference: true,
        receivedOn: true,
        dateDispatch: true,
        dateArrival: true,
        dateResults: true,
        harvest: true,
        externalIdentification: true,
        referenceNumber: true,
        sampleReference: true,
        description: true,
        species: true,
        varietalsTags: true,
        coffeeProcessing: true,
        certification: true,
        cuppingProtocol: true,
        receivedWeight: true,
        cropYear: true,
        numberOfBags: true,
        bagWeight: true,
        moisture: true,
        waterActivity: true,
        density: true,
        temperature: true,
        mass: true,
        volume: true,
        warehouse: true,
        sampleLocation: true,
        shipmentDate: true,
        cargoSeel: true,
        containerNumber: true,
        lotNumber: true,
        courier: true,
        trackingNumber: true,
        decaf: true,
        otaTestValue: true,
        basketCode: true,
        contractWeight: true,
        notesAndRemarks: true
    },
}

export const getFormConfigurationData = async () => {
    const dataString = await loadString(FORM_CONFIGURATION_STORAGE_KEY);
    if (!dataString) {
        await saveString(FORM_CONFIGURATION_STORAGE_KEY, JSON.stringify(FORM_CONFIGURATION_DATA));
        return FORM_CONFIGURATION_DATA;
    }

    const { samples: savedSamplesData} = JSON.parse(dataString);
    const mergedCuppingData = {};

    for (const key in FORM_CONFIGURATION_DATA.samples) {
        mergedCuppingData[key] = savedSamplesData.hasOwnProperty(key) ? savedSamplesData[key] : FORM_CONFIGURATION_DATA.samples[key];
    }

    return {
        samples: mergedCuppingData
    };
}

export const saveConfigurationData = async (samples) => {
    const data = {
        samples
    };
    await saveString(FORM_CONFIGURATION_STORAGE_KEY, JSON.stringify(data));
}

export const convertConfigurationData = async () => {
    const storeData = await getFormConfigurationData();
    return {
        samples: Object.keys(storeData.samples).map(config => ({
            label: config,
            value: storeData.samples[config]
        })),
    }
}