import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { colors } from "assets";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      borderBottom: `1px solid ${colors.border.primary}`,
      cursor: 'pointer',

      '&:first-child': {
        borderTop: `1px solid ${colors.border.primary}`
      }
    },
    score: {
      display: 'flex',
      alignItems: 'center',

      '& > div': {
        textAlign: 'center',
      },

      '& > svg': {
        width: 24,
        height: 24,
      }
    },
    header: {
      padding: theme.spacing(2),
      paddingBottom: '4px',
      backgroundColor: '#EAE7F0',
      textAlign: 'center'
    },
    nav: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row'
    },
    navButton: {
      width: 28,
      height: 28,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.grey[500],
      }
    },
    sampleInfoContainer: {
      padding: '8px 16px 8px 16px',
      backgroundColor: 'white',
      borderBottom: `1px solid ${colors.border.primary}`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  })
)
