import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Stack, Typography, Button, Box, Checkbox, FormControlLabel, Grid } from '@mui/material'

import { ModalWrapper } from 'components'

import { FormConfigurationModalProps } from './form-configuration-modal.props'
import { useStyles } from './form-configuration-modal.styles'
import { convertConfigurationData, saveConfigurationData } from './form-configuration-modal-utils'

interface FormConfigurationOption {
    label: string,
    value: boolean
}

export const FormConfigurationModal: React.FC<FormConfigurationModalProps> = (props: FormConfigurationModalProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {isOpen, onClose, onSave} = props;
    const [samplesConfiguration, setSamplesConfiguration] = useState<FormConfigurationOption[]>([]);

    const onGenerateForm = async () => {
        const samplesStore = samplesConfiguration.reduce((acc, { label, value }) => {
            acc[label] = value;
            return acc;
        }, {});
        await saveConfigurationData(samplesStore)
    }

    const initData = async () => {
        const { samples: samplesConfiguration } = await convertConfigurationData();
        setSamplesConfiguration(samplesConfiguration)
    }

    const onChangeSamplesConfiguration = (index: number, value: boolean) => {
        const config = [...samplesConfiguration];
        config[index].value = value;
        setSamplesConfiguration(config);
    }

    const renderDataCheckbox = () => {
        return (
            <Grid container spacing={2}>
                {samplesConfiguration.map((cup, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Box sx={{ display: 'flex', alignItems: 'center', height: '30px', padding: 1 }}>
                            <FormControlLabel
                                label={t(`sample.${cup.label}`)}
                                control={
                                    <Checkbox
                                        disabled={cup.label === 'sampleName' || cup.label === 'sampleType'}
                                        checked={cup.value}
                                        onChange={() => onChangeSamplesConfiguration(index, !cup.value)}
                                    />
                                }
                            />
                        </Box>
                    </Grid>
                ))}
            </Grid>
        )
    }

    useEffect(() => {
        if (isOpen) {
            initData();
        }
    }, [isOpen]);
    
    return (
        <ModalWrapper
            containerProps={{
                sx: {
                    width: { xs: '95%', sm: '90%', md: '900px' },
                    maxWidth: '90%',
                    padding: { xs: 2, sm: 3 },
                },
            }}
            hideClose
            open={isOpen}
            onClose={onClose}
        >
            <Box className={classes.wrapper}>
                <Typography className={classes.title}>
                    {t('sample.customizeField')}
                </Typography>
                <Typography className={classes.description}>
                    {t('sample.customizeFieldDescription')}
                </Typography>
                {renderDataCheckbox()}
                <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" spacing={2} mt={4}>
                    <Button
                        sx={{ height: '40px', minWidth: '185px' }}
                        variant="contained"
                        onClick={()=>{
                            onGenerateForm()
                            onSave()
                        }}
                    >
                        {t('common.save')}
                    </Button>
                    <Button
                        sx={{ height: '40px', minWidth: '185px' }}
                        variant="outlined"
                        onClick={onClose}
                    >
                        {t('common.cancel')}
                    </Button>
                </Stack>
            </Box>
        </ModalWrapper>
    )
}