/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-01-13 15:45:17
 * @modify date 2022-01-13 15:45:17
 */
import {Instance, SnapshotOut, types, flow, applySnapshot, getSnapshot} from "mobx-state-tree"
import {ceil, find, isEmpty, isNil, omit, snakeCase, toNumber} from "lodash"

import { ApiResult } from "services/api"
import {
  withEnvironment,
  withGeneralError,
  withSetValue,
  withReset,
  withRootStore
} from "models/extensions"
import { defectProps } from "models/defect"
import {flattenGreenGrading} from "utils"
import {GreenGradingTypeModel} from "./green-grading-types"
import {GreenGradingModel, preProcessorGreenGrading} from "../green-grading";

const DATA_PER_PAGE = 10

/**
 * Model description here for TypeScript hints.
 */
export const GreenGradingStoreModel = types
  .model("GreenGradingStore")
  .props({
    greenGradingTypes: types.optional(types.array(GreenGradingTypeModel), []),
    selectedGreenGradingType: types.maybeNull(types.reference(GreenGradingTypeModel)),
    greenGradingSettings: types.optional(types.array(types.model(defectProps)), []),
    greenGradings: types.optional(types.array(GreenGradingModel), []),
    selectedGreenGrading: types.optional(GreenGradingModel, {}),
    page: types.optional(types.number, 1),
    totalPage: types.optional(types.number, 0),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .extend(withGeneralError)
  .extend(withSetValue)
  .extend(withReset)
  .actions(self => ({
    setSelectedGreenGradingType: (id: string) => {
      const selectedGreenGradingType = find(self.greenGradingTypes, { id })
      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        selectedGreenGradingType: selectedGreenGradingType
      })
    },
    getGreenGradingTypes: flow(function * () {
      try {
        const res: ApiResult = yield self.environment.greenGradingTypeApi.all()

        if (res.kind === "ok") {
          applySnapshot(self, {
            ...getSnapshot(self as object) as any,
            greenGradingTypes: res.data
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    getGreenGradingSettings: flow(function * (id) {
      try {
        const payload = { green_grading_type_id: id }
        const res: ApiResult = yield self.environment.greenGradingSettingApi.all(payload)

        if (res.kind === "ok") {
          applySnapshot(self, {
            ...getSnapshot(self as object) as any,
            greenGradingSettings: flattenGreenGrading(res.data).map(item => {
              return {
                ...item,
                id: item.id.toString(),
              }
            })
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    getGreenGradings: flow(function * (id) {
      try {
        const payload: any = {
          "size": DATA_PER_PAGE,
          "page[number]": self.page,
        }
        const additionalPath = 'green_gradings'
        const res: ApiResult = yield self.environment.masterSampleApi.find(id, additionalPath, payload)

        if (res.kind === "ok") {
          const totalPage = ceil(res.meta.pagination.records / DATA_PER_PAGE)
          applySnapshot(self, {
            ...getSnapshot(self as object) as any,
            totalPage,
            greenGradings: res.data.map(item => {
              return preProcessorGreenGrading(item)
            }),
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    getGreenGradingsExternal: flow(function * (id: string, pageSize: number, page: number, search?: string) {
      try {
        let payload: any = {
          "size": pageSize,
          "page[number]": page,
        }

        if (search) {
          payload = {
            ...payload,
            'search[grader_name]': search,
            'search[color]': search,
            'search[smell]': search,
            'search[grading_id]': search,
          }
        }
        
        const additionalPath = 'green_gradings'
        const res: ApiResult = yield self.environment.masterSampleApi.find(id, additionalPath, payload)

        if (res.kind === "ok") {
         return res;
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    getGreenGrading: flow(function * (id) {
      try {
        const res: ApiResult = yield self.environment.greenGradingApi.find(id)

        if (res.kind === "ok") {
          applySnapshot(self, {
            ...getSnapshot(self as object) as any,
            selectedGreenGrading: preProcessorGreenGrading(res.data),
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    newGreenGrading(greenGradingTypeId) {
      const { greenGradingSettings } = self.rootStore.greenGradingStore

      const setDefects = greenGradingSettings.map(setting => {
        return { ...setting, count: 0 }
      })

      const greenGrading = preProcessorGreenGrading({ greenGradingTypeId: toNumber(greenGradingTypeId), defects: setDefects })
      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        selectedGreenGrading: greenGrading,
      })
    },
    removeGreenGrading: flow(function * (id: string) {
      try {
        const res: ApiResult = yield self.environment.greenGradingApi.remove(id)

        if (res.kind === "ok") {
          return res
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    addGreenGrading: flow(function * (defects, sampleId, tmpPicture?: any, tmpPicture2?: any) {
      try {
        if (!self.selectedGreenGrading) return

        let omits = ['createdAt', 'author', 'defects', 'picture2']

        const payload: any = {
          ...omit(self.selectedGreenGrading, omits),
          sampleId,
          picture: tmpPicture,
          picture_2: tmpPicture2,
          defects: defects.map(defect => ({ ...defect, count: toNumber(defect.count)}))
        }

        if (!sampleId) {
          delete payload.sampleId
        }

        if(isEmpty(tmpPicture)){
          delete payload.picture
        }

        if(isEmpty(tmpPicture2)){
          delete payload.picture_2
        }

        //form data
        const data = new FormData()
        Object.keys(payload).forEach(key => {
          if(key === "defects"){
            payload[key].forEach(keyData => {
              Object.keys(keyData).forEach(keyDataDefects => {
                data.append(`data[attributes][${snakeCase(key)}][][${snakeCase(keyDataDefects)}]`, isNil(keyData[keyDataDefects]) ? "" : keyData[keyDataDefects])
              })
            })
            return
          }
          data.append(`data[attributes][${snakeCase(key)}]`, isNil(payload[key])? "" : payload[key])
        })
        const res: ApiResult = yield self.environment.greenGradingApi.saveFormData(data, self.selectedGreenGrading.id)

        if (res.kind === "ok") {
          applySnapshot(self, {
            ...getSnapshot(self as object) as any,
            selectedGreenGrading: preProcessorGreenGrading(res.data),
          })
          return res
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
  }))
  .views(self => ({
    get greenGradingTypeWeight() {
      if (!self.selectedGreenGradingType) return 0

      switch (self.selectedGreenGradingType.unit) {
        case 'kg':
          return self.selectedGreenGradingType.sampleSize * 1000
        case 'lbs':
          return self.selectedGreenGradingType.sampleSize * 453.592
        default:
          return self.selectedGreenGradingType.sampleSize
      }
    }
  }))

type GreenGradingStoreType = Instance<typeof GreenGradingStoreModel>
export interface GreenGradingStore extends GreenGradingStoreType {}
type GreenGradingStoreSnapshotType = SnapshotOut<typeof GreenGradingStoreModel>
export interface GreenGradingStoreSnapshot extends GreenGradingStoreSnapshotType {}
