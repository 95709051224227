/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-20 17:19:32
 * @modify date 2022-04-20 17:19:32
 */

import {ceil, isEmpty, chain, snakeCase, filter} from "lodash"
import moment from "moment"
import { flow, applySnapshot, getSnapshot } from "mobx-state-tree"
import { ApiResult } from "services/api"
import { DATA_PER_PAGE } from "config/env"
import sample from 'fixtures/sample.json'

import { reviewSamplePreProcessor } from "./review-sample-preprocessor"

export const withReviewSampleActions = (self: any) => ({
  actions: {
    setCuppingSamples(samples) {
      const newCuppingSamples = samples.map(sample => reviewSamplePreProcessor(sample))

      applySnapshot(self, {
        ...getSnapshot(self as object) as any,
        cuppingSamples: newCuppingSamples
      })
    },
    getSamples: flow(function * (token = '') {
      try {
        const { guideSample, guideScore } = self.rootStore.guideStore

        if (guideSample || guideScore) {
          applySnapshot(self, {
            ...getSnapshot(self as object) as any,
            page: 1,
            totalPage: 1,
            samples: sample.data,
            nextUrl: undefined,
          })

          return
        }

        let search = {}
        if (!token) {
          chain(self.search)
            .omitBy(isEmpty)
            .assign({scoredByMe: self.search.scoredByMe, generalSearch: self.search.generalSearch})
            .forEach((value, key) => { search[`search[${snakeCase(key)}]`] = value})
            .value()
        }

        const payload: any = {
          ...search,
          "size": DATA_PER_PAGE,
          "page[number]": self.page,
          "sort": self.sort
        }

        const res: ApiResult = token ?
          yield self.environment.shareSampleApi.all(payload, token) :
          yield self.environment.masterSampleApi.all(payload)

        if (res.kind === "ok") {
          const totalPage = ceil(res.meta.pagination.records / DATA_PER_PAGE)
          const hasNextPage = res.links && res.links.next
          const samples = res.data.map((sample: any) => reviewSamplePreProcessor(sample))

          applySnapshot(self, {
            ...self,
            totalPage,
            samples,
            records: res.meta.pagination.records,
            nextUrl: hasNextPage ? res.links.next : undefined,
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    
    getSamplesWithSearch: flow(function * (options?) {
      try {
   
        let search = {}
        let dataOptions = {}

        const formatDateRange = (key) => {

          return {
            [`search[${key}_min]`]: moment(dataOptions[`search[${key}]`]).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            [`search[${key}_max]`]: moment(dataOptions[`search[${key}]`]).endOf('day').format('YYYY-MM-DD HH:mm:ss')
          }
        }

        if (options) {
            const excludedKeys = [
              "createdAt",
              "receivedOn",
              "dateDispatch",
              "dateArrival",
              "dateResults",
              "shipmentDate",
            ]
          
            dataOptions = options.reduce((acc, { id, value }) => {
              acc[`search[${snakeCase(id)}]`] = value;
              return acc;
            }, {})
          
            search = options
              .filter(({ id }) => !excludedKeys.includes(id))
              .reduce((acc, { id, value }) => {
                acc[`search[${snakeCase(id)}]`] = value;
                return acc;
              }, {})
          }
          
          const dateFields = [
            "created_at",
            "received_on",
            "date_dispatch",
            "date_arrival",
            "date_results",
            "shipment_date",
          ]
          
          const searchTmp = {
            ...search,
            ...dateFields.reduce(
              (acc, field) =>
                dataOptions[`search[${field}]`] ? { ...acc, ...formatDateRange(field) } : acc,
              {}
            ),
          }

       
        const payload: any = {
          ...searchTmp,
          "size": DATA_PER_PAGE,
          "page[number]": self.page,
          "sort": self.sort
        }

        const res: ApiResult = yield self.environment.masterSampleApi.all(payload)

        if (res.kind === "ok") {
          const totalPage = ceil(res.meta.pagination.records / DATA_PER_PAGE)
          self.setValue('totalPage', totalPage)
          const hasNextPage = res.links && res.links.next
          self.setValue('records', res.meta.pagination.records)
          self.setValue('nextUrl', hasNextPage ? res.links.next : undefined)
          
          return res.data.map((sample) => {
            return {
              ...reviewSamplePreProcessor(sample),
            }
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    getChildSamples: flow(function * (index: number) {
      try {
        const sample = self.samples[index]
        if (sample.childSamples.length > 0) return

        const additionalPath = `shipment_samples`
        const payload = {
          unique_tokens: [sample.uniqueToken]
        }

        const res: ApiResult = yield self.environment.masterSampleApi.all(payload, additionalPath)

        if (res.kind === "ok") {
          const samples = res.data[0].shipmentSamples.map((sample: any) => {
            return {
              ...reviewSamplePreProcessor(sample),
              id: sample.id.toString()
            }
          })
          const newSamples = self.samples.map((s, i) => {
            if (i === index) {
              return {
                ...s,
                childSamples: samples
              }
            }

            return s
          })

          applySnapshot(self, {
            ...getSnapshot(self as object) as any,
            samples: newSamples,
          })
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    shareSamples: flow(function * (){
      try {
        const payload: any = self.share
        const additionalPath = 'sharing'

        const res: ApiResult = yield self.environment.sampleApi.save(payload, {}, additionalPath)

        if (res.kind === "ok") {
          self.share.reset()
          return res.data
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    removeSample: flow(function * (id: string, isFully = true) {
      try {
        let additionalPath
        if (isFully) additionalPath = '?destroy_fully=true'

        const res: ApiResult = yield self.environment.sampleApi.remove(id, {}, additionalPath)

        if (res.kind === "ok") {
          if (self.samples.length === 1) {
            yield self.getSamples()
          } else {
            const newSamples = filter(self.samples, sample => { return sample.id !== id })
            applySnapshot(self, {
              ...getSnapshot(self as object) as any,
              samples: newSamples
            })
          }

          return res.data
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    updateApprovalSample: flow(function * (id: string, payload: any) {
      try {
        const res: ApiResult = yield self.environment.masterSampleApi.save({
          ...payload,
          id
        })

        if (res.kind === "ok") {
          return res.data
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    createBatchSamples: flow(function * (rows: any) {
      try {
        const payload = {
          samples: rows.map((row: any) => {
	          return Object.keys(row).reduce((acc, key) => {
							if (key === 'id' || key === 'is_new') return acc;

		          const snakeKey = snakeCase(key);
		          acc[snakeKey] = row[key];
		          return acc;
	          }, {});
          })
        }

        const res: ApiResult = yield self.environment.masterSampleApi.save(
          payload,
          {},
          'bulk_create'
        )

        if (res.kind === "ok") {
          return res.data
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    })
  }
})
