/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-22 19:42:42
 * @modify date 2022-04-22 19:42:42
 * @desc [description]
 */

import { applySnapshot, getSnapshot, flow } from "mobx-state-tree"
import {map, head, find, chain, isEmpty, pick, filter, cloneDeep} from 'lodash'
import { ApiResult } from "services/api"
import { samplePreProcessor } from "models/sample"
import samples from 'fixtures/samples.json'
import scores from 'fixtures/scores.json'
import {loadString, saveString} from "utils/storage"
import {CUPPED_STORAGE_KEY} from "config/env"
import {removeEmptyValuePayload, snakeCasePayload} from "utils"

export const withSampleActions = (self: any) => ({
  actions: {
    setSamples(newSamples: Array<any>, id = undefined) {
      try {
        self.addAllToPool(newSamples.map(sample => samplePreProcessor(sample)))

        const selectedSample = id ? find(newSamples, ['id', id]).id : head(newSamples)?.id

        applySnapshot(self, {
          ...getSnapshot(self as object) as any,
          samples: map(newSamples, 'id'),
          selectedSample
        })
      } catch (error: any) {
        self.checkForGeneralError(error)
      }
    },
    getIndividualUrl: flow(function * (uniqueToken) {
      const cuppedStorage = yield loadString(CUPPED_STORAGE_KEY)
      if (!cuppedStorage) return

      const temporaryCupped = JSON.parse(cuppedStorage)
      return temporaryCupped[uniqueToken]
    }),
    getSamples: flow(function * (token: string) {
      try {
        if (!token) return

        const { guideSample, guideScore } = self.rootStore.guideStore
        if (guideSample || guideScore) {
          self.setSamples(guideSample ? samples.samples : scores.samples)

          return
        }

        const { cuppingSessions, setValue: setCuppingSessionValue } = self.rootStore.cuppingSessionStore
        const cuppingSession = find(cuppingSessions, ['uniqueToken', token])
        if (cuppingSession) setCuppingSessionValue('selectedCuppingSession', cuppingSession)

        const additionalPath: string = 'samples'
        const res: ApiResult = yield self.environment.cuppingSessionApi.find(token, additionalPath)

        if (res.kind === "ok") {
          self.setSamples(res.data.samples)
          self.selectedSample?.selectLastGreenGrading()
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    saveSample: flow(function * (id) {
      try {
        if (!id) return

        const sample = find(self.samples, ['id', id])
        if (!sample) return

        const payload: any = chain(sample)
                              .assign({id: sample.id, receivedOn: sample.receivedOn, isScoreTarget: sample.isScoreTarget, masterId: sample.masterId, otaTestValue: sample.otaTestValue})
                              .value()

        const res: ApiResult = yield self.environment.sampleApi.save(payload)

        if (res.kind === "ok") return res.data
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    saveMasterSample: flow(function * (id) {
      try {
        if (!id) return

        const sample = find(self.samples, ['id', id])
        if (!sample) return

        const payload: any = chain(sample)
          .omit(['greenGradings','selectedGreenGrading', 'scores', 'selectedScore', 'cachedAverageScore', 'status', 'uniqueToken', 'images'])
          .omitBy(isEmpty)
          .assign({id: sample.uniqueToken, receivedOn: sample.receivedOn, isScoreTarget: sample.isScoreTarget, masterId: sample.masterId})
          .value()

        const res: ApiResult = yield self.environment.masterSampleApi.save(payload)

        if (res.kind === "ok") return res.data
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    bulkSaveSamples: flow(function * () {
      yield self.environment.sampleApi.save(
        {samples: self.payloadBulkSamples},
        {},
        'bulk_update'
      )
    }),
    updateCalibrationStatus: flow(function * (all: boolean = false) {
      try {
        let actions: Array<any> = []
        if (all) {
          self.samples.forEach(s => {
            const payload = pick(s, ['id', 'isScoreTarget'])
            actions.push(self.environment.sampleApi.save(payload))
          })
        } else {
          const payload = pick(self.selectedSample, ['id', 'isScoreTarget'])
          actions.push(self.environment.sampleApi.save(payload))
        }

        Promise.all(actions)
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }

    }),
    addSample: flow(function * (numberOfSamples, cuppingSessionUniqueToken) {
      try {
        const payload = {
          numberOfSamples,
          cuppingSessionUniqueToken
        }

        const res: ApiResult = yield self.environment.sampleApi.save(payload, {})

        if (res.kind === "ok") {
          self.setSamples(res.data.samples, self.selectedSample.id)
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    removeSample: flow(function * (id: string, isFully = true) {
      try {
        let additionalPath
        if (isFully) additionalPath = '?destroy_fully=true'

        const res: ApiResult = yield self.environment.sampleApi.remove(id, {}, additionalPath)

        if (res.kind === "ok") {
          if (self.samples.length > 1) {
            const newSamples = filter(self.samples, sample => { return sample.id !== id })
            applySnapshot(self, {
              ...getSnapshot(self as object) as any,
              samples: newSamples,
              selectedSample: head(newSamples)
            })
          }

          return res.data
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    discardSample(sample) {
      const newSamples = self.samples.map(s => {
        if (s.id === sample.id) {
          return sample
        }

        return s
      })

      self.setSamples(newSamples, sample.id)
    },
    reorderSamples(startIndex, endIndex) {
      const newSamples = cloneDeep(self.samples)
      const [removed] = newSamples.splice(startIndex, 1)
      newSamples.splice(endIndex, 0, removed)
      let payloads: any[] = []

      newSamples.map((s, i) => {
        s.orderPosition = i + 1
        s.sampleId = self.samples[i].sampleId

        payloads.push({
          id: s.id,
          orderPosition: s.orderPosition,
          sampleId: s.sampleId
        })
      })

      self.setSamples(newSamples, self.selectedSample.id)
      self.environment.sampleApi.save(
        {samples: payloads.map(payload => snakeCasePayload(payload))},
        {},
        'bulk_update'
      )
    },

    reorderSamplesReset() {
      const newSamples = cloneDeep(self.samples)
      let payloads: any[] = []

      newSamples.map((s, i) => {
        s.orderPosition = i + 1
        s.sampleId = (i + 1).toString()

        payloads.push({
          id: s.id,
          orderPosition: s.orderPosition,
          sampleId: s.sampleId
        })
      })

      self.setSamples(newSamples, self.selectedSample.id)
      self.environment.sampleApi.save(
        {samples: payloads.map(payload => snakeCasePayload(payload))},
        {},
        'bulk_update'
      )
    },

    saveCuppedScore: flow(function * (uniqueToken: string, urlToken: string) {
      const cuppedStorage = yield loadString(CUPPED_STORAGE_KEY)

      let temporaryCupped = JSON.parse(cuppedStorage) || {}
      temporaryCupped = {
        ...temporaryCupped,
        [uniqueToken]: urlToken
      }

      yield saveString(CUPPED_STORAGE_KEY, JSON.stringify(temporaryCupped))
    }),
    saveScores: flow(function * () {
      try {
        const scores = self.samples.map(sample => {
          const payloads = snakeCasePayload(sample.selectedScore.payloads)
          return {
            ...payloads,
            sample_id: sample.id
          }
        })

        const payload: any = {scores}
        const res: ApiResult = yield self.environment.sampleScoreApi.save(payload)

        if (res.kind === "ok") {
          return res.data
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
        throw error
      }
    }),
    guestScore: flow(function * (captchaToken) {
      try {
        const {
          cuppingSessionStore: { selectedCuppingSession, guestUrlToken },
          guestInformationStore
        } = self.rootStore
        if (!selectedCuppingSession) return

        const scores = self.samples.map(sample => {
          const payloads = snakeCasePayload(sample.selectedScore.payloads)
          return {
            ...payloads,
            sample_id: sample.id
          }
        })

        const payload: any = {
          ...removeEmptyValuePayload(guestInformationStore),
          uniqueToken: selectedCuppingSession.uniqueToken,
          urlToken: guestUrlToken,
          captchaToken,
          scores
        }

        const res: ApiResult = yield self.environment.guestScoreApi.save(payload)

        if (res.kind === "ok") {
          yield self.saveCuppedScore(selectedCuppingSession.uniqueToken, res.data.urlToken)

          return res.data
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
        throw Error()
      }
    }),

    saveAggregate: flow(function * (aggregates: any[], shouldSync?: boolean) {
      try {
        const payload = {
          aggregates: aggregates.map(aggregate => snakeCasePayload(aggregate))
        }
        if (!shouldSync) {
          yield self.environment.aggregatesApi.save(payload, {}, 'save')
        } else {
          yield self.environment.aggregatesApi.save(payload)
        }
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    }),
    bulkSaveMasterSamples: flow(function * (ids: any, token: String) {
      try {
        const additionalPath = 'add_samples'
        const payload: any = {
          masterSampleIds: ids,
          cuppingSessionUniqueToken: token,
        }
        yield self.environment.sampleApi.save(payload, {}, additionalPath)
      } catch (error: any) {
        yield self.checkForGeneralError(error)
      }
    })
  }
})
