/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-17 22:31:37
 * @modify date 2022-02-17 22:31:37
 */

import React from 'react'
import clsx from "clsx"
import moment from "moment"
import {Link, useNavigate} from "react-router-dom"
import { observer } from 'mobx-react-lite'
import {map, toNumber} from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Avatar,
  Stack,
  Typography,
  Box,
  Chip,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Menu, MenuItem
} from '@mui/material'
import QrCode2Icon from '@mui/icons-material/QrCode2'
import {
  Add as AddIcon,
  ArrowDropDown,
  ArrowDropUp,
  DragIndicator,
  ExpandMore,
  HelpOutlineOutlined
} from "@mui/icons-material"
import CurrencyTextField from '@kylebeikirch/material-ui-currency-textfield'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { isMobile } from "react-device-detect"

import { useStores } from 'models'
import { findRoute } from 'constants/routes'
import {ComboCuppingFlag, FormInput, HeaderTitleWide, LightTooltip, ModalWrapper} from 'components'
import {countryName, globalAction, optionsTranslation} from "utils"
import { PrintQr } from 'pages/modal/print-qr/print-qr'
import {colors} from "assets"

import { useStyles } from './sample-information.styles'
import { GreenGradingModal, SampleItem, GreenGradingOptionsModal } from './components'
import { getFormConfigurationData } from 'components/form-configuration-modal/form-configuration-modal-utils'
import { FormConfigurationModal } from 'components/form-configuration-modal/form-configuration-modal'

export const SampleInformation: React.FC = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const cuppingSessionsLink = findRoute('cuppingSessions')
  const sampleReviewLink = findRoute('sampleReview')
  const cuppingScoreLink = findRoute('sampleScore')

  const {
    cuppingSessionStore: { selectedCuppingSession, startCupping },
    sampleStore: { orderedSamples, selectedSample, saveSample, bulkSaveSamples, addSample, reorderSamples, setValue: setSampleValue },
    masterSampleStore: { setValue: setMasterSampleValue },
    modalStore: { greenGrading, printQRCode, setValue: setModalValue },
    notificationStore
  } = useStores()

  const [urlInvitationsQrCode, setUrlInvitationsQrCode] = React.useState('')
  const [openNumberSampleModal, setOpenNumberSampleModal] = React.useState(false)
  const [numberOfSamples, setNumberOfSample] = React.useState('')
  const [expanded, setExpanded] = React.useState<number | false>(false)
  const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null)
  const [validateDataToggle, setValidateDataToggle] = React.useState<boolean | undefined>(undefined);
  const openMenu = Boolean(anchorMenu)

  const [isOpenFormConfig, setIsOpenFormConfig] = React.useState(false)
  const [samplesConfiguration, setSamplesConfiguration] = React.useState({})

  const setFormConfig = async () => {
    const { samples: samplesConfiguration } = await getFormConfigurationData();
    setSamplesConfiguration(samplesConfiguration)
    setIsOpenFormConfig(false)
  }

  const onAddSample = async () => {
    globalAction(notificationStore, {
      action: async () => await addSample(toNumber(numberOfSamples), selectedCuppingSession?.uniqueToken),
      afterAction: () => {
        notificationStore.setNotification({
          severity: 'success',
          message: t('sample.success.successfullyAddSample')
        })
      }
    })

    setOpenNumberSampleModal(false)
    setNumberOfSample('')
  }

  const onDragEnd = (result) => {
    const { source, destination } = result

    // dropped outside the list
    if (!destination) return
    if (source.index === destination.index) return

    reorderSamples(source.index, destination.index)
  }

  const handleExpand = (id) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    if (!isExpanded) saveSample(id)
    setSampleValue("selectedSample", id)

    setExpanded(isExpanded ? id : false)
  }

  const onCupNow = () => {
    // check if current sample has empty name or sample type
    const errorSample = orderedSamples.find(sample => !sample.sampleType || !sample.name);
    if (errorSample)  {
      setSampleValue("selectedSample", errorSample.id);
      setValidateDataToggle(validateDataToggle !== undefined ? !validateDataToggle : false);
      return;
    }

    startCupping(selectedCuppingSession?.uniqueToken)
    bulkSaveSamples()

    navigate(cuppingScoreLink)
  }

  const renderNumberSampleModal = (
    <ModalWrapper
      open={openNumberSampleModal}
      onClose={() => setOpenNumberSampleModal(false)}
      maxWidth="xs"
    >
      <FormInput label={t('sample.numberOfSamplesTobeAdded')}>
        <CurrencyTextField
          key="numberOfSamplesTobeAdded"
          fullWidth
          size='small'
          type='text'
          inputMode='numeric'
          variant='outlined'
          textAlign='left'
          currencySymbol=''
          value={numberOfSamples}
          outputFormat="string"
          decimalCharacter="."
          digitGroupSeparator=","
          decimalPlaces={0}
          onChange={(event, value)=> setNumberOfSample(value)}
        />
      </FormInput>
      <Button
        variant='contained'
        className='wide'
        onClick={onAddSample}
        sx={{mt: 2}}
        disabled={!selectedCuppingSession?.uniqueToken || !numberOfSamples}
      >
        {t('common.save')}
      </Button>
    </ModalWrapper>
  )

  const subtitle = (
    <Box className={classes.subtitle}>
      <Stack direction={isMobile ? 'column' : 'row'} spacing={1} alignItems='center'>
        <Typography variant="h5">
          {`${t("sample.cuppingSession")} ${selectedCuppingSession?.id}`}
        </Typography>
        {selectedCuppingSession?.comboCuppingFlag && (
          <ComboCuppingFlag />
        )}
      </Stack>

      <Box>
        {selectedSample && selectedSample.masterUniqueToken && (
          <Link to={`/samples/${selectedSample.masterUniqueToken}`} style={{display: 'inline-flex', marginBottom: '8px'}}>
            <Typography variant="h5">{selectedSample.masterSampleName}</Typography>
            &nbsp;
            <LightTooltip
              arrow
              title={t('sample.clickHereToMasterSample')}
            >
              <HelpOutlineOutlined color='primary' />
            </LightTooltip>
          </Link>
        )}

        <Typography>
          {`${t("cuppingSession.startAt")}: ${moment(selectedCuppingSession?.startsAt).format("MMMM DD, YYYY - HH:mm")}`}
        </Typography>
      </Box>
    </Box>
  )

  const renderSampleInfo = (sample) => {
    return (
      <Stack spacing={0.5}>
        <Box>
          <Typography display="inline" color="primary" variant="body2" fontWeight={600}>
            {sample.sampleId}
          </Typography>&nbsp;
          <Typography display="inline" variant="body2" fontWeight={600}>
            {sample.name ?? '-'}
          </Typography>
        </Box>
        <Typography variant="caption">
          {sample.sampleUniqueNumber} - {optionsTranslation('cuppingProtocol', sample.cuppingProtocol)}
        </Typography>
        <Typography variant="caption">
          {optionsTranslation('species', sample.species, true)} | {countryName(sample.countryCode) ?? '-'} | {optionsTranslation('sampleType', sample.sampleType, true)}
        </Typography>
      </Stack>
    )
  }

  const renderSampleNav = (sample) => {
    return (
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        className={clsx(classes.sampleNav, selectedSample.id === sample.id && 'active')}
        onClick={() => {
          saveSample(sample.id)
          setSampleValue("selectedSample", sample.id)
        }}
      >
        <IconButton>
          <DragIndicator />
        </IconButton>
        {renderSampleInfo(sample)}
      </Stack>
    )
  }

  const renderWeb = (
    <Box display="flex" mt={4}>
      <Box width="342px" className={classes.sampleNavWrapper}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppableSamples">
            {provided => (
              <Box
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {orderedSamples.map((sample, index) => {
                  return (
                    <Draggable key={sample.id} draggableId={sample.id.toString()} index={index}>
                      {(provided, snapshot) => (
                        <Box
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          sx={{ opacity: snapshot.isDragging ? 0.5 : 1 }}
                        >
                          {renderSampleNav(sample)}
                        </Box>
                      )}
                    </Draggable>
                  )
                })}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
      <Box flex={1}>
        {selectedSample && <SampleItem validateDataToggle={validateDataToggle} dataConfig={samplesConfiguration} useOverflow />}
      </Box>
    </Box>
  )

  const renderMobile = (
    <Box mt={4}>
      {orderedSamples.map((sample, index) => {
        if (!!expanded && expanded !== sample.id) return null

        return (
          <Accordion
            key={sample.id}
            elevation={0}
            expanded={expanded === sample.id}
            onChange={handleExpand(sample.id)}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Stack direction="row" spacing={0.5} mr={1} sx={{alignItems: "center"}}>
                <IconButton
                  size="small"
                  color="primary"
                  disabled={index === 0}
                  sx={{backgroundColor: colors.primary.o25}}
                  onClick={(e) => {
                    e.stopPropagation()

                    reorderSamples(index, index - 1)
                  }}
                >
                  <ArrowDropUp />
                </IconButton>
                <IconButton
                  size="small"
                  color="primary"
                  disabled={index === orderedSamples.length - 1}
                  sx={{backgroundColor: colors.primary.o25}}
                  onClick={(e) => {
                    e.stopPropagation()

                    reorderSamples(index, index + 1)
                  }}
                >
                  <ArrowDropDown />
                </IconButton>
              </Stack>

              {renderSampleInfo(sample)}
            </AccordionSummary>
            <AccordionDetails>
              {selectedSample && <SampleItem useOverflow />}
            </AccordionDetails>
          </Accordion>
        )
      })}
    </Box>
  )

  React.useEffect(() => {
    setFormConfig()
    setMasterSampleValue('searchQuery', '')
    setUrlInvitationsQrCode(`${window.location.origin}/invitations/${selectedCuppingSession?.uniqueToken} `)
  },[])

  React.useEffect(() => {
    setMasterSampleValue('searchQuery', selectedSample.masterSampleName ?? '')
  }, [selectedSample])

  return (
    <Box px={isMobile ? 0 : 4} py={isMobile ? 2 : 2.5}>
      <Box px={isMobile ? 2 : 0}>
        <HeaderTitleWide
          dataLinks={[
            {
              backUrl: cuppingSessionsLink,
              breadcrumb: t("common.backTo", {menu: t("menu.cuppingSessions")})
            },
            {
              backUrl: sampleReviewLink,
              breadcrumb: t('common.goTo', {menu: t('menu.sampleReview')}),
            },
          ]}
        />

        {subtitle}

        <Stack direction='row' spacing={2} justifyContent="end">
          <Button
            fullWidth={isMobile}
            variant="contained"
            size="small"
            startIcon={
              <Avatar className={classes.iconRounded}>
                <AddIcon />
              </Avatar>
            }
            onClick={(event) => setAnchorMenu(event.currentTarget)}
            className={classes.buttonRounded}
          >
            {t("sample.button.addSamples")}
          </Button>
          <Menu
            anchorEl={anchorMenu}
            open={openMenu}
            onClose={() => setAnchorMenu(null)}
            onClick={() => setAnchorMenu(null)}
          >
            <MenuItem onClick={() => setOpenNumberSampleModal(true)}>
              {t("sample.button.addSamples")}
            </MenuItem>
            <MenuItem onClick={() => navigate(`/cupping-sessions/sample-information/master-sample-item/${selectedCuppingSession?.uniqueToken}`)}>
              {t("sample.button.addExistingSamples")}
            </MenuItem>
          </Menu>

          {selectedCuppingSession?.isPublic && (
            <Button
              fullWidth={isMobile}
              variant="contained"
              startIcon={
                <Avatar className={classes.iconRounded}>
                  <QrCode2Icon />
                </Avatar>
              }
              onClick={() => setModalValue("printQRCode", true)}
              className={classes.buttonRounded}
            >
              {t("sample.printQRCode")}
            </Button>
          )}
          <Button
            variant="contained"
            onClick={() => setIsOpenFormConfig(true)}
          >
            {t('sample.customizeField')}
          </Button>
          {!isMobile && !selectedCuppingSession?.canNotCupNow && (
            <Button
              variant="contained"
              size="small"
              className="wide"
              onClick={onCupNow}
            >
              {t('cuppingSession.cupNow')}
            </Button>
          )}
        </Stack>
      </Box>

      {isMobile ? renderMobile : renderWeb}

      {isMobile && !expanded && !selectedCuppingSession?.canNotCupNow && (
        <Box className={classes.buttonCupNow}>
          <Button
            fullWidth
            variant="contained"
            size="small"
            onClick={onCupNow}
          >
            {t('cuppingSession.cupNow')}
          </Button>
        </Box>
      )}

      {selectedSample?.selectedGreenGrading && (
        <GreenGradingModal
          open={greenGrading}
          onClose={() => setModalValue("greenGrading", false)}
        />
      )}

      <GreenGradingOptionsModal />

      <PrintQr
        open={printQRCode}
        onClose={() => setModalValue("printQRCode", false)}
        setUrl={urlInvitationsQrCode}
        selectCuppingSession={selectedCuppingSession}
        sampelName={map(orderedSamples, "name")}
      />

      <FormConfigurationModal
        isOpen={isOpenFormConfig}
        onClose={() => setIsOpenFormConfig(false)}
        onSave={() => setFormConfig()}
      />

      {renderNumberSampleModal}
    </Box>
  )
})

export default SampleInformation
