import React from "react";
import {useTranslation} from "react-i18next";
import {flexRender} from "@tanstack/react-table";
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableProps,
  TableRow, TextField
} from "@mui/material"

import {Pagination} from "components";

import { useStyles } from './filter-request-table.styles'

interface FilterRequestTableProps {
  table: any
  page?: number
  totalPage?: number
  onChangePage?: (event: React.ChangeEvent<unknown>, value: number) => void
  isFetching?: boolean
  renderSubComponent?: (props: { row }) => React.ReactElement
  tableProps?: TableProps
}

export const FilterRequestTable = (props: FilterRequestTableProps) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const {
    table,
    page,
    totalPage = 0,
    onChangePage,
    isFetching,
	renderSubComponent,
    tableProps
  } = props

  return (
    <Box>
      <TableContainer className={classes.container}>
        <Table className={classes.table} {...tableProps}>
	        <TableHead>
	          {table.getHeaderGroups().map(headerGroup => (
	            <>
		            <TableRow key={`header-${headerGroup.id}`}>
		              {headerGroup.headers.map(header => (
		                <TableCell key={header.id} align="center" style={header.column.columnDef.meta?.style}>
		                  {flexRender(
		                    header.column.columnDef.header,
		                    header.getContext()
		                  )}
		                </TableCell>
		              ))}
		            </TableRow>
					  <TableRow key={`filter-${headerGroup.id}`} className={classes.filter}>
						  {headerGroup.headers.map(header => (
							  <TableCell key={header.id} align="center" style={header.column.columnDef.meta?.style}>
								  {header.column.getCanFilter() && !header.column.columnDef.meta?.hideFilter ?
									  header.column.columnDef.meta?.renderFilterComponent ?
										  header.column.columnDef.meta?.renderFilterComponent(header.column) : (
											  <TextField
												  fullWidth
												  size='small'
												  placeholder={t('common.search')}
												  value={header.column.getFilterValue() || ''}
												  onChange={(e) => header.column.setFilterValue(e.target.value)}
											  />
										  ) : null}
							  </TableCell>
						  ))}
		            </TableRow>
	            </>
	          ))}
	        </TableHead>
	        <TableBody>
	          {!isFetching && table.getRowModel().rows.map(row => {
				const className = row.getIsExpanded() ? "expanded" : ""

	            return (
	              <React.Fragment key={row.id}>
	                <TableRow className={className} sx={{height: '1px'}}>
	                  {row.getVisibleCells().map(cell => (
	                    <TableCell key={cell.id} style={cell.column.columnDef.meta?.style}>
	                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
	                    </TableCell>
	                  ))}
	                </TableRow>
					{row.getIsExpanded() && renderSubComponent &&
					<TableRow className="expand-row">
						<TableCell colSpan={row.getVisibleCells().length}>
						{renderSubComponent({ row })}
						</TableCell>
					</TableRow>
					}
	              </React.Fragment>
	            )
	          })}
	        </TableBody>
	      </Table>
      </TableContainer>

      {totalPage > 0 && !isFetching &&
        <Pagination
          page={page}
          count={totalPage}
          onChange={onChangePage}
          boxStyle={{mb: 3}}
        />
      }

      {isFetching &&
        <Box m={3} textAlign="center">
          <CircularProgress color="primary" />
        </Box>
      }
    </Box>
  )
}
