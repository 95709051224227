/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-19 02:59:03
 * @modify date 2022-04-19 02:59:03
 */

import { sum, uniq, values, flatten, chain, includes, omit, pick } from 'lodash'
import { coeAdditionalScore } from 'constants/form'
import { acidityDescriptorList } from 'constants/acidity-descriptors'
import { aftertasteDescriptorList } from 'constants/aftertaste-descriptors'
import { bodyDescriptorList } from 'constants/body-descriptors'
import { flavorDescriptorList } from 'constants/flavor-descriptors'
import { defectDescriptorList } from 'constants/defect-descriptors'
import { balanceDescriptorList } from 'constants/balance-descriptors'
import { scaDescriptorList} from "constants/sca-descriptors"

export const withSampleScoreViews = (self: any) => ({
  views: {
    get uniformity() {
      return [self.uniformity1, self.uniformity2, self.uniformity3, self.uniformity4, self.uniformity5]
    },
    get cleanCup() {
      return [self.cleanCup1, self.cleanCup2, self.cleanCup3, self.cleanCup4, self.cleanCup5]
    },
    get sweetness() {
      return [self.sweetness1, self.sweetness2, self.sweetness3, self.sweetness4, self.sweetness5]
    },
    get nonUniform() {
      return [self.nonUniform1, self.nonUniform2, self.nonUniform3, self.nonUniform4, self.nonUniform5]
    },
    get nonUniformLength() {
      return self.nonUniform.filter(Boolean).length
    },
    get defective() {
      return [self.defective1, self.defective2, self.defective3, self.defective4, self.defective5]
    },
    get defectiveLength() {
      return self.defective.filter(Boolean).length
    },
    get totalUniformity() {
      return self.uniformity.filter(Boolean).length * 2
    },
    get totalCleanCup() {
      return self.cleanCup.filter(Boolean).length * 2
    },
    get totalSweetness() {
      return self.sweetness.filter(Boolean).length * 2
    },
    get totalNonUniform() {
      return self.nonUniform.filter(Boolean).length * -2
    },
    get totalDefective() {
      return self.defective.filter(Boolean).length * -4
    },
    get unscoreKeys() {
      return ['uniformity', 'cleanCup', 'sweetness']
    },
    get unScoreDescriptiveKeys() {
      return ['overall', 'nonUniform', 'defective']
    },
    get comboCuppingKeys() {
      return [
        {key: 'cuppingProtocol', label: 'descriptor.cuppingProtocol', score: self.cuppingProtocol},
        {key: 'defects', label: 'descriptor.defects', keyDescriptor: 'defectsDescriptors', score: self.defects, descriptorList: defectDescriptorList}
      ]
    },
    get scoreKeys() {
      let keys: any[] = []

      switch (self.cuppingProtocol) {
        case 'sca':
        case 'sca_affective':
        case 'sca_descriptive':
          keys = [
            {key: 'fragrance', keyIntensity: 'fragranceIntensity', label: 'descriptor.fragrance', score: self.fragrance, descriptive: self.fragranceIntensity},
            {key: 'aroma', keyIntensity: 'aromaIntensity', label: 'descriptor.aroma', score: self.aroma, descriptive: self.aromaIntensity},
            {key: 'flavor', keyIntensity: 'flavorIntensity', label: 'descriptor.flavor', score: self.flavor, descriptive: self.flavorIntensity},
            {key: 'aftertaste', keyIntensity: 'aftertasteIntensity', label: 'descriptor.aftertaste', score: self.aftertaste, descriptive: self.aftertasteIntensity},
            {key: 'acidity', keyIntensity: 'acidityIntensity', label: 'descriptor.acidity', score: self.acidity, descriptive: self.acidityIntensity},
            {key: 'mouthfeel', keyIntensity: 'mouthfeelIntensity', label: 'descriptor.mouthfeel', score: self.mouthfeel, descriptive: self.mouthfeelIntensity},
            {key: 'sweetnessScore', keyIntensity: 'sweetnessIntensity', label: 'descriptor.sweetness', score: self.sweetnessScore, descriptive: self.sweetnessIntensity},
            {key: 'overall', label: 'descriptor.overall', score: self.overall},
            {key: 'nonUniform', keyScore: 'nonUniformScore', label: 'descriptor.nonUniformCups', score: self.nonUniformScore},
            {key: 'defective', keyScore: 'defects', label: 'descriptor.defectiveCups', score: self.defects},
          ]
          break
        case 'arabica':
          keys = [
            {key: 'roastLevel', label: 'descriptor.roastLevel', score: self.roastLevel},
            {key: 'fragrance', label: 'descriptor.fragranceOrAroma', score: self.fragrance, keyDescriptor: 'fragranceDescriptors', descriptors: self.fragranceDescriptors, descriptorList: flavorDescriptorList},
            {key: 'acidity', label: 'descriptor.acidity', score: self.acidity, keyDescriptor: 'acidityDescriptors', descriptors: self.acidityDescriptors, descriptorList: acidityDescriptorList},
            {key: 'body', label: 'descriptor.body', score: self.body, keyDescriptor: 'bodyDescriptors', descriptors: self.bodyDescriptors, descriptorList: bodyDescriptorList},
            {key: 'flavor', label: 'descriptor.flavor', score: self.flavor, keyDescriptor: 'flavorDescriptors', descriptors: self.flavorDescriptors, descriptorList: flavorDescriptorList},
            {key: 'aftertaste', label: 'descriptor.aftertaste', score: self.aftertaste, keyDescriptor: 'aftertasteDescriptors', descriptors: self.aftertasteDescriptors, descriptorList: aftertasteDescriptorList},
            {key: 'balance', label: 'descriptor.balance', score: self.balance, keyDescriptor: 'balanceDescriptors', descriptors: self.balanceDescriptors, descriptorList: balanceDescriptorList},
            {key: 'overall', label: 'descriptor.overall', score: self.overall},
            {key: 'uniformity', label: 'descriptor.uniformity', score: self.uniformityScore, value: self.uniformity},
            {key: 'cleanCup', label: 'descriptor.cleanCup', score: self.cleanCupScore, value: self.cleanCup},
            {key: 'sweetness', label: 'descriptor.sweetness', score: self.sweetnessScore, value: self.sweetness},
            {key: 'defects', label: 'descriptor.defects', keyDescriptor: 'defectsDescriptors', score: self.defects, descriptorList: defectDescriptorList}
          ]
          break
        case 'robusta':
          keys = [
            {key: 'roastLevel', label: 'descriptor.roastLevel', score: self.roastLevel},
            {key: 'fragrance', label: 'descriptor.fragranceOrAroma', score: self.fragrance, keyDescriptor: 'fragranceDescriptors', descriptors: self.fragranceDescriptors, descriptorList: flavorDescriptorList},
            {key: 'salt', label: 'descriptor.saltOrAcid', score: self.salt,  keyDescriptor: 'saltDescriptors', descriptors: self.saltDescriptors, descriptorList: acidityDescriptorList},
            {key: 'bittersweet', label: 'descriptor.bitterOrSweet', score: self.bittersweet},
            {key: 'flavor', label: 'descriptor.flavor', score: self.flavor, keyDescriptor: 'flavorDescriptors', descriptors: self.flavorDescriptors, descriptorList: flavorDescriptorList},
            {key: 'aftertaste', label: 'descriptor.aftertaste', score: self.aftertaste, keyDescriptor: 'aftertasteDescriptors', descriptors: self.aftertasteDescriptors, descriptorList: aftertasteDescriptorList},
            {key: 'mouthfeel', label: 'descriptor.mouthfeel', score: self.mouthfeel, keyDescriptor: 'mouthfeelDescriptors', descriptors: self.mouthfeelDescriptors,  descriptorList: bodyDescriptorList},
            {key: 'balance', label: 'descriptor.balance', score: self.balance, keyDescriptor: 'balanceDescriptors', descriptors: self.balanceDescriptors, descriptorList: balanceDescriptorList},
            {key: 'uniformity', label: 'descriptor.uniformity', score: self.uniformityScore, value: self.uniformity},
            {key: 'cleanCup', label: 'descriptor.cleanCup', score: self.cleanCupScore, value: self.cleanCup},
            {key: 'overall', label: 'descriptor.overall', score: self.overall},
            {key: 'defects', label: 'descriptor.defects', keyDescriptor: 'defectsDescriptors', score: self.defects, descriptorList: defectDescriptorList}
          ]
          break
        case 'sucafina_tasting':
          keys = [
            {key: 'interestStatus', label: 'descriptor.interest', score: self.interestStatus},
            {key: 'tasting', label: 'descriptor.tasting', score: self.tastingScore, descriptors: self.tastingDescriptors},
          ]
          break

        case 'cup_of_excellence':
          keys = [
            {key: 'roastLevel', label: 'descriptor.roastLevel', score: self.roastLevel},
            {key: 'fragrance', label: 'descriptor.fragranceOrAroma', keyDescriptor: 'fragranceDescriptors', descriptors: self.fragranceDescriptors, descriptorList: flavorDescriptorList},
            {key: 'acidity', label: 'descriptor.acidity', score: self.acidity, keyDescriptor: 'acidityDescriptors', descriptors: self.acidityDescriptors, descriptorList: acidityDescriptorList},
            {key: 'mouthfeel', label: 'descriptor.mouthfeel', score: self.mouthfeel, keyDescriptor: 'mouthfeelDescriptors', descriptors: self.mouthfeelDescriptors, descriptorList: bodyDescriptorList},
            {key: 'flavor', label: 'descriptor.flavor', score: self.flavor, keyDescriptor: 'flavorDescriptors', descriptors: self.flavorDescriptors, descriptorList: flavorDescriptorList},
            {key: 'aftertaste', label: 'descriptor.aftertaste', score: self.aftertaste, keyDescriptor: 'aftertasteDescriptors', descriptors: self.aftertasteDescriptors, descriptorList: aftertasteDescriptorList},
            {key: 'balance', label: 'descriptor.balance', score: self.balance, keyDescriptor: 'balanceDescriptors', descriptors: self.balanceDescriptors, descriptorList: balanceDescriptorList},
            {key: 'cleanCupScore', label: 'descriptor.cleanCup', score: self.cleanCupScore, keyDescriptor: 'cleanCupDescriptors', descriptors: self.cleanCupDescriptors, descriptorList: flavorDescriptorList},
            {key: 'sweetnessScore', label: 'descriptor.sweetness', score: self.sweetnessScore},
            {key: 'overall', label: 'descriptor.overall', score: self.overall},
            {key: 'defects', label: 'descriptor.defects', score: self.defects, keyDescriptor: 'defectsDescriptors', descriptors: self.defectsDescriptors, descriptorList: defectDescriptorList}
          ]
          break

        case 'commercial':
          keys = [
            {key: 'roastLevel', label: 'descriptor.roastLevel', score: self.roastLevel},
            {key: 'flavor', label: 'descriptor.flavor', score: self.flavor},
            {key: 'body', label: 'descriptor.body', score: self.body},
            {key: 'acidity', label: 'descriptor.acidity', score: self.acidity},
            {key: 'sweetnessScore', label: 'descriptor.sweetness', score: self.sweetnessScore},
          ]
          break

        case 'soluble':
          keys = [
            {key: 'aroma', label: 'descriptor.aroma', score: self.aroma},
            {key: 'acidity', label: 'descriptor.acidity', score: self.acidity},
            {key: 'bitterness', label: 'descriptor.bitterness', score: self.bitterness},
            {key: 'body', label: 'descriptor.body', score: self.body},
            {key: 'aftertaste', label: 'descriptor.aftertaste', score: self.aftertaste},
            {key: 'consistency', label: 'descriptor.consistency', score: self.consistency},
          ]
          break

        default:
          keys = []
      }

      return keys
    },
    get sampleInformation() {
      const { selectedSample: { cupsPerSample } } = self.rootStore.sampleStore

      const numberOfCupsObject = {key: 'numberOfCups', label: 'descriptor.numberOfCups', score: cupsPerSample}
      const additionalKeys = [{key: 'notes', label: 'descriptor.notes', description: self.notes}]
      const data = self.scoreKeys.concat(additionalKeys)
      if (data[0]?.key === 'roastLevel'){
        data.splice(1, 0, numberOfCupsObject)
      } else if (data.length > 0) {
        data.unshift(numberOfCupsObject)
      }

      return data
    },
    get payloads() {
      switch (self.cuppingProtocol) {
        case 'sca':
        case 'sca_affective':
        case 'sca_descriptive':
          return pick(self, [
            'id', 'cuppingProtocol', 'fragrance', 'fragranceIntensity', 'aroma', 'aromaIntensity', 'fragranceAromaDescriptors',
            'fragranceAromaSaltyTaste', 'fragranceAromaSourTaste', 'fragranceAromaSweetTaste', 'fragranceAromaBitterTaste', 'fragranceAromaUmamiTaste', 'fragranceAromaNote',
            'acidity', 'acidityIntensity', 'dryAcidity', 'mediumAcidity', 'sweetAcidity', 'acidityNote', 'flavor', 'flavorIntensity', 'aftertaste', 'aftertasteIntensity', 'flavorAftertasteDescriptors',
            'flavorAftertasteSaltyTaste', 'flavorAftertasteSourTaste', 'flavorAftertasteSweetTaste', 'flavorAftertasteBitterTaste', 'flavorAftertasteUmamiTaste', 'flavorAftertasteNote',
            'mouthfeel', 'mouthfeelIntensity', 'mouthfeelNote', 'roughMouthfeel', 'creamyMouthfeel', 'smoothMouthfeel', 'mouthDryingMouthfeel', 'metallicMouthfeel', 'overall', 'overallNote',
            'nonUniform1', 'nonUniform2', 'nonUniform3', 'nonUniform4', 'nonUniform5', 'nonUniformScore',
            'defective1', 'defective2', 'defective3', 'defective4', 'defective5', 'defects', 'defectsDescriptors',
            'sweetnessScore', 'sweetnessIntensity', 'sweetnessNote', 'totalScore'
          ])

        case 'arabica':
          return pick(self, [
            'id', 'cuppingProtocol', 'totalScore','roastLevel',
            'fragrance','fragranceDescriptors','fragranceDry','fragranceBreak',
            'acidity','acidityDescriptors','acidityIntensity','body','bodyDescriptors',
            'bodyIntensity','flavor','flavorDescriptors','aftertaste','aftertasteDescriptors',
            'balance','balanceDescriptors','overall','defects','defectsDescriptors','notes',
            'uniformity1', 'uniformity2', 'uniformity3', 'uniformity4', 'uniformity5', 'uniformityScore',
            'cleanCup1', 'cleanCup2', 'cleanCup3', 'cleanCup4', 'cleanCup5', 'cleanCupScore',
            'sweetness1', 'sweetness2', 'sweetness3', 'sweetness4', 'sweetness5', 'sweetnessScore'
          ])

        case 'robusta':
          return pick(self, [
            'id', 'cuppingProtocol', 'totalScore', 'roastLevel',
            'fragrance','fragranceDescriptors','fragranceDry','fragranceBreak',
            'salt','saltDescriptors','saltLowSalt','saltHiAcid','bittersweet','bittersweetLowBitter',
            'bittersweetHiSweet','flavor','flavorDescriptors','aftertaste','aftertasteDescriptors',
            'balance', 'balanceDescriptors','mouthfeel', 'mouthfeelDescriptors', 'mouthfeelRoughToSmooth', 'overall',
            'defects', 'defectsDescriptors', 'notes',
            'uniformity1', 'uniformity2', 'uniformity3', 'uniformity4', 'uniformity5', 'uniformityScore',
            'cleanCup1', 'cleanCup2', 'cleanCup3', 'cleanCup4', 'cleanCup5', 'cleanCupScore'
          ])

        case 'sucafina_tasting':
          return pick(self, ['id', 'cuppingProtocol', 'emoticons', 'interestStatus', 'tastingScore', 'tastingDescriptors', 'notes', 'totalScore'])

        case 'cup_of_excellence':
          return pick(self, [
            'id', 'cuppingProtocol', 'totalScore', 'roastLevel',
            'fragranceDescriptors', 'fragranceDry', 'fragranceCrust', 'fragranceBreak',
            'acidity', 'acidityDescriptors', 'acidityIntensity', 'mouthfeel',
            'mouthfeelDescriptors', 'mouthfeelRoughToSmooth', 'flavor', 'flavorDescriptors',
            'aftertaste', 'aftertasteDescriptors', 'balance', 'balanceDescriptors',
            'cleanCupScore', 'cleanCupDescriptors', 'sweetnessScore', 'overall',
            'defects', 'defectsDescriptors', 'notes'
          ])

        case 'commercial':
          return pick(self, [
            'id', 'cuppingProtocol', 'roastLevel', 'flavor', 'flavorDescriptors', 'flavorIntensity',
            'acidity', 'acidityDescriptors', 'acidityIntensity',
            'body', 'bodyDescriptors', 'bodyIntensity',
            'sweetnessScore', 'sweetnessIntensity', 'sweetnessDescriptors',
            'uniformityDetected', 'defectDetected', 'defectsDescriptors',
            'notes', 'totalScore'
          ])

        case 'soluble':
          return pick(self, [
            'id', 'cuppingProtocol', 'totalScore', 'notes', 'roastLevel', 'aroma', 'acidity',
            'bitterness', 'body', 'aftertaste', 'consistency', 'flavorDescriptors', 'consistencyComment'
          ])

	      case 'simplified_common':
		      return pick(self, [
			      'id', 'cuppingProtocol', 'totalScore', 'flavorDescriptors', 'acidityNote', 'sweetnessNote',
			      'physicalAspectDescription', 'physicalAspectRate', 'overallNote', 'result', 'internalNotes'
		      ])

        default:
          return self
      }
    },
    get calculateTotalScore() {
      const notToScore = ['defects', 'roastLevel']
      if (self.cuppingProtocol === 'cup_of_excellence') notToScore.push('fragrance')
      if (self.cuppingProtocol.includes('sca')) notToScore.push('nonUniform', 'defective')
      if (self.cuppingProtocol === 'sucafina_tasting') notToScore.push('interestStatus')

      const scores =  chain(self.scoreKeys)
                      .filter(score => { return !includes(notToScore, score.key) })
                      .map('score')
                      .value()

      if (self.cuppingProtocol === 'cup_of_excellence') scores.push(coeAdditionalScore)

      if (self.cuppingProtocol.includes('sca')) {
        if (self.cuppingProtocol === 'sca_descriptive') return 0

        const factor = 0.25
        const summary = Math.ceil(sum(scores) * 0.65625 / factor) * factor + 52.75

        return summary - (self.nonUniformScore + self.defects)
      }

      return sum(scores) - self.defects
    },
    get totalArabicaScore() {
      const scores = chain(self).pick(
        ['fragrance',	'acidity',	'body',	'flavor',	'aftertaste',	'balance',	'overall',	'totalUniformity',	'totalCleanCup',	'totalSweetness']
        ).values().value()

      return sum(scores) - self.defects
    },
    get totalRobustaScore() {
      const scores = chain(self).pick(
        ['fragrance',	'salt',	'bittersweet',	'flavor',	'aftertaste',	'mouthfeel', 'balance',	'overall',	'totalUniformity',	'totalCleanCup']
        ).values().value()

      return sum(scores) - self.defects
    },
    get totalCoeScore() {
      const scores = chain(self).pick(
        ['acidity',	'mouthfeel',	'flavor',	'aftertaste',	'balance',	'cleanCupScore',	'sweetnessScore',	'overall']
        ).values().value()

      return sum(scores) - self.defects + coeAdditionalScore
    },
    get allDescriptors() {
      const descriptors = [
        values(self.acidityDescriptors), values(self.fragranceDescriptors), values(self.bodyDescriptors),
        values(self.flavorDescriptors), values(self.aftertasteDescriptors), values(self.balanceDescriptors),
        values(self.defectsDescriptors), values(self.tastingDescriptors)
      ]

      return uniq(flatten(descriptors))
    }
  }
})
