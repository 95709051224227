import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Stack, Typography, Button, Box, Checkbox, FormControlLabel } from '@mui/material'

import { ModalWrapper } from 'components'

import { ReportConfigurationModalProps } from './report-configuration-modal.props'
import { useStyles } from './report-configuration-modal.styles'
import { convertConfigurationData, saveConfigurationData } from './report-configuration-modal-utils'
import { useNavigate } from 'react-router-dom'

interface ReportConfigurationOption {
    label: string,
    value: boolean
}

export const ReportConfigurationModal: React.FC<ReportConfigurationModalProps> = (props: ReportConfigurationModalProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { type, isOpen, onClose, navigateUrl, reportType = 'seeReport', isSimplifiedCommon } = props;
    const [cuppingConfiguration, setCuppingConfiguration] = useState<ReportConfigurationOption[]>([]);
    const [greenGradingConfiguration, setGreenGradingConfiguration] = useState<ReportConfigurationOption[]>([]);
    const [snapshotConfiguration, setSnapshotConfiguration] = useState<ReportConfigurationOption[]>([]);
    const onGenerateReport = async () => {
        const cuppingStore = cuppingConfiguration.reduce((acc, { label, value }) => {
            acc[label] = value;
            return acc;
        }, {});
        const greenGradingStore = greenGradingConfiguration.reduce((acc, { label, value }) => {
            acc[label] = value;
            return acc;
        }, {});
        const snapshotStore = snapshotConfiguration.reduce((acc, { label, value }) => {
            acc[label] = value;
            return acc;
        }, {});
        await saveConfigurationData(cuppingStore, greenGradingStore, snapshotStore);
        const stateData = reportType === 'seeReport' ? type === 'cupping' ? { cuppingStore } : type === 'green-grading' ? { greenGradingStore } :  { cuppingStore, greenGradingStore }: {snapshotStore} ;
        navigate(navigateUrl, { state: stateData });
    }

    const initData = async () => {
        const { cupping: cuppingConfiguration, greenGrading: greenGradingConfiguration, snapshot: snapshotConfiguration } = await convertConfigurationData();
        setCuppingConfiguration(cuppingConfiguration);
        setGreenGradingConfiguration(greenGradingConfiguration);
        setSnapshotConfiguration(snapshotConfiguration)
    }

    const onChangeCuppingConfiguration = (index: number, value: boolean) => {
        const config = [...cuppingConfiguration];
        config[index].value = value;
        setCuppingConfiguration(config);
    }

    const onChangeGreenGradingConfiguration = (index: number, value: boolean) => {
        const config = [...greenGradingConfiguration];
        config[index].value = value;
        setGreenGradingConfiguration(config);
    }

    const onSnapshotConfiguration = (index: number, value: boolean) => {
        const config = [...snapshotConfiguration];
        config[index].value = value;
        setSnapshotConfiguration(config);
    }

    const renderDataCheckbox = (report) => {
        switch (report) {
            case 'snapshotReport':
              return (
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(3, 1fr)',
                    }}
                >
                    {snapshotConfiguration.map((cup, index) => {
                        const { label, value } = cup;
                        if (!isSimplifiedCommon && label ==='sampleResult') {
                            return null;
                        }
                        return (
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '40px',
                                padding: 1,
                                boxSizing: 'border-box',
                            }} key={index}>
                                <FormControlLabel
                                    label={t(`sample.${label}`)}
                                    control={
                                        <Checkbox
                                            checked={value}
                                            onChange={() => onSnapshotConfiguration(index, !value)}
                                        />
                                    }
                                />
                            </Box>
                        );
                    })}
                </Box>
              )
            default:
              return (
                <>
                     {type === 'cupping' || type === 'combined'
                    ?
                    <>
                        {type === 'combined' ? <Typography fontWeight='600' marginBottom='12px'>
                            {t('report.cuppingReport')}
                        </Typography> : null}
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(3, 1fr)',
                            }}
                        >
                            {cuppingConfiguration.map((cup, index) => {
                                const { label, value } = cup;
                                return (
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '40px',
                                        padding: 1,
                                        boxSizing: 'border-box',
                                    }} key={index}>
                                        <FormControlLabel
                                            label={t(`sample.${label}`)}
                                            control={
                                                <Checkbox
                                                    checked={value}
                                                    onChange={() => onChangeCuppingConfiguration(index, !value)}
                                                />
                                            }
                                        />
                                    </Box>
                                );
                            })}
                        </Box></>
                    : null}
                {type === 'green-grading' || type === 'combined'
                    ?
                    <>
                        {type === 'combined' ? <Typography fontWeight='600' marginBottom='12px' marginTop='20px'>
                            {t('report.greenGradingReport')}
                        </Typography> : null}
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(3, 1fr)',
                            }}
                        >
                            {greenGradingConfiguration.map((gg, index) => {
                                const { label, value } = gg;
                                return (
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '40px',
                                        padding: 1,
                                        boxSizing: 'border-box',
                                    }} key={index}>
                                        <FormControlLabel
                                            label={t(`greenGrading.${label}`)}
                                            control={
                                                <Checkbox
                                                    checked={value}
                                                    onChange={() => { onChangeGreenGradingConfiguration(index, !value) }}
                                                />
                                            }
                                        />
                                    </Box>
                                );
                            })}
                        </Box>
                    </>
                    : null}
                </>
           
              )
          }
    }

    useEffect(() => {
        if (isOpen) {
            initData();
        }
    }, [isOpen]);
    
    return (
        <ModalWrapper
            containerProps={{
                sx: {
                    width: '700px'
                }
            }}
            hideClose
            open={isOpen}
            onClose={onClose}
        >
            <Box className={classes.wrapper}>
                <Typography className={classes.title}>
                    {reportType === 'seeReport' ? t('report.selectReportInformation') : t('report.selectSnapshotInformation')}
                </Typography>
                <Typography className={classes.description}>
                    {t('report.reportConfigurationDescription')}
                </Typography>
                {renderDataCheckbox(reportType)}
                <Stack direction="row" justifyContent="center" spacing={2} mt={4}>
                    <Button
                        sx={{ height: '40px', minWidth: '185px' }}
                        variant="contained"
                        onClick={onGenerateReport}
                    >
                        {reportType === 'seeReport' ? t('generateReport.title') : t('report.export')}
                    </Button>
                    <Button
                        sx={{ height: '40px', minWidth: '185px' }}
                        variant="outlined"
                        onClick={onClose}
                    >
                        {t('common.cancel')}
                    </Button>
                </Stack>
            </Box>
        </ModalWrapper>
    )
}