import React from "react";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  CircularProgress,
  IconButton,
  TextField,
} from "@mui/material";
import { debounce, isEmpty } from "lodash";
import { ClearOutlined } from "@mui/icons-material";

interface Option {
  code: string;
  label: string;
}

interface AutocompleteSelectProps {
  value: Option | null;
  onChange: (e, newValue) => void
  open?: boolean | false
  getData: (searchValue: string) => Promise<Record<string, string>>;
}

export const AutocompleteSelect = (props: AutocompleteSelectProps) => {
  const { t } = useTranslation()
  const { value, onChange, open, getData } = props
  const [isLoading, setIsLoading] = React.useState(false)
  const [options, setOptions] = React.useState<Option[]>([])
  const [inputValue, setInputValue] = React.useState("")

  const handleClear = () => {
    setInputValue('')
  }

  const debouncedSearch = React.useMemo(() => 
    debounce(async (valueData: string) => {
      try {
        setIsLoading(true)
        const data = await getData(valueData) // Ensure data is awaited before processing
  
        if (!isEmpty(data)) {
          const transformedData = Object.entries(data).map(([code, label]) => ({ code, label }))
          setOptions(transformedData) // Ensure function returns the transformed data
        }
      } catch (error) {
        console.error("Error during search:", error)
        setOptions([]) // Return empty array in case of an error
      } finally {
        setIsLoading(false)
      }
    }, 300),
    [getData] // Depend on getData so the function updates when getData changes
  )

  return (
    <Autocomplete
      size="small"
      open={open}
      value={value || null}
      inputValue={inputValue} // Keep input text controlled
      onChange={onChange}
      onInputChange={(_, newInputValue, reason) => {
        if(reason === "reset" && newInputValue.length === 0) return
        setInputValue(newInputValue)
        if(newInputValue.length < 1) return
        debouncedSearch(newInputValue)
      }}
      getOptionLabel ={(options) => options.label}
      options={options}
      clearIcon={
        <IconButton size="small" onClick={handleClear}>
          <ClearOutlined />
        </IconButton>
      }
      clearOnEscape
      sx={{ width: 200 }}
      renderInput={(params) => 
      <TextField {...params} 
        placeholder={t('common.search')}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {isLoading ? <CircularProgress size={20} color="primary" /> : null} {/* Spinner in input */}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
      }
    />


  )
}