import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { findIndex, toString, upperFirst } from "lodash";
import { useTranslation } from "react-i18next";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import {
  IconArrowRight,
  IconArrowLeft,
  IconArrowRightWhite,
  IconHomeRound,
} from 'assets/images'

import { useStores } from "models";
import { colors } from "assets";
import { countryName, delay, flatListDescriptors, optionsTranslation } from "utils";

import { useStyles } from "./simplified-common-mobile.styles";
import { ComboCuppingFlag, HeaderTitleWide } from "components";
import { getInitialSimplifiedCommonCuppingData, saveSimplifiedCommonCuppingData } from "../simplified-common/simplified-common-utils";
import { SimplifiedCommonMobileDescriptorInput, SimplifiedCommonMobileInput, SimplifiedCommonMobileSelectInput, SimplifiedCommonMobileTextInput } from "./components/simplified-common-mobile-input";
import { flavorDescriptorList } from "constants/flavor-descriptors";
import { simplifiedCommonExpectations, simplifiedCommonSampleResults, simplifiedCommonSelections } from "constants/form";
import { EditSampleModal } from "../simplified-common/edit-sample-modal";
import { useKeyboardStatus } from "hooks/keyboard-status";

interface SimplifiedCommonMobileContentRef {
  updateSampleReference: () => void;
}

interface SimplifiedCommonMobileContentProps {
  containerHeight: number;
  isAuthenticated: boolean;
  cuppingSessionLink: string;
  selectedCuppingSession: any;
  isComboCupping: boolean;
  checkTotalScore: () => void;
}

const SimplifiedCommonMobileContent = forwardRef<SimplifiedCommonMobileContentRef, SimplifiedCommonMobileContentProps>((props, ref) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isAuthenticated, cuppingSessionLink, selectedCuppingSession, isComboCupping, containerHeight } = props;
  const { sampleStore: { samples } } = useStores();
  const [selectedSampleIdx, setSelectedSampleIdx] = useState(-1);
  const [simplifiedCommonSamples, setSimplifiedCommonSamples] = useState<any[]>(() => []);

  const init = async () => {
    if (!selectedCuppingSession) return;
    const initSamples = await getInitialSimplifiedCommonCuppingData(selectedCuppingSession.id, samples);
    const rows = initSamples.map(sample => ({
      sampleId: sample?.sampleId,
      cuppingProtocol: sample?.cuppingProtocol,
      countryCode: sample?.countryCode,
      sampleType: sample?.sampleType,
      species: sample?.species,

      id: sample?.id!,
      sampleUniqueNumber: sample?.sampleUniqueNumber,
      name: sample?.name,
      sampleReference: sample?.sampleReference,
      totalScore: sample?.selectedScore.totalScore,
      flavorDescriptors: sample?.selectedScore.flavorDescriptors,
      acidityNote: sample?.selectedScore.acidityNote,
      sweetnessNote: sample?.selectedScore.sweetnessNote,
      physicalAspectDescription: sample?.selectedScore.physicalAspectDescription,
      physicalAspectRate: sample?.selectedScore.physicalAspectRate,
      overallNote: sample?.selectedScore.overallNote,
      result: sample?.selectedScore.result,
      internalNotes: sample?.selectedScore.internalNotes,
    }));
    setSimplifiedCommonSamples(rows);

    // Update the samples with the latest data from store
		const newRowsMap = rows.reduce((acc, row) => { acc[row.id] = row; return acc; }, {});
		for (let i = 0; i < samples.length; i++) {
			const sample = samples[i];
			if (!sample) continue;
			const newRow = newRowsMap[sample.id];
			if (newRow) {
				sample.setValue('selectedScore', {
					...sample.selectedScore,
					totalScore: newRow.totalScore,
					flavorDescriptors: newRow.flavorDescriptors,
					acidityNote: newRow.acidityNote,
					sweetnessNote: newRow.sweetnessNote,
					physicalAspectDescription: newRow.physicalAspectDescription,
					physicalAspectRate: newRow.physicalAspectRate,
					overallNote: newRow.overallNote,
					result: newRow.result,
					internalNotes: newRow.internalNotes,
				});
			}
		}
  }

  const handleChange = (id: number, field: string, value: string) => {
    setSimplifiedCommonSamples((prevSamples) => prevSamples.map((sample) =>
      sample.id === id ? { ...sample, [field]: value } : sample
    ));
  };

  const saveEditingSamples = async () => {
    if (!selectedCuppingSession) return;
    await saveSimplifiedCommonCuppingData(selectedCuppingSession.id, samples);
  }

  const updateSampleReference = () => {
    setSimplifiedCommonSamples(prevSamples => {
      const newSamples = [...prevSamples];
      newSamples.forEach(row => {
        const sample = samples.find(s => s?.id === row.id)
        if (!sample) return

        row.sampleReference = sample.sampleReference;
        row.name = sample.name;
      })

      return newSamples;
    })
  }

  const updateData = () => {
    const updateSample = simplifiedCommonSamples[selectedSampleIdx];
    const sample = samples[selectedSampleIdx];
    if (!sample) return

    sample.setValue('selectedScore', {
			...sample.selectedScore,
			totalScore: updateSample.totalScore ? parseFloat(Number(updateSample.totalScore).toFixed(2)) : 0,
			flavorDescriptors: !!updateSample.flavorDescriptors ? updateSample.flavorDescriptors : [],
			acidityNote: !!updateSample.acidityNote ? updateSample.acidityNote : '',
			sweetnessNote: !!updateSample.sweetnessNote ? updateSample.sweetnessNote : '',
			physicalAspectDescription: updateSample.physicalAspectDescription,
			physicalAspectRate: updateSample.physicalAspectRate,
			overallNote: !!updateSample.overallNote ? updateSample.overallNote : '',
			result: updateSample.result,
			internalNotes: updateSample.internalNotes,
		});

    saveEditingSamples();
  }

  useEffect(() => {
    updateData();
  }, [simplifiedCommonSamples]);

  useImperativeHandle(ref, () => ({
    updateSampleReference,
  }));

  useEffect(() => {
    init();
  }, []);

  if (!simplifiedCommonSamples || simplifiedCommonSamples.length === 0) {
    return null;
  }

  const renderSampleInfo = (sample) => {
    return (
      <Stack spacing={0.5} >
        <Box>
          <Typography display="inline" color="primary" variant="body2" fontWeight={600}>
            {sample.sampleId}
          </Typography>&nbsp;
          <Typography display="inline" variant="body2" fontWeight={600}>
            {sample.name || '-'}
          </Typography>
        </Box>
        <Typography variant="caption">
          {sample.sampleUniqueNumber}{sample.sampleReference ? ` - ${sample.sampleReference}` : ''} - {optionsTranslation('cuppingProtocol', sample.cuppingProtocol)}
        </Typography>
        <Typography variant="caption" color={colors.text.caption}>
          {countryName(sample.countryCode) || '-'} | {optionsTranslation('sampleType', sample.sampleType, true)} | {optionsTranslation('species', sample.species, true)}
        </Typography>
      </Stack>
    )
  }

  const simplifiedFlavorDescriptorList = (flatListDescriptors(flavorDescriptorList) || []).map((item) => ({
    id: item.id,
    value: item.label,
    label: upperFirst(item.label),
  }));
  const commonSelections = simplifiedCommonSelections.filter(item => item !== 'Phenol');

  if (selectedSampleIdx >= 0) {
    const selectedSample = simplifiedCommonSamples[selectedSampleIdx];
    if (!selectedSample) {
      return null;
    }

    return (
      <Box overflow='hidden' display='flex' flexDirection='column'>
        <Box className={classes.header}>
          <Typography fontSize={14} fontWeight='500'>
            {`${t('sampleScore.cuppingSession')} #${selectedCuppingSession?.numberForCompany}`}
          </Typography>
          <Typography fontSize={12}>
            {`${t('sample.sample')} (${selectedSampleIdx + 1}/${simplifiedCommonSamples.length})`}
          </Typography>
          <Box className={classes.nav}>
            <IconButton
              disabled={selectedSampleIdx === 0}
              className={classes.navButton}
              onClick={() => {
                setSelectedSampleIdx(selectedSampleIdx - 1);
              }}
              sx={{ paddingRight: '10px' }}
            >
              <IconArrowLeft />
            </IconButton>

            <IconButton
              onClick={() => {
                setSelectedSampleIdx(-1);
              }
              }
            >
              <IconHomeRound />
            </IconButton>

            <IconButton
              disabled={selectedSampleIdx === simplifiedCommonSamples.length - 1}
              className={classes.navButton}
              onClick={() => {
                setSelectedSampleIdx(selectedSampleIdx + 1);
              }}
              sx={{ paddingLeft: '10px' }}
            >
              <IconArrowRightWhite />
            </IconButton>
          </Box>
        </Box>

        <Box className={classes.sampleInfoContainer}>
          {renderSampleInfo(selectedSample)}
          <Box textAlign="center">
            <Typography fontSize={10} fontWeight='500'>{t('descriptor.score')}</Typography>
            <Typography variant="h5" color="primary">{selectedSample.totalScore ? selectedSample.totalScore : '-'}</Typography>
          </Box>
        </Box>
        <Box sx={{ overflow: 'auto', height: containerHeight }}>
          <SimplifiedCommonMobileInput
            label={t('sample.sampleReference')}
            component={
              <Box fontSize={12}>{selectedSample.sampleReference ? selectedSample.sampleReference : '-'} </Box>
            }
          />
          <SimplifiedCommonMobileInput
            label={t('descriptor.score')}
            component={
              <SimplifiedCommonMobileTextInput
                id={selectedSample.id}
                field="totalScore"
                value={selectedSample.totalScore}
                type="number"
                handleChange={handleChange}
                placeholder={t('placeholder.writeHere')}
                disableDebounceInput
              />
            }
          />
          <SimplifiedCommonMobileInput
            label={t('descriptor.flavor')}
            component={
              <SimplifiedCommonMobileDescriptorInput
                id={selectedSample.id}
                field="flavorDescriptors"
                value={selectedSample.flavorDescriptors}
                valueOptions={simplifiedFlavorDescriptorList}
                handleChange={handleChange}
                placeholder={t('placeholder.select')}
              />
            }
          />
          <SimplifiedCommonMobileInput
            label={t('descriptor.acidity')}
            component={
              <SimplifiedCommonMobileSelectInput
                id={selectedSample.id}
                field="acidityNote"
                value={selectedSample.acidityNote}
                options={commonSelections}
                handleChange={handleChange}
                placeholder={t('placeholder.select')}
              />
            }
          />
          <SimplifiedCommonMobileInput
            label={t('descriptor.sweetness')}
            component={
              <SimplifiedCommonMobileSelectInput
                id={selectedSample.id}
                field="sweetnessNote"
                value={selectedSample.sweetnessNote}
                options={commonSelections}
                handleChange={handleChange}
                placeholder={t('placeholder.select')}
              />
            }
          />
          <SimplifiedCommonMobileInput
            label={t('descriptor.physicalAspectDescription')}
            type="multi-line"
            component={
              <SimplifiedCommonMobileTextInput
                id={selectedSample.id}
                field="physicalAspectDescription"
                value={selectedSample.physicalAspectDescription}
                type="multi-line"
                handleChange={handleChange}
                placeholder={t('placeholder.writeHere')}
              />
            }
          />
          <SimplifiedCommonMobileInput
            label={t('descriptor.physicalAspectRate')}
            component={
              <SimplifiedCommonMobileSelectInput
                id={selectedSample.id}
                field="physicalAspectRate"
                value={selectedSample.physicalAspectRate}
                options={simplifiedCommonExpectations}
                handleChange={handleChange}
                placeholder={t('placeholder.select')}
              />
            }
          />
          <SimplifiedCommonMobileInput
            label={t('descriptor.overallExpectation')}
            component={
              <SimplifiedCommonMobileSelectInput
                id={selectedSample.id}
                field="overallNote"
                value={selectedSample.overallNote}
                options={simplifiedCommonExpectations}
                handleChange={handleChange}
                placeholder={t('placeholder.select')}
              />
            }
          />
          <SimplifiedCommonMobileInput
            label={t('descriptor.sampleResult')}
            component={
              <SimplifiedCommonMobileSelectInput
                id={selectedSample.id}
                field="result"
                value={selectedSample.result}
                options={simplifiedCommonSampleResults}
                handleChange={handleChange}
                placeholder={t('placeholder.select')}
              />
            }
          />
           <SimplifiedCommonMobileInput
            label={t('descriptor.internalNotes')}
            type="multi-line"
            component={
              <SimplifiedCommonMobileTextInput
                id={selectedSample.id}
                field="internalNotes"
                value={selectedSample.internalNotes}
                type="multi-line"
                handleChange={handleChange}
                placeholder={t('placeholder.writeHere')}
              />
            }
          />
        </Box>
      </Box>
    )
  }

  return (
    <Box flex={1} overflow='hidden'>
      <HeaderTitleWide
        {...(isAuthenticated && {
          backUrl: cuppingSessionLink,
          breadcrumb: t('common.backTo', { menu: t('menu.cuppingSessions') })
        })}
        containerSx={{
          paddingLeft: '16px',
          paddingRight: '16px',
          paddingTop: '16px'
        }}
        linkContainerSx={{
          marginBottom: '4px !important'
        }}
        linkSx={{
          fontSize: '10px'
        }}
        subtitle={<Stack direction="row" spacing={1} alignItems='center'>
          <Typography fontSize={14} fontWeight='500'>
            {`${t('sampleScore.cuppingSession')} #${selectedCuppingSession?.numberForCompany}`}
          </Typography>
          {isComboCupping && <ComboCuppingFlag />}
        </Stack>}
      />
      <Box mb={6} mt={2} sx={{ backgroundColor: 'white',  height: containerHeight - 130, overflow: 'auto' }}>
        {simplifiedCommonSamples.map((sample, index) => {
          if (!sample) {
            return null;
          }

          return (
            <Box
              key={sample.id}
              className={classes.contentWrapper}
              onClick={() => {
                setSelectedSampleIdx(index)
              }}
            >
              {renderSampleInfo(sample)}

              <Box className={classes.score}>
                <Box textAlign="center" mr={1}>
                  <Typography fontSize={10} fontWeight='500'>{t('descriptor.score')}</Typography>
                  <Typography variant="h5" color="primary">{sample.totalScore ? sample.totalScore : '-'}</Typography>
                </Box>

                <IconArrowRight />
              </Box>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
})

export const SimplifiedCommonMobile = (props) => {
  const childRef = useRef<SimplifiedCommonMobileContentRef>(null);
  const { t } = useTranslation();
  const { checkTotalScore, isAuthenticated } = props;
  const [openEditSampleModal, setOpenEditSampleModal] = useState(false);

  const handleTriggerUpdate = () => {
    if (childRef.current) {
      childRef.current.updateSampleReference();
    }
  }

  // handle UX on iOS devices
  const isKeyboardOpen = useKeyboardStatus();

  useEffect(() => {
    if (!isKeyboardOpen) {
      document.body.style.height = window.innerHeight + "px";
    } {
      document.body.style.height = 'auto';
    }
  }, [isKeyboardOpen]);

  const containerHeight = window.innerHeight - 56;
  return (
    <>
      <Box display='flex' flexDirection='column' height={containerHeight}>
        <SimplifiedCommonMobileContent ref={childRef} containerHeight={containerHeight} {...props} />
        <Box px={2} py={1} display='flex' sx={{ backgroundColor: 'white' }}>
          {isAuthenticated ? <Button variant='contained' onClick={() => setOpenEditSampleModal(true)} sx={{ marginRight: '8px', flex: 1 }} >
            {t('sample.button.editSample')}
          </Button> : null}
          <Button variant='contained' onClick={checkTotalScore} sx={{ flex: 1 }}>
            {t('sampleScore.reviewAndSubmit')}
          </Button>
        </Box>
      </Box>
      <EditSampleModal
        open={openEditSampleModal}
        setOpen={setOpenEditSampleModal}
        updateSampleReference={handleTriggerUpdate}
      />
    </>
  )
}
