/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-20 17:15:19
 * @modify date 2022-04-20 17:15:19
 */

import { types } from 'mobx-state-tree'
import { defaultSampleProps } from "models/sample/default-sample.props"

const props = {
  ...defaultSampleProps,
  numberOfCuppers: types.maybeNull(types.number),
  sampleId: types.maybeNull(types.string),
  cuppingSessionId: types.maybeNull(types.number),
  country: types.maybeNull(types.string),
  regionName: types.maybeNull(types.string),
  averageScore: types.maybeNull(types.number),
  canNotAddSampleInfo: types.maybeNull(types.boolean),
  canDeleteSample: types.maybeNull(types.boolean),
  cuppingSessionUniqueToken: types.maybeNull(types.string),
  hasBeenCupped: types.union(types.string, types.number),
  cuppingProtocol: types.maybeNull(types.string),
  canSeeReport: types.maybeNull(types.boolean),
  currentUserCuppedAlready: types.maybeNull(types.boolean),
  numberOfSamples: types.maybeNull(types.number),
  latestCuppingSessionSampleCachedAveragedScore: types.optional(types.string, '-'),
}

export const cuppingSampleProps = types.model({
  id: types.union(types.string, types.number),
  ...props,
})

export const sampleProps = types.model({
  id: types.identifier,
  ...props,
  childSamples: types.optional(types.array(cuppingSampleProps), []),
})
