import React, {useState} from "react";
import {useTranslation} from "react-i18next";

import {useStores} from "models";
import {RejectModal} from "modules";
import {FormInput, ModalConfirm, ModalMessage, ModalWrapper} from "components";
import {SampleShipmentApproval} from "constants/constant";
import {has} from "lodash";
import {pointerMessages} from "../../../utils";
import {Button, Stack} from "@mui/material";

interface ApproveRejectProps {
  id: string | undefined
  transactionItemId?: string
  setTransactionItemId?: (value) => void
  openQcReason: boolean
  setOpenQcReason: (bool) => void
  openConfirmApprove: boolean
  setOpenConfirmApprove: (bool) => void
  confirm?: string
  confirmApproveTitle?: string
  confirmApproveDescription?: string
  openRejectModal: boolean
  setOpenRejectModal: (bool) => void
  successMessage?: string
  onSuccess?: () => void
  needRequestNewSample?: boolean
  token?: string
  isSample?: boolean
}

export const ApproveReject = (props: ApproveRejectProps) => {
  const { t } = useTranslation()
  const {
    id, transactionItemId, setTransactionItemId,
    openQcReason, setOpenQcReason,
    openConfirmApprove, setOpenConfirmApprove,
    confirm, confirmApproveTitle, confirmApproveDescription,
    openRejectModal, setOpenRejectModal,
    successMessage, onSuccess, needRequestNewSample, token, isSample
  } = props
  const {
    reviewSampleStore: { updateApprovalSample },
    sampleShipmentStore: { updateApprovalStatus, requestNewSample: processRequestNewSample },
    notificationStore
  } = useStores()

  const [openRequestNewSample, setOpenRequestNewSample] = useState(false)
  const [reason, setReason] = useState('')
  const [reasonJson, setReasonJson] = useState<any>()
  const [qcReason, setQcReason] = useState('')

  const onCloseQcReason = () => {
    setQcReason('')
    setOpenQcReason(false)
    setOpenConfirmApprove(false)
    setOpenRejectModal(false)
  }

  const onApproveSample = async () => {
    try {
      setOpenConfirmApprove(false)
      notificationStore.setLoading(true)

      const payload = {
        approvalStatus: SampleShipmentApproval.APPROVED,
        qcReason
      }

      if (transactionItemId) {
        payload['uniqueToken'] = transactionItemId
      }

      isSample ? await updateApprovalSample(id, payload) : await updateApprovalStatus(id, payload, token)
      setTransactionItemId && setTransactionItemId('')

      notificationStore.setNotification({
        severity: 'success',
        message: t('shipment.success.successfullyApprovedSample')
      })
    } finally {
      notificationStore.setLoading(false)
      onSuccess && onSuccess()
    }
  }

  const onRejectSample = async (rejectedReason, rejectedReasonJson, requestNewSample?: boolean) => {
    try {
      notificationStore.setLoading(true)

      const payload = {
        approvalStatus: SampleShipmentApproval.REJECTED,
        reason: rejectedReason,
        reasonJson: rejectedReasonJson,
        qcReason
      }

      if (transactionItemId) {
        payload['uniqueToken'] = transactionItemId
      }

      setOpenRequestNewSample(false)
      if (requestNewSample) {
        await processRequestNewSample(id, { uniqueToken: transactionItemId }, token)
      } else {
        setOpenRejectModal(false)
      }

      isSample ? await updateApprovalSample(id, payload) : await updateApprovalStatus(id, payload, token)
      setTransactionItemId && setTransactionItemId('')

      notificationStore.setNotification({
        severity: 'success',
        message: t('shipment.success.successfullyRejectedSample')
      })
    } finally {
      notificationStore.setLoading(false)
      onSuccess && onSuccess()
    }
  }

  return (
    <>
      <ModalWrapper
        open={openQcReason}
        onClose={onCloseQcReason}
        maxWidth="sm"
      >
        <FormInput
          label={t('shipment.modal.reasonForChange')}
          formControlProps={{margin: 'none'}}
          textFieldProps={{
            multiline: true,
            rows: 4,
            placeholder: t('shipment.modal.reasonForChangePlaceholder'),
            value: qcReason,
            onChange: (event) => setQcReason(event.target.value),
          }}
        />

        <Stack direction="row" justifyContent="center" spacing={2} mt={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenQcReason(false)}
            sx={{px: 4}}
          >
            Submit
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={onCloseQcReason}
            sx={{px: 4}}
          >
            Cancel
          </Button>
        </Stack>
      </ModalWrapper>

      <ModalConfirm
        open={openConfirmApprove && !openQcReason}
        onClose={() => setOpenConfirmApprove(false)}
        title={confirmApproveTitle ?? t('shipment.confirmApprove.title')}
        description={confirmApproveDescription ?? t('shipment.confirmApprove.description')}
        confirm={confirm}
        onConfirm={onApproveSample}
      />

      <RejectModal
        open={openRejectModal && !openQcReason}
        onClose={() => setOpenRejectModal(false)}
        onReject={(r, json) => {
          setReason(r)
          setReasonJson(json)
          if (needRequestNewSample) {
            setOpenRejectModal(false)
            setOpenRequestNewSample(true)
          } else {
            onRejectSample(r, json)
          }
        }}
      />

      <ModalConfirm
        open={openRequestNewSample}
        title={t('shipment.requestNewSample.title')}
        description={t('shipment.requestNewSample.description')}
        confirm={t('shipment.button.yesPlease')}
        onConfirm={() => onRejectSample(reason, reasonJson, true)}
        cancel={t('shipment.button.no')}
        onCancel={() => onRejectSample(reason, reasonJson, false)}
      />
    </>
  )
}
