import { createStyles, makeStyles } from '@mui/styles'
import { colors } from 'assets'

export const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      padding: '8px',
      paddingTop: '16px',
    },
    title: {
      fontSize: '14px',
      fontWeight: '600'
    },
    description: {
      fontSize: '14px',
      color: colors.text.caption,
      marginBottom: '20px'
    },
    sectionTitle: {
      padding: '8px',
      marginTop: '8px',
      backgroundColor: colors.magnoliaAccent,
      justifyContent: 'space-between',
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center'
    }
  })
)
