/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-06 20:03:53
 * @modify date 2022-04-13 20:27:17
 */

import React from 'react'
import {chain, has, find, clone, includes, pull, remove, debounce, isEmpty} from 'lodash'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useLocation, useNavigationType } from 'react-router-dom'
import {
  Box,
  Container,
  Button,
  Collapse,
  Typography,
  Divider,
  Tooltip,
  SvgIcon,
  Stack,
  Menu,
  MenuItem,
  AppBar, Toolbar, FormControlLabel, Checkbox, Popover, Grid
} from '@mui/material'
import {
  ArrowDropDown,
  Cancel,
  CompareArrows,
  ExpandLess,
  ExpandMore, Feed,
  ListOutlined,
  SearchSharp,
  SystemUpdateAlt,
  TableChartOutlined
} from '@mui/icons-material'
import PivotTableChartIcon from '@mui/icons-material/PivotTableChart';
import ReactHtmlParser from 'react-html-parser'

import { useStores } from 'models'
import { findRoute } from 'constants/routes'
import {IconAddCuppingSession, IconReviewSamples, IconShare, IconShippingFast, SortAsc, SortDefault, SortDesc, IconGenerateCombinedReport, IconTableSearchBar} from 'assets/images'
import { globalAction, validateEmail, pointerMessages, countryName } from 'utils'
import { ModalWrapper, FormInput, MenuIcon, Pagination, FilterChip, HeaderTitleWide, LightTooltip} from 'components'
import { sortSamples } from 'constants/form'
import { loadString, saveString } from 'utils/storage';
import { VIEW_TABLE_SAMPLES_STORAGE_KEY } from 'config/env';
import { HEADER_SELECTOR_SAMPLE_STORAGE_KEY } from 'config/env';
import { colors } from 'assets'

import {AdvanceSearch, SampleTable, SampleItemMobile} from './components'
import {MultipleGenerateLabel} from "../modal";
import { CombinedReportModal } from './components/combined-report-modal/combined-report-modal'
import { ExportConfigurationModal } from 'components/export-configuration-modal/export-configuration-modal'
import { convertNestedKeys } from 'components/export-configuration-modal/export-configuration-modal-utils'
import { headerSelectorSamples,viewSamples } from  "constants/form"
import { ColumnFiltersState } from '@tanstack/react-table'

const MOBILE_MARGIN_TOP = 17;

export const Samples: React.FC = observer(() => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()
  const { search } = useLocation()
  const navigationType = useNavigationType()

  const {
    reviewSampleStore: { search: { name, generalSearch, setValue: setSearchValue, reset: resetSearch },
      share: { title, to, message, setValue: setShareValue },
      searchValues, records, getSamples, getSamplesWithSearch, shareSamples, samples, totalPage, page,
      setCuppingSamples, setValue: setReviewSampleValue,
    },
    sampleShipmentStore: { setSamplesToBeShipment, getNewSamples, samplesToBeShipment, setSamplesToBeShipmentMultiGenerateLabel },
    compareStore: { setValue: setCompareValue },
    masterSampleStore: { exportMasterSample },
    notificationStore
  } = useStores()

  const homeLink = findRoute('home')
  const compareLInk = findRoute('compare')
  const [expanded, setExpanded] = React.useState(false)
  const [openShare, setOpenShare] = React.useState(false)
  const [ids, setIds] = React.useState<String[]>([])
  const [isReview, setIsReview] = React.useState(true)
  const [disableCompare, setDisableCompare] = React.useState(true)
  const [cuppingProtocols, setCuppingProtocols] = React.useState<String[]>([])
  const [shareErrors, setShareErrors] = React.useState<any>({})
  const [isMobile, setIsMobile] = React.useState(false)
  const [temporaryAcidityAndSaltDescriptors, setTemporaryAcidityAndSaltDescriptors] = React.useState<any[]>([])
  const [temporaryBodyAndMouthfeelDescriptors, setTemporaryBodyAndMouthfeelDescriptors] = React.useState<any[]>([])
  const [temporaryFragranceDescriptors, setTemporaryFragranceDescriptors] = React.useState<any[]>([])
  const [temporaryFlavorAndAftertasteDescriptors, setTemporaryFlavorAndAftertasteDescriptors] = React.useState<any[]>([])
  const [temporaryDefectsDescriptors, setTemporaryDefectsDescriptors] = React.useState<any[]>([])
  const [temporaryListSearch, setTemporaryListSearch] = React.useState<any[]>([])
  const [openShareReport, setOpenShareReport] = React.useState(false)
  const [shareReportIds, setShareReportIds] = React.useState("")
  const [searchAdvanceData,setSearchAdvanceData] = React.useState<any[]>([])
  const [reservedSamples, setReservedSamples] = React.useState<any[]>([])
  const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorMenu)
  const [openMultipleGenerateLabel, setMultipleOpenGenerateLabel] = React.useState(false)
  const [isOpenCombinedReport, setOpenCombinedReport] = React.useState(false);
  const [selectedSort, setSelectedSort] = React.useState(sortSamples);
  const [sortMenu, setSortMenu] = React.useState<null | HTMLElement>(null)
  const openSort = Boolean(sortMenu)
  const [headerSelector, setHeaderSelector] = React.useState<null | HTMLElement>(null)
  const openHeaderSelector = Boolean(headerSelector)
  const [isOpenExportConfiguration, setOpenExportConfiguration] = React.useState<boolean>(false);
  const [tmpHeaderSelectorSamples, setTmpHeaderSelectorSamples] = React.useState<any>([{key: '', label: '',value: false }]);
  const [data, setData] = React.useState<any[]>([])

  const onExport = (config) =>  {
    globalAction(notificationStore, { action: async() => await exportMasterSample({
      master_sample_ids: ids,
      ...convertNestedKeys(config)
    }) })
  }

  const handleOpenExportConfiguration = () =>  {
    setOpenExportConfiguration(true);
  }

  const handleCloseExportConfiguration = () =>  {
    setOpenExportConfiguration(false);
  }
  const [selectedViewMenu, setSelectedViewMenu] = React.useState("")
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([])
  const hasFilter = columnFilters.length > 0

  const getViewTable= async() => {
    const dataString = await loadString(VIEW_TABLE_SAMPLES_STORAGE_KEY)
    if(!dataString) {
      await saveString(VIEW_TABLE_SAMPLES_STORAGE_KEY, JSON.stringify(viewSamples[0]))
      setSelectedViewMenu(viewSamples[0].label)
      return
    }

    setSelectedViewMenu(JSON.parse(dataString).label)
  }

  const setChipSearch = (flag, query) => {
    setReviewSampleValue('page', 1)
    if(flag === 'purchaseContract') {
      setSearchValue('purchaseContractReference', query)
    } else if (flag === 'salesContract') {
      setSearchValue('salesContractReference', query)
    } else if (flag === 'masterSamplesById') {
      setSearchValue('sampleUniqueNumber', query)
    } else if (flag === 'masterSamplesByPg') {
      setSearchValue('purchaseGrade', query)
    } else {
      setSearchValue('name', query)
    }

    onGetSamples()
  }

  const getHeaderSelector = async() => {
    const dataString = await loadString(HEADER_SELECTOR_SAMPLE_STORAGE_KEY)
    if(!dataString) {
      await saveString(HEADER_SELECTOR_SAMPLE_STORAGE_KEY, JSON.stringify(headerSelectorSamples))
      setTmpHeaderSelectorSamples(headerSelectorSamples)
      return
    }

    const savedHeaderSelectorSamples = JSON.parse(dataString);
    const mappedHeaderSelectorSamples = savedHeaderSelectorSamples.reduce((acc, item) => {
      acc[item.key] = item;
      return acc
    }, {});

    const mergedHeaderSelectorSamples = headerSelectorSamples.map((item) => {
      if (has(mappedHeaderSelectorSamples, item.key)) {
        return mappedHeaderSelectorSamples[item.key]
      }
      return item;
    })

    setTmpHeaderSelectorSamples(mergedHeaderSelectorSamples)
  }

  const handleSelectedSort = (index) => {
    let newArr = [...selectedSort];

    newArr.map((data, i) => {
      if(i !== index && newArr[i]["sort"] !== "default") newArr[i]["sort"] = "default"
    })

    if(newArr[index]["sort"] === "default"){
      newArr[index]["sort"] = "asc"
    } else if(newArr[index]["sort"] === "asc") {
      newArr[index]["sort"] = "desc"
    } else {
      newArr[index]["sort"] = "default"
    }
    setReviewSampleValue("sort",newArr[index].sort === "default" ? '' : `${newArr[index].key} ${newArr[index].sort}`)
    setSelectedSort(newArr)
    setReviewSampleValue('page', 1)
  }

  const renderIconSort = (data) => {
    switch (data) {
      case "asc": return SortAsc
      case "desc": return SortDesc
      default : return SortDefault

    }
  }

  const onCheck = (selectedSamples) => {
    let newIds: String[] = []
    let newCuppingProtocols: String[] = []
    let newReservedSamples: any[] = []

    selectedSamples.map(sample => {
      newIds.push(sample.id)
      newCuppingProtocols.push(sample.cuppingProtocol)
      newReservedSamples.push(sample)
    })

    setIds(newIds)
    setReservedSamples(newReservedSamples)
    setCuppingProtocols(newCuppingProtocols)
  }

  const onCheckMobile = (id : string) => {
    const { cuppingProtocol } = find(samples, ['id', id])

    const newIds = clone(ids)
    const newCuppingProtocols = clone(cuppingProtocols)

    if (includes(ids, id)) {
      pull(newIds, id)

      const index = newCuppingProtocols.indexOf(cuppingProtocol)
      if (index >= 0) newCuppingProtocols.splice(index, 1)

      remove(reservedSamples,(data) => data.id === id)

    } else {
      newIds.push(id)
      newCuppingProtocols.push(cuppingProtocol)
      reservedSamples.push(find(samples, ['id', id]))
    }

    setIds(newIds)
    setCuppingProtocols(newCuppingProtocols)
  }


  const renderMobile = () => {
    return(
      samples.map((sample) =>
        <SampleItemMobile
          {...sample}
          ids={ids}
          isReview={isReview}
          onCheck={() => onCheckMobile(sample.id)}
          onGetSamples={onGetSamples}
        />

      )
    )
  }

  const onRemoveSelectedSample = (sample) => {
    onCheck(reservedSamples.filter(s => s.id !== sample.id))
  }

  const filterLabel = (sample) => {
    return `${sample.sampleUniqueNumber}; ${sample.name}${sample.countryCode ? `; ${countryName(sample.countryCode)}` : ''}${sample.processName ? `; ${sample.processName}` : ''}${sample.purchaseGrade ? `; ${sample.purchaseGrade}` : ''}`
  }

  const onShareSamples = () => {
    setShareErrors({})
    const validateEmails = chain(to).split(',')
                            .map(email => { return validateEmail(email.replace(/\s/g, ""))})
                            .every(Boolean).value()

    if (!validateEmails) {
      setShareErrors({ "email": [{ message: t('sample.error.emailIsInvalid') }] })
      return
    }

    setShareValue('samplesToShare', ids.join(','))

    globalAction(notificationStore, {
      action: shareSamples,
      afterAction: () => {
        setIds([])
        setOpenShare(false)
      }
    })
  }

  const onGetSamples = React.useCallback(
    debounce(() => {
      globalAction(notificationStore, {
        action: async () => await getSamples(params.token)
      })
    }, 300), []
  )

  const onGetSamplesWithSearch = React.useCallback(
	  debounce(async (option?) => {
	    try {
	      notificationStore.setLoading(true)
	      const contracts = await getSamplesWithSearch(option)
	      setData(contracts)
	    } finally {
	      notificationStore.setLoading(false)
	    }
  }, 500), [])

  const onCompareSample = async () => {
    setCompareValue('samples', [])
    setCompareValue('ids', ids)
    navigate(compareLInk)
  }

  const onSetLayout = () => {
    if(window.innerWidth < 900) {
      setIsMobile(true)
      return
    }

    setIsMobile(false)
  }

  const handleChangeCategory = () => {
    temporaryListSearch.map(item =>
      Object.keys(searchValues).forEach(k => {
        if(k !== item )
        setTemporaryListSearch(current =>
          current.filter(data => {
            return data !== item;
          }),
        );
      })
    )
  }

  const handleOpenCombinedReportModal = () => {
    setOpenCombinedReport(true);
  }

  const handleCloseCombinedReportModal = () => {
    setOpenCombinedReport(false);
  }
  const handleCheckboxHeaderSelectorChange = (key) => {
    setTmpHeaderSelectorSamples((prev) =>
      prev.map((item) =>
        item.key === key ? { ...item, value: !item.value } : item
      )
    );
  };

  const renderButtonsMobile = () => {
    return(
      <Box sx={{p: 1}}>
       <Button
          size="large"
          variant="contained"
          onClick={(event) => setAnchorMenu(event.currentTarget)}
          endIcon= {openMenu ? <ExpandLess /> : <ExpandMore />}
          fullWidth
        >
         {t("sample.samples.action")}
        </Button>
        <Menu
          anchorEl={anchorMenu}
          open={openMenu}
          onClose={() => setAnchorMenu(null)}
          onClick={() => setAnchorMenu(null)}
          PaperProps={{
            style: {
                 width: "92%",
                 maxWidth: "92%",
               }
          }}
        >
        <MenuItem onClick={() => navigate(`/samples/new`)}>
          <MenuIcon>
            <SvgIcon component={IconReviewSamples} inheritViewBox />
          </MenuIcon>
          {t('sample.samples.newSample')}
        </MenuItem>

        <MenuItem onClick={() => navigate('/multiple-samples')}>
          <MenuIcon>
            <SvgIcon component={IconReviewSamples} inheritViewBox />
          </MenuIcon>
          {t('sample.samples.newMultipleSample')}
        </MenuItem>
        <MenuItem disabled={reservedSamples.length === 0} onClick={() => {
          const newSamples = getNewSamples(reservedSamples)
          setSamplesToBeShipment(newSamples)
          navigate(`/sample-shipments/new`)
          }}
        >
          <MenuIcon>
            <SvgIcon component={IconShippingFast} inheritViewBox />
          </MenuIcon>
          {t('sample.samples.newShipment')}
        </MenuItem>
        <MenuItem
          disabled={reservedSamples.length === 0}
          onClick={() => {
            setCuppingSamples(reservedSamples)
            navigate(`/cupping-sessions/new`)
          }}
        >
          <MenuIcon>
            <SvgIcon component={IconAddCuppingSession} inheritViewBox />
          </MenuIcon>
          {t('sample.samples.newCuppingSession')}
        </MenuItem>
        <MenuItem disabled={disableCompare}  onClick={onCompareSample}>
          <MenuIcon>
            <SvgIcon component={CompareArrows} inheritViewBox />
          </MenuIcon>
          {t('sample.compare')}
        </MenuItem>
        <MenuItem disabled={ids.length < 1} onClick={() => setOpenShare(true)}>
          <MenuIcon>
            <SvgIcon component={IconShare} inheritViewBox />
          </MenuIcon>
          {t('sample.share')}
        </MenuItem>
        <MenuItem onClick={handleOpenExportConfiguration}>
          <MenuIcon>
            <SvgIcon component={SystemUpdateAlt} inheritViewBox />
          </MenuIcon>
          {t('sample.samples.downloadToCsv')}
        </MenuItem>
        <MenuItem disabled={reservedSamples.length === 0} onClick={() => {
          const newSamples = getNewSamples(reservedSamples)
          setSamplesToBeShipment(newSamples)
          setMultipleOpenGenerateLabel(true)
        }}
        >
          <MenuIcon>
            <SvgIcon component={Feed} inheritViewBox />
          </MenuIcon>
          {t('sample.samples.generateLabel')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setExpanded(!expanded)
            handleChangeCategory()
          }}>
          <MenuIcon>
            <SvgIcon component={SearchSharp} inheritViewBox />
          </MenuIcon>
          {t('sample.advanceSearch')}
        </MenuItem>
        </Menu>
      </Box>
    )
  }

  const renderButtonSort = () => {
    return(
      <>
        <Button
          variant="outlined"
          endIcon={<ArrowDropDown />}
          sx={{ borderRadius: 1 }}
          onClick={(event) => setSortMenu(event.currentTarget)}
        >
          {t("order.button.sortBy")}
        </Button>

        <Menu
          id="basic-menu"
          anchorEl={sortMenu}
          open={openSort}
          onClose={() => setSortMenu(null)}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {selectedSort.map((data, index) => {
            return (
              <MenuItem
                sx={{ justifyContent: 'space-between', alignItems: 'center' }}
                onClick={() => handleSelectedSort(index)}
              >
                <Typography>{t(data.label)}</Typography>
                <SvgIcon component={renderIconSort(data.sort)} inheritViewBox sx={{ fontSize: 20, textAlign: '' }} />
              </MenuItem>
            )
          })}
        </Menu>
      </>
    )
  }

  const renderButtonsAction = () => {
    return(
      <Box>
        <Button
          size="large"
          variant="contained"
          onClick={(event) => setAnchorMenu(event.currentTarget)}
          endIcon= {openMenu ? <ExpandLess /> : <ExpandMore />}
          fullWidth
        >
          {t("sample.samples.action")}
        </Button>
        <Menu
          anchorEl={anchorMenu}
          open={openMenu}
          onClose={() => setAnchorMenu(null)}
          onClick={() => setAnchorMenu(null)}
        >
          <MenuItem onClick={() => navigate(`/samples/new`)}>
            <MenuIcon>
              <SvgIcon component={IconReviewSamples} inheritViewBox />
            </MenuIcon>
            {t('sample.samples.newSample')}
          </MenuItem>

          <MenuItem onClick={() => navigate('/multiple-samples')}>
            <MenuIcon>
              <SvgIcon component={IconReviewSamples} inheritViewBox />
            </MenuIcon>
            {t('sample.samples.newMultipleSample')}
          </MenuItem>
          <MenuItem disabled={reservedSamples.length === 0} onClick={() => {
            const newSamples = getNewSamples(reservedSamples)
            setSamplesToBeShipment(newSamples)
            navigate(`/sample-shipments/new`)
          }}
          >
            <MenuIcon>
              <SvgIcon component={IconShippingFast} inheritViewBox />
            </MenuIcon>
            {t('sample.samples.newShipment')}
          </MenuItem>
          <MenuItem
            disabled={reservedSamples.length === 0}
            onClick={() => {
              setCuppingSamples(reservedSamples)
              navigate(`/cupping-sessions/new`)
            }}
          >
            <MenuIcon>
              <SvgIcon component={IconAddCuppingSession} inheritViewBox />
            </MenuIcon>
            {t('sample.samples.newCuppingSession')}
          </MenuItem>
          <Tooltip title={disableCompare ? `${t('sample.theSameCuppingProtocolDescription')}` : ""} arrow>
           <span>
            <MenuItem disabled={disableCompare}  onClick={onCompareSample}>
              <MenuIcon>
                <SvgIcon component={CompareArrows} inheritViewBox />
              </MenuIcon>
              {t('sample.compare')}
            </MenuItem>
           </span>
          </Tooltip>
          <MenuItem disabled={ids.length < 1} onClick={() => setOpenShare(true)}>
            <MenuIcon>
              <SvgIcon component={IconShare} inheritViewBox />
            </MenuIcon>
            {t('sample.share')}
          </MenuItem>
          <MenuItem onClick={handleOpenExportConfiguration}>
            <MenuIcon>
              <SvgIcon component={SystemUpdateAlt} inheritViewBox />
            </MenuIcon>
            {t('sample.samples.downloadToCsv')}
          </MenuItem>
          <MenuItem disabled={reservedSamples.length === 0} onClick={handleOpenCombinedReportModal}>
            <MenuIcon>
              <SvgIcon component={IconGenerateCombinedReport} inheritViewBox />
            </MenuIcon>
            {t('sample.samples.generateCombinedReport')}
          </MenuItem>
          <MenuItem disabled={reservedSamples.length === 0} onClick={() => {
            const newSamples = getNewSamples(reservedSamples)
            setSamplesToBeShipment(newSamples)
            setMultipleOpenGenerateLabel(true)
          }}
          >
            <MenuIcon>
              <SvgIcon component={Feed} inheritViewBox />
            </MenuIcon>
            {t('sample.samples.generateLabel')}
          </MenuItem>
          <Tooltip 
            title={selectedViewMenu === viewSamples[1].label ? ReactHtmlParser(t('sample.advanceSearchTooltip')) : ''}  
            placement='left' 
            arrow
            slotProps={{
              tooltip: {
                style: {
                  backgroundColor: 'white',
                  color: 'black',
                },
              },
              arrow: {
                style: {
                  color: 'white',
                },
              },
            }}
            >
          <span>
            <MenuItem
              disabled={selectedViewMenu === viewSamples[1].label}
              onClick={() => {
                setExpanded(!expanded)
                handleChangeCategory()
              }}>
                
              <MenuIcon>
                <SvgIcon component={SearchSharp} inheritViewBox />
              </MenuIcon>
              {t('sample.advanceSearch')}
            </MenuItem>
          </span>
          </Tooltip>
        </Menu>
      </Box>
    )
  }

  const renderListHeaderSelector = (dataList) => {
    return(
      <Box sx={{width: 400, p: 1}}>
        <Grid container >
        {dataList.map((data, index) => (
          <Grid item xs={6} key={index}>
            <FormControlLabel
              label={`${t(data.label)}`}
              control={
                <Checkbox
                  size="medium"
                  checked={data.value}
                  onChange={() => handleCheckboxHeaderSelectorChange(data.key)}
                />
              }
            />
          </Grid>
        ))}
      </Grid>
    </Box>
    )
  }

  const renderButtonHeaderSelector = () => {
    return (
      <>
        <Button
          variant="contained"
          sx={{ borderRadius: 1 }}
          onClick={(event) => setHeaderSelector(event.currentTarget)}
        >
          <PivotTableChartIcon />
        </Button>
        <Popover
          open={openHeaderSelector}
          anchorEl={headerSelector}
          onClose={() => {
            setHeaderSelector(null)
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          {renderListHeaderSelector(tmpHeaderSelectorSamples.filter((data) => data.key !== 'checkboxSampleUniqueNumber' && data.key !== 'action'))}
        </Popover>
      </>
    );
  }

  const resetFilter = (key) => {
    setSearchValue(key, '');
    setSearchValue('generalSearch', false)
    setReviewSampleValue('page', 1)
    handleChangeCategory()
    onGetSamples()
    if (sessionStorage.getItem('searchParams')) sessionStorage.removeItem("searchParams")
  }

  const resetAllFilter = () => {
    resetSearch()
    Object.keys(searchValues).forEach(key => setSearchValue(key, ''))
    setTemporaryListSearch([])
    setSearchValue('generalSearch', false)
    setReviewSampleValue('page', 1)
    handleChangeCategory()
    onGetSamples()
    if (sessionStorage.getItem('searchParams')) sessionStorage.removeItem("searchParams")
  }

  const setlabelFilterChip = (key) => {
    switch (key) {
      case 'countryCode':
        return t('sample.country')
      case 'status':
        return t('sample.status.title')
      case 'sampleUniqueNumber':
        return t('sample.sampleId')
      case 'processName':
        return t('sample.coffeeProcessing')
      case 'supplierName':
        return t('sample.supplier')
      default :
        return t(`sample.${key}`)
    }
  }

  React.useEffect(() => {
    setDisableCompare(true)

    if (ids.length < 2 || ids.length > 4) return

    const sameProtocol = cuppingProtocols.every( (val, i, arr) => val === arr[0] )
    setDisableCompare(!sameProtocol)
  }, [ids.length])

  React.useEffect(() => {
    setDisableCompare(true)

    if (ids.length < 2 || ids.length > 4) return

    const sameProtocol = cuppingProtocols.every( (val, i, arr) => val === arr[0] )
    setDisableCompare(!sameProtocol)
  }, [ids.length])

  React.useEffect(() => {
    if(selectedViewMenu === viewSamples[1].label) return

    if (search) {
      const query = new URLSearchParams(search).get('search')
      const flag = new URLSearchParams(search).get('flag')
      setChipSearch(flag, query)
    
      // Save to sessionStorage so it persists across navigation
      sessionStorage.setItem('searchParams', JSON.stringify({ query, flag }))
     
      navigate('/samples', { replace: true })
    }
  }, [search])

  React.useEffect(() => {
    getViewTable()

    getHeaderSelector()

    if (params.token) setIsReview(false)

    if (search) {
      resetSearch()
    }
   
  }, [])

  React.useLayoutEffect(() => {
    setShareValue('message', t('sample.shareSampleText'))
    window.addEventListener('resize', onSetLayout)

    onSetLayout()
    return () => window.removeEventListener("resize", onSetLayout)
  }, [])

  React.useEffect(() => {
    if (search) return

    if (navigationType === "PUSH") {

      sessionStorage.removeItem("searchParams")
    } else {
      const savedSearch = sessionStorage.getItem('searchParams')

      if (savedSearch) {
        const { query, flag } = JSON.parse(savedSearch)

        setChipSearch(flag, query)
      }
    }
  }, [navigationType]);

  return (
    <Box px={4} pb={2.5}>
      {!isMobile &&
        <HeaderTitleWide
          title={t('menu.reviewSamples')}
          {...(isReview && {
            breadcrumb: t('common.backTo', { menu: t('menu.home')}),
            backUrl: homeLink,
          })}
          {...(generalSearch && {subtitle: t('sample.showingResult', {total: records, query: name})})}
          rightContent={(isReview && !isMobile) &&
            <Stack direction="row" spacing={1} justifyContent="end">
              {(hasFilter && selectedViewMenu === viewSamples[1].label) && (
                <Button
                  variant="contained"
                  sx={{ borderRadius: '50px' }}
                  startIcon={<Cancel />}
                  onClick={() => setColumnFilters([])}
                >
                  {t(`common.clearFilter`)}
                </Button>
              )}
              {renderButtonSort()}
              {renderButtonsAction()}
              <Button
                variant={selectedViewMenu === viewSamples[0].label ? "contained" : "outlined"}
                sx={{borderRadius: 1}}
                onClick={(event) =>  {
                  setSelectedViewMenu(viewSamples[0].label)
                  saveString(VIEW_TABLE_SAMPLES_STORAGE_KEY, JSON.stringify(viewSamples[0]))
                }}
              >
                <ListOutlined /> 
              </Button>
              <Button
                variant={selectedViewMenu === viewSamples[1].label ? "contained" : "outlined"}
                sx={{borderRadius: 1}}
                onClick={(event) =>  {
                  setSelectedViewMenu(viewSamples[1].label)
                  saveString(VIEW_TABLE_SAMPLES_STORAGE_KEY, JSON.stringify(viewSamples[1]))
                }}
              >
                <SvgIcon component={IconTableSearchBar} fontSize="small" sx={{ color: selectedViewMenu === viewSamples[1].label ? 'white' : colors.primary.main }} inheritViewBox />
              </Button>
              {renderButtonHeaderSelector()}
            </Stack>
          }
        />
      }

      {(isReview && isMobile) &&
        <AppBar position="fixed" elevation={0} color="default" sx={{ height: 125, p: 1, bottom: 'auto', top: 53}} >
          {
            <Stack direction="column" spacing={1} justifyContent="space-between">
              <HeaderTitleWide
                title={t('menu.reviewSamples')}
                {...(isReview && {
                  breadcrumb: t('common.backTo', { menu: t('menu.home')}),
                  backUrl: homeLink,
                })}
                {...(generalSearch && {subtitle: t('sample.showingResult', {total: records, query: name})})}
              />
              {renderButtonsMobile()}
            </Stack>
          }
        </AppBar>
      }
      {isMobile ? <Box mt={MOBILE_MARGIN_TOP} /> : null}
      {reservedSamples.length > 0 && (
        <Stack direction="row" spacing={1} mb={2} useFlexGap flexWrap="wrap">
          {reservedSamples.map(s => (
            <FilterChip
              key={s.id}
              label={filterLabel(s)}
              onRemove={() => onRemoveSelectedSample(s)}
            />
          ))} 

          <Button
            variant="text"
            size="small"
            color="error"
            onClick={() => onCheck([])}
            sx={{px: 1}}
          >
            {t('common.clearFilter')}
          </Button>
        </Stack>
      )}

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <AdvanceSearch
          isMobile={isMobile}
          expanded={expanded}
          temporaryDefectsDescriptors = {temporaryDefectsDescriptors}
          temporarySearchAdvanceData = {searchAdvanceData}
          temporaryFlavorAndAftertasteDescriptors = {temporaryFlavorAndAftertasteDescriptors}
          temporaryFragranceDescriptors = {temporaryFragranceDescriptors}
          temporaryBodyAndMouthfeelDescriptors = {temporaryBodyAndMouthfeelDescriptors}
          temporaryAcidityAndSaltDescriptors = {temporaryAcidityAndSaltDescriptors}
          temporaryListSearch = {temporaryListSearch}
          addTemporarySearchAdvanceData={(item)=>{
            setSearchAdvanceData(item)
          }}
          addTemporaryDefectsDescriptors={(descriptors) => {
            setTemporaryDefectsDescriptors(descriptors)
          }}
          addTemporaryFlavorAndAftertasteDescriptors={(descriptors) => {
            setTemporaryFlavorAndAftertasteDescriptors(descriptors)
          }}
          addTemporaryFragranceDescriptors={(descriptors) => {
            setTemporaryFragranceDescriptors(descriptors)
          }}
          addTemporaryBodyAndMouthfeelDescriptors={(descriptors) => {
            setTemporaryBodyAndMouthfeelDescriptors(descriptors)
          }}
          addTemporaryAcidityAndSaltDescriptors={(descriptors) => {
            setTemporaryAcidityAndSaltDescriptors(descriptors)
          }}
          addTemporaryListSearch={(data) => {
            setTemporaryListSearch(data)
          }}
          onSearch={() => {
            setSearchValue('generalSearch', false)
            setReviewSampleValue('page', 1)
            handleChangeCategory()
            onGetSamples()
          }}
          onCancel={() => {
            onGetSamples()
            setExpanded(false)
            window.scrollTo(0, 0)
            setTemporaryDefectsDescriptors([])
            setTemporaryFlavorAndAftertasteDescriptors([])
            setTemporaryFragranceDescriptors([])
            setTemporaryBodyAndMouthfeelDescriptors([])
            setTemporaryAcidityAndSaltDescriptors([])
            setTemporaryListSearch([])
          }}
        />
      </Collapse>

      
      {!isEmpty(searchValues)&& (
          <Stack direction="row" spacing={1} mt={2} mb={1} useFlexGap flexWrap="wrap">
            { Object.keys(searchValues).map((key, i) => {
              return (
                <FilterChip
                  key={i}
                  label={`${setlabelFilterChip(key)}: ${searchValues[key]}`}
                  onRemove={() => resetFilter(key)}
                />
              )
            })}

            <Button
              variant="text"
              size="small"
              color="error"
              onClick={resetAllFilter}
              sx={{px: 1}}
            >
              {t('common.clearFilter')}
            </Button>
          </Stack>
        )}

      {/* Tab */}

        {isMobile ?
          <>
            <Box sx={{mb: 7}}>
              {renderMobile()}
            </Box>
            <AppBar position="fixed" color="default" sx={{ top: 'auto', bottom: 0, height: 70}}>
              {totalPage > 0 && <Pagination page={page} count={totalPage} siblingCount={1} onChange={(e, p) => setReviewSampleValue('page', p)} /> }
            </AppBar>
          </>
          :
          <SampleTable 
            isReview={isReview} 
            reservedSamples={reservedSamples}  
            onSelected={onCheck} 
            onGetSamples={onGetSamples} 
            onGetSamplesWithSearch={onGetSamplesWithSearch}
            data={data}
            tmpHeaderSelectorSamples={tmpHeaderSelectorSamples}
            selectedViewMenu={selectedViewMenu}
            selectedSort={selectedSort}
            columnFilter={columnFilters}
            setColumnFilter={(filter)=>setColumnFilters(filter)}
          />
        }


      <ModalWrapper
        maxWidth='sm'
        open={openShare}
        onClose={() => {
          setOpenShare(false);
          setOpenShareReport(false)}}
      >
        <Typography variant='h5' sx={{mb: 2}}>{t('sample.shareSampleList')}</Typography>
        <Divider />
        {openShareReport ?
          <FormInput
            label={t('sample.samplesToShare')}
            textFieldProps={{
              disabled: true,
              value: shareReportIds
            }}
          /> :
          <FormInput
            label={t('sample.samplesToShare')}
            textFieldProps={{
              disabled: true,
              value: ids.join(', ')
            }}
          />
        }

        <FormInput
          label={t('sample.title')}
          textFieldProps={{
            value: title,
            onChange: (e) => setShareValue('title', e.target.value)
          }}
        />
        <FormInput
          label={t('sample.to')}
          textFieldProps={{
            value: to,
            onChange: (e) => setShareValue('to', e.target.value),
            helperText: has(shareErrors, 'email') ? pointerMessages('email', shareErrors) : t('report.emailShareSampleDescription'),
            error: has(shareErrors, 'email')
          }}
        />
        <FormInput
          label={t('sample.emailMessage')}
          textFieldProps={{
            multiline: true,
            rows: 3,
            value: message,
            onChange: (e) => setShareValue('message', e.target.value)
          }}
        />

        <Button
          variant='contained'
          className='wide'
          sx={{mt: 3}}
          disabled={!title || !to || !message}
          onClick={onShareSamples}
        >
          {t('report.send')}
        </Button>
      </ModalWrapper>
      {!isEmpty(samplesToBeShipment) &&
        <MultipleGenerateLabel
          index={0}
          samplesToBePrintLabel={samplesToBeShipment}
          open={openMultipleGenerateLabel}
          onClose={() => setMultipleOpenGenerateLabel(false)}
          onSubmit={(data) => {
            data.map(d => {
              setSamplesToBeShipmentMultiGenerateLabel(d)
            })
          }}
          isUseIndexAsLabelNumber
        />
      }
      <CombinedReportModal
        isOpen={isOpenCombinedReport}
        onClose={handleCloseCombinedReportModal}
        selectedSamples={reservedSamples}
      />
      <ExportConfigurationModal
        isOpen={isOpenExportConfiguration}
        onExport={onExport}
        onClose={handleCloseExportConfiguration}
      />
    </Box>
  )
})

export default Samples
