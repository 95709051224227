import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { colors } from "assets";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      borderBottom: `1px solid ${colors.border.primary}`,
      backgroundColor: 'white',
      minHeight: '56px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    multilineContainer: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      borderBottom: `1px solid ${colors.border.primary}`,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
    },
    labelContainer: {
      marginRight: theme.spacing(2),
      width: '35%',
    },
    multilineLabelContainer: {
      marginBottom: theme.spacing(1),
    },
  })
)
