import { EXPORT_CONFIGURATION_STORAGE_KEY } from 'config/env';
import { mapKeys, mapValues, snakeCase } from 'lodash';
import { loadString, saveString } from 'utils/storage';

const EXPORT_CONFIGURATION_DATA = {
    sampleInformation: {
        sampleUid: true,
        sampleName: true,
        cuppingSessionDate: true,
        cuppingForm: true,
        averageScore: true,
        greenGradingDate: true,
        greenGradingForm: true,
        primaryDefectCount: true,
        secondaryDefectCount: true,
        screenSize: true,
        country: true,
        receivedDate: true,
        sampleType: true,
        sampleGrade: true,
        supplier: true,
        producerName: true,
        purchaseContractReference: true,
        strategy: true,
        customer: true,
        customerCode: true,
        salesContractReference: true,
        description: true,
        species: true,
        varietals: true,
        coffeeProcessing: true,
        preferedCuppingProtocol: true,
        receivedWeight: true,
        cropYear: true,
        numberOfBags: true,
        bagWeight: true,
        moisture: true,
        density: true,
        waterActivity: true,
        temperature: true,
        mass: true,
        volume: true,
        warehouse: true,
        sampleLocation: true,
        cargoSeelNumber: true,
        containerNumber: true,
        trackingNumber: true,
        lotNumberIco: true,
        notesAndRemarks: true,
        otaValue: true,
        decaf: true,
        basketCode: true
    },
    cuppingSummary: {
        sampleUid: true,
        shipmentId: true,
        cuppingSessionId: true,
        sampleName: true,
        sampleType: true,
        country: true,
        sampleGrade: true,
        supplier: true,
        customer: true,
        coffeeProcessing: true,
        purchaseContractReference: true,
        salesContractReference: true,
        waterActivity: true,
        moisture: true,
        density: true,
        defects: true,
        trackingNumber: true,
        cuppers: true,
        cuppingForm: true,
        finalScore: true,
    },
    cuppingDetail: {
        sampleUid: true,
        cuppingSessionId: true,
        shipmentId: true,
        sampleName: true,
        sampleType: true,
        country: true,
        sampleGrade: true,
        supplier: true,
        customer: true,
        coffeeProcessing: true,
        purchaseContractReference: true,
        salesContractReference: true,
        waterActivity: true,
        moisture: true,
        density: true,
        trackingNumber: true,
        cuppingForm: true,
        cupperName: true,
        finalScore: true,
        aroma: true,
        aromaDescriptor: true,
        dry: true,
        break: true,
        flavor: true,
        flavorDescriptor: true,
        aftertaste: true,
        aftertasteDescriptor: true,
        acidity: true,
        acidityIntensity: true,
        acidityDescriptor: true,
        body: true,
        bodyIntensity: true,
        bodyDescriptor: true,
        balance: true,
        balanceDescriptor: true,
        uniformity: true,
        cleanUp: true,
        sweetness: true,
        overall: true,
        notes: true,
    },
}

export const getExportConfigurationData = async () => {
    const dataString = await loadString(EXPORT_CONFIGURATION_STORAGE_KEY);
    if (!dataString) {
        await saveString(EXPORT_CONFIGURATION_STORAGE_KEY, JSON.stringify(EXPORT_CONFIGURATION_DATA));
        return EXPORT_CONFIGURATION_DATA;
    }

    const { sampleInformation: savedSampleInformationData, cuppingSummary: savedCuppingSummaryData,
        cuppingDetail: savedCuppingDetailData } = JSON.parse(dataString);
    const mergedSampleInformationData = {};
    const mergedCuppingSummaryData = {};
    const mergedCuppingDetailData = {};

    for (const key in EXPORT_CONFIGURATION_DATA.sampleInformation) {
        mergedSampleInformationData[key] = savedSampleInformationData.hasOwnProperty(key) ? savedSampleInformationData[key] : EXPORT_CONFIGURATION_DATA.sampleInformation[key];
    }

    for (const key in EXPORT_CONFIGURATION_DATA.cuppingSummary) {
        mergedCuppingSummaryData[key] = savedCuppingSummaryData.hasOwnProperty(key) ? savedCuppingSummaryData[key] : EXPORT_CONFIGURATION_DATA.cuppingSummary[key];
    }

    for (const key in EXPORT_CONFIGURATION_DATA.cuppingDetail) {
        mergedCuppingDetailData[key] = savedCuppingDetailData.hasOwnProperty(key) ? savedCuppingDetailData[key] : EXPORT_CONFIGURATION_DATA.cuppingDetail[key];
    }

    return {
        sampleInformation: mergedSampleInformationData,
        cuppingSummary: mergedCuppingSummaryData,
        cuppingDetail: mergedCuppingDetailData
    };
}

export const saveConfigurationData = async (sampleInformation, cuppingSummary, cuppingDetail) => {
    const data = {
        sampleInformation,
        cuppingSummary,
        cuppingDetail
    };
    await saveString(EXPORT_CONFIGURATION_STORAGE_KEY, JSON.stringify(data));
}

export const convertConfigurationData = async () => {
    const storeData = await getExportConfigurationData();
    return {
        sampleInformation: Object.keys(storeData.sampleInformation).map(config => ({
            label: config,
            value: storeData.sampleInformation[config]
        })),
        cuppingSummary: Object.keys(storeData.cuppingSummary).map(config => ({
            label: config,
            value: storeData.cuppingSummary[config]
        })),
        cuppingDetail: Object.keys(storeData.cuppingDetail).map(config => ({
            label: config,
            value: storeData.cuppingDetail[config]
        })),
    }
}

export const convertKeysToSnakeCase = (obj) => {
    return mapKeys(obj, (value, key) => snakeCase(key)); // Convert top-level keys
};

export const convertNestedKeys = (obj) => {
    return mapValues(convertKeysToSnakeCase(obj), (value) =>
        Object.keys(value)
            .filter((key) => value[key] === true) // Only include keys with value `true`
            .map((key) => snakeCase(key))
    );
};