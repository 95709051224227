import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete, Box, IconButton, TextField, Typography } from "@mui/material";

import { colors } from "assets";

import { useStyles } from "./simplified-common-mobile-input.styles";
import { IconArrowDown, IconArrowUp } from "assets/images";
import { debounce, isObject } from "lodash";
import { string } from "mobx-state-tree/dist/internal";

interface SimplifiedCommonMobileInputProps {
  label: string,
  component: ReactNode,
  type?: 'single-line' | 'multi-line' | undefined,
}

export const SimplifiedCommonMobileInput = (props: SimplifiedCommonMobileInputProps) => {
  const classes = useStyles();
  const { label, component, type } = props;

  return (
    <Box className={type === 'multi-line' ? classes.multilineContainer : classes.container}>
      <Box className={type === 'multi-line' ? classes.multilineLabelContainer : classes.labelContainer}>
        <Typography fontSize={12} fontWeight={500}>
          {label}
        </Typography>
      </Box>
      {component}
    </Box>
  )
}

interface SimplifiedCommonMobileTextInputProps {
  id: any,
  value: any,
  field: string,
  handleChange: any,
  type?: string,
  placeholder?: string,
  disableDebounceInput?: boolean,
}

export const SimplifiedCommonMobileTextInput: React.FC<SimplifiedCommonMobileTextInputProps> = (props: SimplifiedCommonMobileTextInputProps) => {
  const { id, field, value, handleChange, type, placeholder, disableDebounceInput } = props;
  const [inputValue, setInputValue] = useState(value ?? '');

  const isMultiLine = type === 'multi-line';
  const debouncedHandleChange = useMemo(
    () => debounce((newValue) => handleChange(id, field, newValue), 500),
    [id, field, handleChange]
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    if (disableDebounceInput) {
      handleChange(id, field, newValue);
    } else {
      debouncedHandleChange(newValue);
    }
  };

  useEffect(() => {
    setInputValue(value ?? '');
  }, [value]);

  useEffect(() => {
    return () => debouncedHandleChange.cancel();
  }, [debouncedHandleChange]);
  return (
    <TextField
      sx={isMultiLine ? {
        flex: 1,
        '& input::placeholder, & textarea::placeholder': {
          position: 'absolute',
          top: 0,
          left: 0,
        },
        '& .MuiOutlinedInput-root': {
          alignItems: 'flex-start',
          paddingTop: '8px',
          paddingLeft: '8px'
        },
      } : { flex: 1 }}
      InputProps={{
        style: {
          height: isMultiLine ? 'unset' : '40px',
          minHeight: isMultiLine ? '80px' : 'unset',
          overflow: isMultiLine ? 'auto' : 'hidden',
          fontSize: '12px',
          border: `1px solid ${colors.border.primary}`,
        },
      }}
      value={inputValue}
      onChange={handleInputChange}
      placeholder={placeholder}
      variant="outlined"
      type={type ?? 'text'}
      multiline={isMultiLine}
    />
  )
};

interface SimplifiedCommonMobileDescriptorInputProps {
  id: any,
  value: any,
  field: string,
  handleChange: any,
  valueOptions: any[],
  placeholder?: string,
}

export const SimplifiedCommonMobileDescriptorInput: React.FC<SimplifiedCommonMobileDescriptorInputProps> = (props: SimplifiedCommonMobileDescriptorInputProps) => {
  const { id, field, value, handleChange, valueOptions, placeholder } = props;
  const [inputHeight, setInputHeight] = useState(40);
  const textRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const [selectedValues, setSelectedValues] = React.useState([]);

  useEffect(() => {
    if (textRef.current) {
      setInputHeight(Math.max(40, textRef.current.scrollHeight + 8)); // Adjust height based on text
    }
  }, [value]);

  const handleValueChange = (event, newValue) => {
    const selectedValues = newValue.map((item) =>
      typeof item === 'string' ? item : item.value
    );
    setSelectedValues(newValue);
    setInputValue("");
    handleChange(id, field, selectedValues);
  };

  const handleOnInputChange = (event, newValue, reason) => {
    if (reason !== "reset") {
      setInputValue(newValue);
    }
  };
  const handleBlur = () => {
    if (inputValue.trim() === "" || !valueOptions.some((opt) => opt.label === inputValue)) {
      setInputValue('');
      handleChange(id, field, selectedValues);
    }
  };

  useEffect(() => {
    setSelectedValues(value);
  }, [value]);

  return (
    <Box display="flex" alignItems="center" flex={1} position='relative'>
      <Autocomplete
        inputValue={inputValue}
        onInputChange={handleOnInputChange}
        onBlur={handleBlur}
        multiple
        clearIcon={null}
        open={isOpen}
        options={valueOptions}
        value={value.map((val) => valueOptions.find((opt) => opt.value === val) || val)}
        onChange={handleValueChange}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
        isOptionEqualToValue={(o, v) => o.value === v.value}
        renderTags={(tagValue) => (
          <div ref={textRef} style={{ whiteSpace: 'pre-wrap' }}>
            <Box paddingTop={1}>
              {tagValue.map((option, index) => (
                <React.Fragment key={index}>
                  {index > 0 ? ', ' : ''}
                  {option.label}
                </React.Fragment>
              ))}
            </Box>
          </div>
        )}
        componentsProps={{
          popupIndicator: {
            sx: { display: 'none' }, // Custom logic for the indicator
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={value && value.length > 0 ? '' : placeholder}
            sx={{
              '& .MuiInputBase-root': {
                minHeight: inputHeight,
                display: 'flex',
                alignItems: 'flex-start',
                padding: '8px',
              },
              '& .MuiOutlinedInput-root': {
                minHeight: inputHeight,
                padding: '8px',
              },
            }}
          />
        )}
        onOpen={() => setIsOpen(true)} // Track dropdown state
        onClose={() => setIsOpen(false)}
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          minHeight: 40,
          flex: 1,
        }}
      />
      <Box position='absolute' right={0} top={2}>
        <IconButton onClick={() => setIsOpen((prev) => !prev)}>
          {isOpen ? <IconArrowUp /> : <IconArrowDown />}
        </IconButton>
      </Box>
    </Box>
  );
};

interface SimplifiedCommonSelectInputProps {
  id: any,
  value: any,
  field: string,
  handleChange: any,
  placeholder?: string,
  options: string[] | { value: number | string, label: string }[],
}

export const SimplifiedCommonMobileSelectInput: React.FC<SimplifiedCommonSelectInputProps> = (props) => {
  const { id, field, value, handleChange, options, placeholder } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");

  const valueOptions = options.map((option: string | { value: number | string, label: string }) => ({
    value: isObject(option) ? option.value : option,
    label: isObject(option) ? option.label : option,
  }));
  valueOptions.unshift({ value: "", label: "" });

  const selectedOption = valueOptions.find((option) => option.value === (!!value ? value : ''));

  const handleValueChange = (event, newValue) => {
    handleChange(id, field, newValue?.label || '');
    setInputValue(newValue?.label || "");
  };

  const handleOnInputChange = (event, newValue, reason) => {
    if (reason !== "reset") {
      setInputValue(newValue);
    }
  };

  const handleBlur = () => {
    const isValid = valueOptions.some((option) => option.label === inputValue);
    if (!isValid) {
      setInputValue(value || selectedOption?.label || "");
    }
  };

  useEffect(() => {
    setInputValue(!!value ? value : '');
  }, [value]);
  return (
    <Box display="flex" alignItems="center" flex={1} position='relative'>
      <Autocomplete
        disableClearable
        open={isOpen}
        options={valueOptions}
        value={selectedOption}
        onChange={handleValueChange}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
        isOptionEqualToValue={(o, v) => o.value === (isObject(v) ? v.value : v)}
        inputValue={inputValue}
        onInputChange={handleOnInputChange}
        onBlur={handleBlur}
        componentsProps={{
          popupIndicator: {
            sx: { display: 'none' },
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            sx={{
              '& .MuiOutlinedInput-root': {
                height: 40,
              },
            }}
          />
        )}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flex: 1,
        }}
      />
      <Box position='absolute' right={0} top={0}>
        <IconButton onClick={() => setIsOpen((prev) => !prev)}>
          {isOpen ? <IconArrowUp /> : <IconArrowDown />}
        </IconButton>
      </Box>
    </Box>
  );
};
