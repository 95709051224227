import {Instance, types} from "mobx-state-tree";
import { withSetValue} from "../extensions";

export const CuppingResultAggregateModel = types
  .model("CuppingResultAggregate")
  .props({
    totalScore: types.maybeNull(types.optional(types.number, 0)),
    descriptors: types.optional(types.array(types.string), []),
    internalNotes: types.maybeNull(types.optional(types.string, '')),
    sampleResult: types.maybeNull(types.optional(types.string, '')),
    sampleScoresIds: types.optional(types.array(types.number), []),
    syncBy: types.maybeNull(types.optional(types.string, '')),
    syncAt: types.maybeNull(types.optional(types.string, '')),
  })
  .extend(withSetValue)

type CuppingResultAggregateType = Instance<typeof CuppingResultAggregateModel>
export interface CuppingResultAggregate extends CuppingResultAggregateType {}
