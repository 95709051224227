import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import {colors} from "../../../../assets";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    scoreWrapper: {
      display: 'flex',
      padding: '16px',
      borderBottom: '1px solid #EBE8F2'
    },
    descriptor: {
      width: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
      wordBreak: 'break-word'
    },
    descriptorText: {
      color: colors.primary.main,
      fontWeight: '500'
    },
  })
)
