import { Api } from "./api"

export class SampleScoreApi extends Api {
  constructor() {
    super('sample_scores', [
      'roastLevel', 'cuppingProtocol', 'fragrance', 'fragranceIntensity', 'fragranceDescriptors', 'fragranceDry', 'fragranceCrust', 'fragranceBreak', 'fragranceAromaDescriptors', 'fragranceAromaNote',
      'acidity', 'acidityDescriptors', 'acidityIntensity', 'acidityIntensity', 'aroma', 'aromaIntensity', 'dryAcidity', 'mediumAcidity', 'sweetAcidity', 'acidityNote',
      'body', 'bodyDescriptors', 'bodyIntensity', 'flavor', 'flavorDescriptors', 'flavorIntensity', 'flavorAftertasteDescriptors',
      'saltyTaste', 'sourTaste', 'sweetTaste', 'bitterTaste', 'umamiTaste', 'flavorAftertasteNote',
      'aftertaste', 'aftertasteDescriptors', 'aftertasteIntensity', 'aftertasteBrackishToSavory',
      'balance', 'balanceDescriptors', 'salt', 'saltDescriptors', 'saltLowSalt', 'saltHiAcid', 'bittersweet', 'bittersweetLowBitter', 'bittersweetHiSweet',
      'mouthfeel', 'mouthfeelDescriptors', 'mouthfeelRoughToSmooth', 'mouthfeelIntensity', 'mouthfeelNote', 'roughMouthfeel', 'creamyMouthfeel', 'smoothMouthfeel', 'mouthDryingMouthfeel', 'metallicMouthfeel',
      'cleanCupScore', 'cleanCupDescriptors', 'sweetnessScore', 'sweetnessIntensity', 'sweetnessNote', 'sweetnessDescriptors', 'overall', 'overallNote',
      'uniformity1', 'uniformity2', 'uniformity3', 'uniformity4', 'uniformity5', 'uniformityScore', 'cleanCup1', 'cleanCup2', 'cleanCup3', 'cleanCup4', 'cleanCup5',
      'sweetness1', 'sweetness2', 'sweetness3', 'sweetness4', 'sweetness5', 'defects', 'defectsDescriptors', 'notes',
      'nonUniform1', 'nonUniform2', 'nonUniform3', 'nonUniform4', 'nonUniform5', 'nonUniformScore', 'uniformityDetected',
      'defective1', 'defective2', 'defective3', 'defective4', 'defective5',
      'sampleId', 'cuppingSessionId', 'totalScore', 'defectDetected',
      'emoticons', 'interestStatus', 'tastingScore', 'tastingDescriptors','consistencyComment',
      'scores','isSelected', 'physicalAspectDescription', 'physicalAspectRate', 'internalNotes', 'result'
    ])
  }
}
