import React from "react";
import {Autocomplete, Button, CircularProgress, IconButton, InputAdornment, TextField} from "@mui/material";
import {styled} from "@mui/material/styles";
import {
  findRoute
} from 'constants/routes'
import {useNavigate} from "react-router-dom";
import { ClearOutlined, SearchOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { debounce, isEmpty } from "lodash";
import { useStores } from "models"
import { isMobile } from "react-device-detect";

interface Option {
  value: any
  uniqueValue:any
  group: string
  uniqToken?: string
  firstLetter: string
  count: number
}


interface GroupData {
  count: number
  items: Array<{
    id: string
    name?: string
    purchaseContractReference?: string
    salesContractReference?: string
    sampleOrderNumber?: string
    shipmentNumber?: string
    uniqueToken?: string
    sampleUniqueNumber?: string
    purchaseGrade?: string
  }>;
}

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'initial',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
}));

const GroupItems = styled('ul')({
  padding: 0,
});

const valueData = (category, item) => {
  switch (category) {
    case 'masterSamplesById':
      return item.sampleUniqueNumber
    case 'masterSamplesByPg':
      return item.purchaseGrade
    case 'purchaseContractSamples':
      return item.purchaseContractReference
    case 'salesContractSamples':
      return item.salesContractReference
    case 'sampleOrders':
      return item.sampleOrderNumber
    case 'sampleShipments':
      return item.shipmentNumber
    default:
      return item.name;
  }
}

const transformData = (data) => {
  const result: Option[] = [];

  Object.entries(data).forEach(([category, groupData]) => {

    const groupCount = (groupData as GroupData).count;

    (groupData as GroupData ).items.forEach((item) => {
      const value = valueData(category, item)

      const uniqueValue = `${value}_${item.uniqueToken || Math.random().toString(36).substr(2, 9)}`;

      result.push({
        value,
        uniqueValue,
        group: category,
        uniqToken: item.uniqueToken,
        firstLetter: category,
        count: groupCount,
      });
    });
  });

  return result;
};

export const SearchBar: React.FC = ()=> {

  const reviewSamplesLink = findRoute('reviewSamples')
  const searchSampleOrder = findRoute('sampleOrders')
  const salesContractDetails = findRoute('salesContracts')
  const contracts = findRoute("contracts")
  const sampleShipments = findRoute('sampleShipments')

  const [loading, setLoading] = React.useState(false); 

  const navigate = useNavigate()

  const { t } = useTranslation()

    const {
      dashboardStore: { getSearch },
    } = useStores()

  const [inputValue, setInputValue] = React.useState('')
  const [selectedValue, setSelectedValue] = React.useState<Option | null>(null); 
  const [open, setOpen] = React.useState(false); 
  const [options, setOptions] = React.useState<Option[]>([]);

  const debouncedSearch = React.useMemo(() => 
    debounce(async (value: string) => {
      try {
        setLoading(true)
        const data = await getSearch(value);
        const searchData = {
          masterSamplesById: data.masterSamplesById,
          masterSamplesByPg: data.masterSamplesByPg,
          purchaseContractSamples: data.purchaseContractSamples,
          salesContractSamples: data.salesContractSamples,
          sampleOrders: data.sampleOrders,
          sampleShipments: data.sampleShipments,
          samples: data.samples,
        };

        const transformedData = await transformData(searchData);
        setOptions(transformedData);
      } catch (error) {
        console.error("Error during search:", error)
      } finally {
        setLoading(false)
      }
    }, 300),
    [getSearch]  
  );

  const onSearch = (e) => {
    if (e.key !== 'Enter' || isEmpty(selectedValue)) return

    switch(selectedValue?.group){
      case "purchaseContractSamples":
        navigate(`${contracts}/${selectedValue?.value}/details`)
        break
      case "salesContractSamples":
        navigate(`${salesContractDetails}/${selectedValue?.value}/details`)
        break
      case "sampleOrders":
        navigate(`${searchSampleOrder}/${selectedValue.uniqToken}`)
        break
      case "sampleShipments":
       navigate(`${sampleShipments}/${selectedValue.uniqToken}`)
        break 
      default:
        navigate(`${reviewSamplesLink}/${selectedValue?.uniqToken}/details?tab=cupping`)
        break
    }
  }

  const handleViewAll = (params) => {
    setInputValue(inputValue)
    setOpen(false)

    switch(params.group){
      case "purchaseContractSamples":
        navigate(`${reviewSamplesLink}?search=${inputValue}&&flag=purchaseContract`);
        break
      case "salesContractSamples":
        navigate(`${reviewSamplesLink}?search=${inputValue}&&flag=salesContract`);
        break
      case "sampleOrders":
        navigate(`${searchSampleOrder}/?search=${inputValue}`)
        break
      case "sampleShipments":
       navigate(`${sampleShipments}/?search=${inputValue}`)
        break 
      case "masterSamplesById":
        navigate(`${reviewSamplesLink}?search=${inputValue}&&flag=masterSamplesById`);
        break
      case "masterSamplesByPg":
        navigate(`${reviewSamplesLink}?search=${inputValue}&&flag=masterSamplesByPg`);
        break
      default:
        navigate(`${reviewSamplesLink}?search=${inputValue}&&flag=name`);
        break
    }

  }

  const handleClear = () => {
    setInputValue('')
  };

  const handleChange = (event, newValue) => {
    setSelectedValue(newValue)
  }

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue)
    setOpen(true)

    if(newInputValue.length < 1) return

    debouncedSearch(newInputValue)
  }

  const handleDefaultData = () => {
    if(!isEmpty(selectedValue)) return

    setInputValue('')
    setOptions([])
    setSelectedValue(null)
    setOpen(false)
  };

  React.useEffect(() => {
    return () => {
      debouncedSearch.cancel()
    };
  }, [debouncedSearch]);


  React.useEffect(() => {
    if (inputValue.length > 1) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [inputValue.length]);

  return (
    <Autocomplete
      id="search-bar"
      open={open} // Controlled open state
      onClose={() => setOpen(false)} // Close the popup when needed
      options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
      groupBy={(option) => option.firstLetter}
      getOptionLabel={(option) => option.value || ""}
      isOptionEqualToValue={(option, value) => option.uniqueValue === value.uniqueValue} 
      sx={isMobile ? { width: window.innerWidth - 200 } : { width: 350 }}
      value={selectedValue} // Controlled component
      onChange={handleChange} // Update the selected value
      inputValue={inputValue} // Sync input value
      onInputChange={handleInputChange}
      //disableClearable
      popupIcon={null} // Removes the dropdown arrow icon   
      clearIcon={
      <IconButton onClick={handleClear}>
        <ClearOutlined />
      </IconButton>
    }
      renderInput={(params) =>
        <TextField
          {...params}
          variant="standard"
          placeholder={t('common.search')}
          onKeyPress={onSearch}
          value={inputValue}
          onClick={() => {
            handleDefaultData()
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {loading ? <CircularProgress size={20} color="primary" /> : null} {/* Spinner in input */}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          sx={{
            "& .MuiInput-underline:before": { borderBottom: "none" }, // Remove the underline
            "& .MuiInput-underline:after": { borderBottom: "none" },  // Remove the focused underline
            "& .MuiInput-underline:hover:before": { borderBottom: "none" }, // Prevent underline on hover
          }}
        />
      }
      renderGroup={(params) => {
         const children = Array.isArray(params.children) ? params.children : [];
         const groupCount = options.find((opt) => opt.group === params.group)?.count || 0;
        
        return(
          <li key={`group-${params.group}`}>     
            <GroupHeader>
              {t(`globalSearch.${params.group}`)} {`(${groupCount})`}
            </GroupHeader>
            <GroupItems>
              {children.map((child, index) => (
                <li key={`group-${params.group}-option-${index}-${loading}`}>{child}</li>
              ))}
            <li>
            <Button
              size="small"
              onClick={() => handleViewAll(params)}
              variant="text"
              sx={{ml: 1}}
            >
              See More
            </Button>
            </li>
            </GroupItems>
          </li>
        
      )}
    }
    />
  );
}