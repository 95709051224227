import React, {useCallback, useEffect, useState} from 'react'
import {debounce} from "lodash"
import {useTranslation} from "react-i18next"
import {observer} from "mobx-react-lite"
import {Box, Button, Checkbox, FormControlLabel, Grid, Popover, selectClasses, Stack, Typography} from "@mui/material"
import {Cancel} from "@mui/icons-material"
import {
  ColumnFiltersState,
  createColumnHelper,
  getCoreRowModel,
  useReactTable
} from "@tanstack/react-table"
import {Link} from "react-router-dom"
import PivotTableChartIcon from '@mui/icons-material/PivotTableChart';
import { loadString, saveString } from 'utils/storage';
import { HEADER_SELECTOR_CONTRACT_STORAGE_KEY } from 'config/env';

import {useStores} from "models"
import {FilterChip, FilterRequestTable} from "components"

import {useStyles} from "./contracts.styles"
import {SampleModal} from "./components"
import { countryName } from 'utils'
import { formatDate, headerSelectorContract } from 'constants/form'
import moment from 'moment'

const PREFIX = 'contracts'

const columnHelper = createColumnHelper<any>()

export const Contracts: React.FC = observer(() => {
  const { t } = useTranslation()
  const classes = useStyles()
  const {
    contractStore: { page, newSamples, totalPage, getContracts, createSamples, setValue: setContractValue },
    notificationStore
  } = useStores()

  const [rowSelection, setRowSelection] = useState({})
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [openModal, setOpenModal] = useState(false)
  const [selectedSamples, setSelectedSamples] = useState<any[]>([])
  const [data, setData] = useState<any[]>([])
  const hasFilter = columnFilters.length > 0
  const [headerSelector, setHeaderSelector] = React.useState<null | HTMLElement>(null)
  const openHeaderSelector = Boolean(headerSelector)
  const [tmpHeaderSelector, setTmpHeaderSelector] = React.useState<any>([{key: '', data: []}]);

  const getHeaderSelector = async() => {
    const dataString = await loadString(HEADER_SELECTOR_CONTRACT_STORAGE_KEY)
    if(!dataString) {
      await saveString(HEADER_SELECTOR_CONTRACT_STORAGE_KEY, JSON.stringify(headerSelector))
      setTmpHeaderSelector(headerSelectorContract)
      return
    }

    setTmpHeaderSelector(JSON.parse(dataString))
  }

  const columns = [
    columnHelper.accessor('id', {
      id: 'id',
      enableColumnFilter: false,
      header: () => <Box />,
      cell: info =>
        <Box textAlign="left">
          <Checkbox
            checked={info.row.getIsSelected()}
            onChange={info.row.getToggleSelectedHandler()}
          />
        </Box>,
	    meta: {
		    hideFilter: true,
		    style: { minWidth: '1px' }
	    }
    }),
    columnHelper.accessor('purchaseContractReference', {
      id: 'purchaseContractReference',
      header: t(`${PREFIX}.table.purchaseContract`),
      cell: info => <Link to={`/contracts/${info.renderValue()}/details`} >{info.renderValue()}</Link>,
    }),
    columnHelper.accessor('purchaseGrade', {
      id: 'purchaseGrade',
      header: t(`${PREFIX}.table.purchaseGrade`),
      cell: info => info.renderValue() ? info.renderValue() : '-',
    }),
    columnHelper.accessor('supplierName', {
      id: 'supplierName',
      header: t(`${PREFIX}.table.supplier`),
      cell: info => info.renderValue() ? info.renderValue() : '-',
    }),
    columnHelper.accessor('country', {
      id: 'country',
      header: t(`${PREFIX}.table.country`),
      cell: info => info.renderValue() ? info.renderValue() : '-',
    }),
    columnHelper.accessor('salesContractReference', {
      id: 'salesContractReference',
      header: t(`${PREFIX}.table.salesContract`),
      cell: info => info.renderValue() ? info.renderValue() : '-',
    }),
    columnHelper.accessor('species', {
      id: 'species',
      header: t(`${PREFIX}.table.species`),
      cell: info => info.renderValue() ? info.renderValue() : '-',
    }),
    columnHelper.accessor('harvest', {
      id: 'harvest',
      header: t(`${PREFIX}.table.harvest`),
      cell: info => info.renderValue() ? info.renderValue() : '-',
    }),
    columnHelper.accessor('numberOfBag', {
      id: 'numberOfBag',
      header: t(`${PREFIX}.table.numberOfBag`),
      cell: info => info.renderValue() ? info.renderValue() : '-',
    }),
    columnHelper.accessor('cargoNumber', {
      id: 'cargoNumber',
      header: t(`${PREFIX}.table.cargoNumber`),
      cell: info => info.renderValue() ? info.renderValue() : '-',
    }),
    columnHelper.accessor('containerNumber', {
      id: 'containerNumber',
      header: t(`${PREFIX}.table.containerNumber`),
      cell: info => info.renderValue() ? info.renderValue() : '-',
    }),
    columnHelper.accessor('deliveryInformation', {
      id: 'deliveryInformation',
      header: t(`${PREFIX}.table.deliveryInformation`),
      cell: info => info.renderValue() ? info.renderValue() : '-',
    }),
    columnHelper.accessor('externalIdentification', {
      id: 'externalIdentification',
      header: t(`${PREFIX}.table.externalIdentification`),
      cell: info => info.renderValue() ? info.renderValue() : '-',
    }),
    columnHelper.accessor('lotNumberOrIcoMarks', {
      id: 'lotNumberOrIcoMarks',
      header: t(`${PREFIX}.table.lotNumberOrIcoMarks`),
      cell: info => info.renderValue() ? info.renderValue() : '-',
    }),
    columnHelper.accessor('name', {
      id: 'name',
      header: t(`${PREFIX}.table.name`),
      cell: info => info.renderValue() ? info.renderValue() : '-',
    }),
    columnHelper.accessor('phoneNumber', {
      id: 'phoneNumber',
      header: t(`${PREFIX}.table.phoneNumber`),
      cell: info => info.renderValue() ? info.renderValue() : '-',
    }),
    columnHelper.accessor('processName', {
      id: 'processName',
      header: t(`${PREFIX}.table.processName`),
      cell: info => info.renderValue() ? info.renderValue() : '-',
    }),
    columnHelper.accessor('receivedAt', {
      id: 'receivedAt',
      header: t(`${PREFIX}.table.receivedAt`),
      cell: info => info.renderValue() ? moment(info.renderValue()).format(formatDate) : '-',
    }),
    columnHelper.accessor('sampleReference', {
      id: 'sampleReference',
      header: t(`${PREFIX}.table.sampleReference`),
      cell: info => info.renderValue() ? info.renderValue() : '-',
    }),
    columnHelper.accessor('shipmentDate', {
      id: 'shipmentDate',
      header: t(`${PREFIX}.table.shipmentDate`),
      cell: info => info.renderValue() ? moment(info.renderValue()).format(formatDate) : '-',
    }),
    columnHelper.accessor('strategy', {
      id: 'strategy',
      header: t(`${PREFIX}.table.strategy`),
      cell: info => info.renderValue() ? info.renderValue() : '-',
    }),
    columnHelper.accessor('warehouseReference', {
      id: 'warehouseReference',
      header: t(`${PREFIX}.table.warehouseReference`),
      cell: info => info.renderValue() ? info.renderValue() : '-',
    }),
    columnHelper.accessor('description', {
      id: 'description',
      header: t(`${PREFIX}.table.description`),
      cell: info => info.renderValue() ? info.renderValue() : '-',
    }),
  ]

  const visibleColumns = React.useMemo(() => {

    const visibleKeys = tmpHeaderSelector
      .filter((item) => item.value)
      .map((item) => item.key);
      
    if(visibleKeys.length !== 0 ) saveString(HEADER_SELECTOR_CONTRACT_STORAGE_KEY, JSON.stringify(tmpHeaderSelector))

    return columns.filter((col) => visibleKeys.includes(col.id!));
  }, [tmpHeaderSelector]);

  const table = useReactTable({
    data,
    columns: visibleColumns,
    state: { rowSelection, columnFilters },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
	  onColumnFiltersChange: (updaterOrValue) => {
		  setContractValue('page', 1)
		  setColumnFilters(updaterOrValue)
	  },
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id,
  })

  const renderListHeaderSelector = (dataList) => {
    return(
      <Box sx={{width: 400, p: 1}}>
        <Grid container >
        {dataList.map((data, index) => (
          <Grid item xs={6} key={index}>
            <FormControlLabel
              label={`${t(data.label)}`}
              control={
                <Checkbox
                  size="medium"
                  checked={data.value}
                  onChange={() => handleCheckboxHeaderSelectorChange(data.key)}
                />
              }
            />
          </Grid>
        ))}
      </Grid>
    </Box>
    )
  }

  const renderButtonHeaderSelector = () => {
    return (
      <>
        <Button
          variant="contained"
          sx={{ borderRadius: 1 }}
          onClick={(event) => setHeaderSelector(event.currentTarget)}
        >
          <PivotTableChartIcon />
        </Button>
        <Popover
          open={openHeaderSelector}
          anchorEl={headerSelector}
          onClose={() => {
            setHeaderSelector(null)
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          {renderListHeaderSelector(tmpHeaderSelector.filter((data) => data.key !== 'id'))}
      
        </Popover>
      </>
    )
  }

  const handleCheckboxHeaderSelectorChange = (key) => {
    setTmpHeaderSelector((prev) =>
      prev.map((item) =>
        item.key === key ? { ...item, value: !item.value } : item
      )
    );
  };

  const clearFilter = () => {
    setRowSelection({})
    selectedSamples.length = 0
  }

  const filterLabel = (sample) => {
    return `${sample.purchaseContractReference}${sample.purchaseGrade ? `; ${sample.purchaseGrade}` : ''} ${sample.salesContractReference ? `; ${sample.salesContractReference}` : ''} ${sample.country ? `; ${sample.country}` : ''}`
  }

  const onRemoveSelectedSample = (sample) => {
    setSelectedSamples((prevSelected) =>
      prevSelected.filter((s) => parseInt(s.id) !== parseInt(sample.id))
    );
    setRowSelection((prevRowSelection) => {
      const updatedRowSelection = { ...prevRowSelection };
      delete updatedRowSelection[sample.id];
      return updatedRowSelection;
    });
  };

  const onCreateSample = () => {
    setOpenModal(true)
  }

  const onGetContracts = useCallback(
	  debounce(async (option?) => {
	    try {
	      notificationStore.setLoading(true)
	      const contracts = await getContracts(option)
	      setData(contracts)
	    } finally {
	      notificationStore.setLoading(false)
	    }
  }, 500), [])

  useEffect(() => {
    const ids = Object.keys(rowSelection).map(id => id)

    setSelectedSamples(selectedSamples.filter(item => ids.includes(item.id)))

    setSelectedSamples(prevSelected => [
      ...prevSelected,
      ...ids
        .map(id => data.find(item => item.id === id))
        .filter(item => item && !prevSelected.some(selected => selected.id === item.id)),
    ])
  }, [rowSelection])

  useEffect(() => {
    onGetContracts(columnFilters)
  }, [JSON.stringify(columnFilters), page])

  React.useEffect(() => {
    getHeaderSelector()
  }, [])

  return (
    <Box px={4} py={2.5}>
      <Typography variant='h4'>
        {t(`${PREFIX}.title`)}
      </Typography>
      {selectedSamples.length > 0 && (
        <Stack direction="row" spacing={1} mb={2} useFlexGap flexWrap="wrap">
          {selectedSamples.map(s => (
            <FilterChip
              key={s.id}
              label={filterLabel(s)}
              onRemove={() => onRemoveSelectedSample(s)}
            />
          ))}

          <Button
            variant="text"
            size="small"
            color="error"
            onClick={clearFilter}
            sx={{px: 1}}
          >
            {t('common.clearFilter')}
          </Button>
        </Stack>
      )}
      <Stack direction="row" spacing={2} my={2} justifyContent="end">
        {hasFilter && (
          <Button
            variant="contained"
            sx={{borderRadius: '50px'}}
            startIcon={<Cancel />}
            onClick={() => setColumnFilters([])}
          >
            {t(`${PREFIX}.button.clearFilter`)}
          </Button>
        )}
        <Button
          variant="contained"
          disabled={selectedSamples.length === 0}
          onClick={onCreateSample}
        >
          {t(`${PREFIX}.button.createSample`)}
        </Button>
        {renderButtonHeaderSelector()}
      </Stack>

      <Box className={classes.table}>
	      <FilterRequestTable
		      table={table}
		      page={page}
		      totalPage={totalPage}
		      onChangePage={(e, p) => setContractValue('page', p)}
	      />

        {/*<FilterTable table={table}/>*/}
      </Box>

      <SampleModal
        open={openModal}
        onClose={() => {
          clearFilter()

          setOpenModal(false)
        }}
        samples={selectedSamples}
        createSamples={createSamples}
        notificationStore={notificationStore}
      />
    </Box>
  )
})

export default Contracts
