/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-06 20:03:53
 * @modify date 2022-04-14 23:17:39
 */

import React from 'react'
import {camelCase, clone, cloneDeep, debounce, find, isEmpty, isNil, map, truncate} from 'lodash'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ArrowDropDown, CloudUpload, DeleteOutline, ListOutlined, MoreHoriz, TableChartOutlined, Settings as SettingsIcon, Cancel  } from '@mui/icons-material'
import { Container, Button, Avatar, Menu, MenuItem, Grid, Stack, Typography, Box, SvgIcon, Tooltip, IconButton } from '@mui/material'
import moment from 'moment'
import clsx from 'clsx'
import QrCode2Icon from '@mui/icons-material/QrCode2';

import { useStores } from 'models'
import { globalAction } from 'utils'
import { findRoute } from 'constants/routes'
import { IconExport, IconStartCupping, emptySearch, IconEdit, IconReview, IconPlayCircle, IconPauseCircle, IconLabel, IconTableSearchBar } from 'assets/images'
import { Pagination, ModalConfirm, HeaderTitleWide, FilterRequestTable } from 'components'
import { cuppingProtocols, cuppingSessionFilters, cuppingSessionSorts, formatDateWithTime, viewCuppingSession } from 'constants/form'
import { MenuIcon } from 'components'
import { loadString, saveString , save} from 'utils/storage';
import { VIEW_TABLE_CUPPING_SESSION_STORAGE_KEY } from 'config/env';
import { colors } from 'assets'

import { SessionItem, SettingQrCode } from './components'
import { useStyles } from './cupping-sessions.styles'
import { PrintQr } from 'pages/modal/print-qr/print-qr'
import { isMobile } from 'react-device-detect'
import { Sample } from 'models/sample'
import { MultipleGenerateLabel } from 'pages/modal/multiple-generate-label/multiple-generate-label'
import { ExportConfigurationModal } from 'components/export-configuration-modal/export-configuration-modal'
import { convertNestedKeys } from 'components/export-configuration-modal/export-configuration-modal-utils'
import { ColumnFiltersState, createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { FilterSelect } from "components/filter-request-table/components/filter-component";

type RenderMenuItemsProps = {
  id: any
  uniqueToken: any
  isPublic: any
  canNotBeEdited: any
  onPlayOrPause: any
  isPaused: any
  tooltipCanNotBeEdited: any
  reasonCanNotBeReviewed: any
  canNotResendToWebhook: any
  reasonCanNotBeDeleted: any
  canNotBeDeleted: any
  canNotBeReviewed: any
  onReview: () => void
  onEdit: () => void
  onResendWebhook: ()=> any
  onRemove: ()=> any
  onPrintQRCode: ()=> any
  onQrCodeSetting: ()=> any
  onPrintAllLabel: ()=> any
};

const RenderMenuItems: React.FC<RenderMenuItemsProps> = ({
  id,
  uniqueToken,
  isPublic,
  canNotBeEdited,
  onPlayOrPause,
  isPaused,
  tooltipCanNotBeEdited,
  reasonCanNotBeReviewed,
  canNotResendToWebhook,
  reasonCanNotBeDeleted,
  canNotBeDeleted,
  canNotBeReviewed,
  onReview,
  onEdit,
  onResendWebhook,
  onRemove,
  onPrintQRCode,
  onQrCodeSetting,
  onPrintAllLabel
}) => {
  const { t } = useTranslation()
  const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorMenu)

  return (
    <>
      <IconButton
        size="small"
        color="primary"
        onClick={(event) => setAnchorMenu(event.currentTarget)}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        anchorEl={anchorMenu}
        open={open}
        onClose={() => setAnchorMenu(null)}
        onClick={() => setAnchorMenu(null)}
      >
        {isPublic &&
          <MenuItem sx={{ display: { sm: 'block', md: 'none' } }} disabled={canNotBeEdited} onClick={onPlayOrPause}>
            <MenuIcon><SvgIcon component={isPaused ? IconPlayCircle : IconPauseCircle} inheritViewBox /></MenuIcon>
            {t(`cuppingSession.${isPaused ? 'start' : 'pause'}`)}
          </MenuItem>
        }
        <Tooltip title={tooltipCanNotBeEdited} placement='left' arrow>
          <span>
            <MenuItem sx={{ display: { sm: 'block', md: 'none' } }} disabled={canNotBeEdited} onClick={onEdit}>
              <MenuIcon><SvgIcon component={IconEdit} inheritViewBox /></MenuIcon>
              {t('common.edit')}
            </MenuItem>
          </span>
        </Tooltip>
        <Tooltip title={reasonCanNotBeReviewed || ''} placement='left' arrow>
          <span>
            <MenuItem sx={{ display: { sm: 'block', md: 'none' } }} disabled={canNotBeReviewed} onClick={onReview}>
              <MenuIcon><SvgIcon component={IconReview} inheritViewBox /></MenuIcon>
              {t('cuppingSession.review')}
            </MenuItem>
          </span>
        </Tooltip>
        {!canNotResendToWebhook &&
          <MenuItem disabled={canNotResendToWebhook} onClick={onResendWebhook}>
            <MenuIcon><CloudUpload /></MenuIcon>
            {t('cuppingSession.sendToWebhook')}
          </MenuItem>
        }
        <Tooltip title={reasonCanNotBeDeleted || ''} placement='left' arrow>
          <span>
            <MenuItem disabled={canNotBeDeleted} onClick={onRemove}>
              <MenuIcon><DeleteOutline /></MenuIcon>
              {t('common.remove')}
            </MenuItem>
          </span>
        </Tooltip>

        {isPublic &&
          <MenuItem onClick={onPrintQRCode}>
            <MenuIcon><QrCode2Icon /></MenuIcon>
            {t('sample.printQRCode')}
          </MenuItem>
        }

        {isPublic &&
          <MenuItem onClick={onQrCodeSetting}>
            <MenuIcon><SettingsIcon /></MenuIcon>
            {t('cuppingSession.setting.qrCodeSetting')}
          </MenuItem>
        }

        <MenuItem onClick={() => onPrintAllLabel()}>
          <MenuIcon><IconLabel /></MenuIcon>
          {t('cuppingSession.setting.printAllLabel')}
        </MenuItem>
      </Menu>
    </>
  )
}

export const CuppingSessions: React.FC = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()
  const {
    userStore: { canCreateCuppingSessions },
    companyStore: { isDormantAccount },
    cuppingSessionStore: {
      filter, page, totalPage, cuppingSessions, startCupping,
      getCuppingSessions, getCuppingSession, getCuppingSessionsWithSearch, removeCuppingSession, resendWebhook, playOrPause, updateRequestInformation,
      setValue: setCuppingSessionStoreValue, exportSamplesFromCuppingSessions
    },
    modalStore: { printQRCode, setValue: setModalValue },
    sampleStore: { getSamples,samples },
    reviewSampleStore: { setValue: setReviewSampleValue },
    sampleShipmentStore: { setSamplesToBeShipment, getNewSamples, samplesToBeShipment, setSamplesToBeShipmentMultiGenerateLabel },
    notificationStore
  } = useStores()

  const formCuppingSessionLink = '/cupping-sessions/new'
  const sampleInformationLink = findRoute('sampleInformation')
  const sampleScoreLink = findRoute('sampleScore')
  const sampleReviewLink = findRoute('sampleReview')
  const forbiddenLink = findRoute('forbidden')

  const [anchorFilter, setAnchorFilter] = React.useState<null | HTMLElement>(null)
  const openFilter = Boolean(anchorFilter)
  const [headerSorts, setHeaderSorts ] = React.useState(cuppingSessionSorts)
  const [openConfirm, setOpenConfirm] = React.useState(false)
  const [urlInvitationsQrCode, setUrlInvitationsQrCode] = React.useState('')
  const [selectDataCuppingSession, setSelectDataCuppingSession] = React.useState<any>({})
  const [openSetting, setOpenSetting] = React.useState(false)
  const [openMultipleGenerateLabel, setMultipleOpenGenerateLabel] = React.useState(false)
  const [selectedItems, setSelectedItems] = React.useState<Set<string>>(new Set());
  const [isOpenExportConfiguration, setOpenExportConfiguration] = React.useState<boolean>(false);
  const [selectedViewMenu, setSelectedViewMenu] = React.useState(viewCuppingSession[0].label)
  const [data, setData] = React.useState<any[]>([])
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([])
  const hasFilter = columnFilters.length > 0
  const [rowSelection, setRowSelection] = React.useState({})

  const columnHelper = createColumnHelper<any>()

  const getViewTable= async() => {
    const dataString = await loadString(VIEW_TABLE_CUPPING_SESSION_STORAGE_KEY)
    if(!dataString) {
      await  await saveString(VIEW_TABLE_CUPPING_SESSION_STORAGE_KEY, JSON.stringify(viewCuppingSession[0]))
      setSelectedViewMenu(viewCuppingSession[0].label)
      return
    }

    setSelectedViewMenu(JSON.parse(dataString).label)
  }

  const onGetCuppingSession= React.useCallback(
	  debounce(async (option?) => {
	    try {
	      notificationStore.setLoading(true)
	      const dataCupping = await getCuppingSessionsWithSearch(option)
	      setData(dataCupping)
	    } finally {
	      notificationStore.setLoading(false)
	    }
  }, 300), [])

  const columns = [
    columnHelper.accessor('numberForCompany', {
      id: 'numberForCompany',
      header: () => <Box textAlign="left">{t('cuppingSession.id')} #</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('ownerName', {
      id: 'ownerName',
      header: () => <Box textAlign="left">{t('cuppingSession.owner')} #</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('startsAt', {
      id: 'startsAt',
      header: () => <Box textAlign="left">{t('cuppingSession.time')} #</Box>,
      cell: info => moment(info.renderValue()).format(formatDateWithTime) ,
    }),
    columnHelper.accessor('name', {
      id: 'name',
      header: () => <Box textAlign="left">{t('cuppingSession.name')} #</Box>,
      cell: info => truncate(info.renderValue(), {'length': 70}),
    }),
    columnHelper.accessor('location', {
      id: 'location',
      header: () => <Box textAlign="left">{t('cuppingSession.location')} #</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('cuppingProtocol', {
      id: 'cuppingProtocol',
      header: () => <Box textAlign="left">{t('cuppingSession.cuppingProtocol')} #</Box>,
      cell: info =>t(`options.cuppingProtocol.${camelCase(info.renderValue())}`) ,
      meta: {
        renderFilterComponent: (info) => (
          <FilterSelect
          value={info.getFilterValue() || ''}
          onChange={(e) => info.setFilterValue(e.target.value)}
            options={cuppingProtocols}
            translationKey="cuppingProtocol" 
          />
        )
      }
    }),
    columnHelper.accessor('uniqueToken', {
      id: 'uniqueToken',
      enableColumnFilter: false,
      header: () => <Box textAlign="center">{t('cuppingSession.samplesInformation')}</Box>,
      cell: info => renderButtonSampleInformation(info.row.original, info.row.id),
    }),
    columnHelper.accessor('uniqueToken', {
      id: 'uniqueToken',
      enableColumnFilter: false,
      header: () => <Box textAlign="center">{t('cuppingSession.status')}</Box>,
      cell: info => renderButtonStatus(info.row.original),
    }),
    columnHelper.accessor('uniqueToken', {
      id: 'uniqueToken',
      enableColumnFilter: false,
      header: () => <Box textAlign="center">{t('common.edit')}</Box>,
      cell: info => renderButtonEdit(info.row.original),
    }),
    columnHelper.accessor('uniqueToken', {
      id: 'uniqueToken',
      enableColumnFilter: false,
      header: () => <Box textAlign="center">{t('cuppingSession.review')}</Box>,
      cell: info => renderButtonReview(info.row.original),
    }),
    columnHelper.accessor('uniqueToken', {
      id: 'uniqueToken',
      enableColumnFilter: false,
      header: () => <Box textAlign="center"></Box>,
      cell: info => renderButtonMenu(info.row.original),
    }),
  ]

  const renderButtonSampleInformation = (row, index) => {
    const {addSampleInfoTooltipMessage, canNotAddSampleInfo, uniqueToken, samplesWithInfoCount, samplesCount } = row

    return(
      <Box sx={{textAlign: "center"}}>
      <Tooltip title={addSampleInfoTooltipMessage || ''} arrow>
      <span>
        <IconButton
          size='small'
          color='primary'
          disabled={canNotAddSampleInfo}
          className={clsx('bg', classes.iconButton)}
          onClick={()=> onAction(getSamples(uniqueToken), sampleInformationLink)}
        >
          <SvgIcon component={IconEdit} inheritViewBox />
        </IconButton>
      </span>
    </Tooltip>
    <Typography variant='body3' sx={{ml: 0.5}}>
      {`${samplesWithInfoCount}/${samplesCount}`}
    </Typography>
    </Box>
    )
  }

  const renderButtonStatus = (row) => {
    const {reasonCanNotCupNow, canBeStarted, canNotCupNow, uniqueToken} = row
    
    return(
      <Box sx={{ textAlign: "center" }}>
        <Tooltip title={reasonCanNotCupNow || ''} arrow>
          <span>
            <Button
              size='small'
              variant='contained'
              disabled={!canBeStarted && canNotCupNow}
              startIcon={<SvgIcon component={IconStartCupping} fontSize='medium' inheritViewBox />}
              onClick={() => onAction(getSamples(uniqueToken), sampleScoreLink)}
              className={classes.cupNowButton}
            >
              {canBeStarted ? t('cuppingSession.start') : t('cuppingSession.cupNow')}
            </Button>
          </span>
        </Tooltip>
      </Box>
    )
  }

  const renderButtonEdit = (row) => {
    const {tooltipCanNotBeEdited, canNotBeEdited, uniqueToken, id} = row
    
    return(
      <Box sx={{ textAlign: "center" }}>
        <Tooltip title={tooltipCanNotBeEdited} arrow>
            <span>
              <IconButton
                size='small'
                color='primary'
                disabled={canNotBeEdited}
                onClick={()=> navigate(`/cupping-sessions/${uniqueToken}`)}
                className={clsx('bg', classes.iconButton)}
              >
                <SvgIcon component={IconEdit} inheritViewBox />
              </IconButton>
            </span>
          </Tooltip>
      </Box>
    )
  }

  const renderButtonReview = (row) => {
    const { canNotBeReviewed, reasonCanNotBeReviewed, uniqueToken} = row
    
    return(
      <Box sx={{ textAlign: "center" }}>
        <Tooltip title={reasonCanNotBeReviewed || ''} arrow>
            <span>
              <IconButton
                size='small'
                color='primary'
                disabled={canNotBeReviewed}
                onClick={()=>onAction(getSamples(uniqueToken), sampleReviewLink)}
                className={clsx('bg', classes.iconButton)}
              >
                <SvgIcon component={IconReview} inheritViewBox />
              </IconButton>
            </span>
          </Tooltip>
      </Box>
    )
  }

  const renderButtonMenu = (row) => {
   
    const { 
      isPublic, 
      canNotBeEdited, 
      onPlayOrPause,
      isPaused,
      id,
      uniqueToken,
      tooltipCanNotBeEdited, 
      reasonCanNotBeReviewed, 
      canNotResendToWebhook, 
      reasonCanNotBeDeleted, 
      canNotBeDeleted,
      canNotBeReviewed,
      samples
      } = row
    
    return(
      <Box sx={{ textAlign: "center" }}>
        <Stack direction="row" spacing={1} 
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}>
          {isPublic &&
            <IconButton
              size='small'
              color={isPaused ? 'secondary' : 'primary'}
              disabled={canNotBeEdited}
              onClick={onPlayOrPause}
              className={clsx('bg', classes.iconButton, isPaused && 'paused')}
            >
              <SvgIcon
                component={isPaused ? IconPlayCircle : IconPauseCircle}
                inheritViewBox
              />
            </IconButton>
          }

        <RenderMenuItems
            id={id}
            uniqueToken={uniqueToken}
            isPublic={isPublic} 
            canNotBeEdited={canNotBeEdited} 
            onPlayOrPause={onPlayOrPause} 
            isPaused={isPaused}
            tooltipCanNotBeEdited={tooltipCanNotBeEdited}
            reasonCanNotBeReviewed={reasonCanNotBeReviewed}
            canNotResendToWebhook={canNotResendToWebhook}
            reasonCanNotBeDeleted={reasonCanNotBeDeleted}
            canNotBeDeleted={canNotBeDeleted}
            canNotBeReviewed={canNotBeReviewed}
            onReview={()=>onAction(getSamples(uniqueToken), sampleReviewLink)}
            onEdit={()=>navigate(`/cupping-sessions/${uniqueToken}`)}
            onResendWebhook={() => onResendWebhook(uniqueToken)}
            onRemove={() => {
              setOpenConfirm(true)
              setCuppingSessionStoreValue('selectedCuppingSession', id)
            }}
            onPrintQRCode={() => {
              getSamples(uniqueToken)
              setSelectDataCuppingSession(row)
              setModalValue('printQRCode', true)
              setUrlInvitationsQrCode(`${window.location.origin}/invitations/${uniqueToken} `)
            }}
            onQrCodeSetting={() => {
              setSelectDataCuppingSession(row)
              setOpenSetting(true)
            }}
            onPrintAllLabel={()=>handlePrintAllLabel(samples)}
        />
        </Stack>
      </Box>
    )
  }

  const tableSearch = useReactTable({
    data: data,
    columns,
    state: { rowSelection, columnFilters },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
	  onColumnFiltersChange: (updaterOrValue) => {
		  setCuppingSessionStoreValue('page', 1)
		  setColumnFilters(updaterOrValue)
	  },
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id,
  })

  const viewCuppingSessionTable = () => {
    switch (selectedViewMenu) {
      case viewCuppingSession[1].label:
        return(
          <FilterRequestTable
            table={tableSearch}
            page={page}
            totalPage={totalPage}
            onChangePage={(e, p) => setCuppingSessionStoreValue('page', p)}
          />
        )
      default:
        return(
          <>
          <Grid container spacing={1} className={classes.gridWrapper}>
            <Grid item xs={12} className={classes.gridHeader}>
              <Grid
                container
                sx={{ alignItems: 'end' }}
              >
                <Grid item md={3}>
                  <Grid container sx={{ textAlign: 'center' }}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={2}>
                      <Button
                        onClick={() => setSort('id')}
                        className={classes.headerButton}
                      >
                        {t('cuppingSession.id')}
                      </Button>
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        onClick={() => setSort('owner')}
                        className={classes.headerButton}
                      >
                        {t('cuppingSession.owner')}
                      </Button>
                    </Grid>
                    <Grid item xs={5}>
                      <Button
                        onClick={() => setSort('time')}
                        className={classes.headerButton}
                      >
                        {t('cuppingSession.time')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={2}>
                  <Button
                    onClick={() => setSort('name')}
                    className={classes.headerButton}
                  >
                    {t('cuppingSession.name')}
                  </Button>
                </Grid>
                <Grid item md={1}>
                  <Button
                    onClick={() => setSort('location')}
                    className={classes.headerButton}
                  >
                    {t('cuppingSession.location')}
                  </Button>
                </Grid>
                <Grid item md={1} fontWeight={600}>
                  {t('cuppingSession.cuppingForm')}
                </Grid>
                <Grid item md={5} fontWeight={600}>
                  <Grid container sx={{ textAlign: 'center', alignItems: 'end' }}>
                    <Grid item xs={2}>{t('cuppingSession.samplesInformation')}</Grid>
                    <Grid item xs={3}>{t('cuppingSession.status')}</Grid>
                    <Grid item xs={2}>{t('common.edit')}</Grid>
                    <Grid item xs={2}>{t('cuppingSession.review')}</Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item md={1}></Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {cuppingSessions.map(cuppingSession =>
              <Grid item xs={12} key={cuppingSession.id} className={classes.listItem}>
                <SessionItem
                  {...cuppingSession}
                  onStartCupping={() => onAction(startCupping(cuppingSession.uniqueToken))}
                  onCuppingNow={() => onAction(getSamples(cuppingSession.uniqueToken), sampleScoreLink)}
                  onEditSampleInfo={() => onAction(getSamples(cuppingSession.uniqueToken), sampleInformationLink)}
                  onReviewCuppingSession={() => onAction(getSamples(cuppingSession.uniqueToken), sampleReviewLink)}
                  onClickRightReviewCuppingSession={(event) => handleClickRightCuppingSession(event, cuppingSession)}
                  onRemoveCuppingSession={() => {
                    setOpenConfirm(true)
                    setCuppingSessionStoreValue('selectedCuppingSession', cuppingSession.id)
                  }}
                  onResendWebhook={() => onResendWebhook(cuppingSession.uniqueToken)}
                  onPrintQrCode={() => {
                    getSamples(cuppingSession.uniqueToken)
                    setSelectDataCuppingSession(cuppingSession)
                    setModalValue('printQRCode', true)
                    setUrlInvitationsQrCode(`${window.location.origin}/invitations/${cuppingSession.uniqueToken} `)
                  }}
                  onPlayOrPause={() => onAction(playOrPause(cuppingSession.id))}
                  onSettingQrCode={() => {
                    setSelectDataCuppingSession(cuppingSession)
                    setOpenSetting(true)
                  }}
                  onSelectItem={handleCheckboxChange}
                  isSelected={getSelectedItem(cuppingSession.id)}
                  onPrintAllLabel={handlePrintAllLabel}
                />
              </Grid>
            )}
          </Grid>
          {totalPage > 0 &&
            <Pagination
              page={page}
              count={totalPage}
              onChange={(e, p) => setCuppingSessionStoreValue('page', p)}
            />
          }
          </>
        )
    }
  }

  const handleCheckboxChange = (id: string) => {
    setSelectedItems((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(id)) {
        newSelected.delete(id);
      } else {
        newSelected.add(id);
      }
      return newSelected;
    });
  };

  const getSelectedItem = (id: string) => selectedItems.has(id);

  const onExport = (config) => {
    const ids = [...selectedItems];
    globalAction(notificationStore, {
      action: async () => await exportSamplesFromCuppingSessions({
        cupping_session_ids: ids,
        ...convertNestedKeys(config) 
      })
    })
  }

  const handleOpenExportConfiguration = () =>  {
    setOpenExportConfiguration(true);
  }

  const handleCloseExportConfiguration = () =>  {
    setOpenExportConfiguration(false);
  }

  const setSort = (key: string) => {
    let newSort = clone(headerSorts)
    newSort[key] = !newSort[key]

    setHeaderSorts(newSort)
  }

  const onAction = (action: void, link: string = '') => {
    globalAction(notificationStore, {
      complexAction: true,
      action: async () => {
        await action

        if (link) navigate(link)
      }
    })
  }

  const handleClickRightCuppingSession = (event, cuppingSession) => {
    event.preventDefault(); // Prevent the default browser context menu
    onActionClickRight(getSamples(cuppingSession.uniqueToken), sampleReviewLink)
  }

  const handlePrintAllLabel = (samples: Sample[]) => {
    const newSamples = getNewSamples(samples)
    setSamplesToBeShipment(newSamples);
    setMultipleOpenGenerateLabel(true);
  }

  const onActionClickRight = (action: void, link: string = '') => {
    globalAction(notificationStore, {
      complexAction: true,
      action: async () => {
        await action

        if (link)  window.open(link, '_blank'); // Open the URL in a new tab
      }
    })
  }

  const onRemove = () => globalAction(notificationStore, { action: removeCuppingSession })

  const onResendWebhook = (token) => globalAction(notificationStore, { action: () => resendWebhook(token) })

  const onLoadCuppingSessions = () => globalAction(notificationStore, { action: getCuppingSessions })

  React.useEffect(() => { onLoadCuppingSessions() }, [page, filter])
  React.useEffect(() => {
    getViewTable()

    if (isDormantAccount) navigate(forbiddenLink)
    setCuppingSessionStoreValue('selectedCuppingSession', undefined)

    if (isNil(params.id)) return

    onAction(getCuppingSession(params.id), sampleScoreLink)
  }, [])

  React.useEffect(() => {
    if(selectedViewMenu === viewCuppingSession[0].label) return

    onGetCuppingSession(columnFilters)
  }, [JSON.stringify(columnFilters), page, selectedViewMenu])

  return (
    <Box px={isMobile ? 0 : 4} py={isMobile ? 0 : 2.5}>
      <Box sx={isMobile ? {
        margin: '16px 0 24px 0',
        padding: '0 16px'
      } : {}}>
        <HeaderTitleWide
          breadcrumb={t('menu.cuppingSessions')}
          title={t('cuppingSession.letsStartCupping')}
          rightContent={(
            <Stack direction='row' spacing={isMobile ? 1 : 2} className={classes.buttonWrapper}>
              {(hasFilter && viewCuppingSession[1].label === selectedViewMenu) && (
                <Button
                  variant="contained"
                  sx={{ borderRadius: '50px' }}
                  startIcon={<Cancel />}
                  onClick={() => setColumnFilters([])}
                >
                  {t(`common.clearFilter`)}
                </Button>
              )}
              {canCreateCuppingSessions &&
                <Button
                  variant="contained"
                  startIcon={
                    <Avatar className={classes.iconRounded}>
                      <IconStartCupping />
                    </Avatar>
                  }
                  sx={{ borderRadius: 8 }}
                  className={classes.buttonRounded}
                  onClick={() => {
                    navigate(formCuppingSessionLink)
                    setReviewSampleValue('cuppingSamples', [])
                  }}
                >
                  {t('cuppingSession.newCuppingSession')}
                </Button>
              }
              <Button
                variant="contained"
                startIcon={<SvgIcon component={IconExport} />}
                sx={{ borderRadius: 8 }}
                className={classes.buttonRounded}
                onClick={handleOpenExportConfiguration}
                disabled={selectedItems.size === 0}
              >
                {t('cuppingSession.export')}
              </Button>

              <Button
                variant="outlined"
                endIcon={<ArrowDropDown />}
                sx={{ borderRadius: 8 }}
                onClick={(event) => setAnchorFilter(event.currentTarget)}
              >
                {t(find(cuppingSessionFilters, ['key', filter])?.label || '')}
              </Button>
              <Menu
                anchorEl={anchorFilter}
                open={openFilter}
                onClose={() => setAnchorFilter(null)}
                onClick={() => setAnchorFilter(null)}
              >
                {cuppingSessionFilters.map((filter) =>
                  <MenuItem
                    key={filter.key}
                    value={filter.key}
                    onClick={() => setCuppingSessionStoreValue('filter', filter.key)}
                  >
                    {t(filter.label)}
                  </MenuItem>
                )}
              </Menu>

              <Button
                variant={selectedViewMenu === viewCuppingSession[0].label ? "contained" : "outlined"}
                sx={{borderRadius: 1}}
                onClick={(event) =>  {
                  setSelectedViewMenu(viewCuppingSession[0].label)
                  saveString(VIEW_TABLE_CUPPING_SESSION_STORAGE_KEY, JSON.stringify(viewCuppingSession[0]))
                }}
              >
                <ListOutlined /> 
              </Button>

              <Button
                variant={selectedViewMenu === viewCuppingSession[1].label ? "contained" : "outlined"}
                sx={{borderRadius: 1}}
                onClick={(event) => { 
                  setSelectedViewMenu(viewCuppingSession[1].label)
                  saveString(VIEW_TABLE_CUPPING_SESSION_STORAGE_KEY, JSON.stringify(viewCuppingSession[1]))
                }}
              >
                <SvgIcon component={IconTableSearchBar} fontSize="small" sx={{ color: selectedViewMenu === viewCuppingSession[1].label ? 'white' : colors.primary.main }} inheritViewBox />
              </Button>

            </Stack>
          )}
        />
      </Box>

      {cuppingSessions.length > 0 ?
        <Box sx={{mt: 1}}>
         {viewCuppingSessionTable()}
        </Box> :
        <Container sx={{textAlign: 'center'}}>
          <Typography variant="h3" sx={{mb: 4}}>
              {t('cuppingSession.noResultFound')}
          </Typography>
          <img src={emptySearch} alt="Not found" />
        </Container>
      }

      <ModalConfirm
        open={openConfirm}
        description={t('cuppingSession.removeCuppingSessionConfirmDescription')}
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => {
          setOpenConfirm(false)
          onRemove()
        }}
      />
       <PrintQr
        open={printQRCode}
        onClose={() => setModalValue('printQRCode', false)}
        setUrl = {urlInvitationsQrCode}
        selectCuppingSession = {selectDataCuppingSession}
        sampelName ={map(samples, 'name')}
      />

      {selectDataCuppingSession && openSetting &&
        <SettingQrCode
          open={openSetting}
          onClose={() => setOpenSetting(false)}
          cuppingSession={selectDataCuppingSession}
          onSave={updateRequestInformation}
        />
      }

      {!isEmpty(samplesToBeShipment) &&
        <MultipleGenerateLabel
          index={0}
          samplesToBePrintLabel={samplesToBeShipment}
          open={openMultipleGenerateLabel}
          onClose={() => setMultipleOpenGenerateLabel(false)}
          onSubmit={(data) => {
            data.map(d => {
              setSamplesToBeShipmentMultiGenerateLabel(d)
            })
          }}
        />
      }
      <ExportConfigurationModal
        isOpen={isOpenExportConfiguration}
        onExport={onExport}
        onClose={handleCloseExportConfiguration}
      />
    </Box>
  )
})

export default CuppingSessions
