import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import {colors} from "assets";

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		dataGrid: {
			'& .MuiDataGrid-columnHeader': {
				backgroundColor: colors.royalPurple,
				fontWeight: 'bold',
				borderRadius: '0 !important',
				fontSize: '16px'
			},
			'& .MuiDataGrid-cell': {
				borderRight: `1px solid ${colors.royalPurple}`,
				backgroundColor: 'white',
			},
			'& .MuiDataGrid-editInputCell': {
				fontSize: '16px'
			},
			'& .MuiDataGrid-columnHeaderTitle': {
				whiteSpace: 'pre-wrap'
			},
			borderRadius: 0
		},
		buttonWrapper: {
			display: 'flex',
			justifyContent: 'flex-end',
			gap: theme.spacing(2),
			marginTop: theme.spacing(2),
		},
		scrollableGrid: {
			position: 'absolute',
			top: 0,
			right: 0,
			zIndex: 2,
			backgroundColor: 'white',
		},
		stickyGrid: {
			zIndex: 1,
			position: 'relative',
		},
		buttonMobileWrapper: {
			position: 'fixed',
			textAlign: 'center',
			bottom: 0,
			padding: '12px 16px',
			marginBottom: 0,
			backgroundColor: 'white',
			boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.1)',
			zIndex: 1,
			width: '100%',
			'& button': {
			  width: '100%'
			}
		  },
	})
)
