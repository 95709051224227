/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-17 22:58:45
 * @modify date 2022-04-17 22:58:45
 */

import { toNumber } from "lodash"
import {isValidJSON} from "utils"

export const preProcessorGreenGrading = (sn: any) => {
  return {
    ...sn,
    id: toNumber(sn.id) || null,
    weight: toNumber(sn.weight) || 0,
    total: isValidJSON(sn.total) ? sn.total : JSON.stringify(sn.total) || '{}',
    smell: sn.smell || '',
    color: sn.color || '',
    density: toNumber(sn.density) || null,
    picture: sn.picture || '',
    picture2: sn.picture2 || '',
    quakerNumber: sn.quakerNumber || 0,
    purchaseGrade: sn.purchaseGrade || '',
    purchaseContractReference: sn.purchaseContractReference || '',
    waterActivity: sn.waterActivity || '',
    salesContractReference: sn.salesContractReference || ''

  }
}
