/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-04-17 22:29:31
 * @modify date 2022-04-17 22:29:31
 */

import { types } from "mobx-state-tree"

import { GreenGradingModel, preProcessorGreenGrading } from "models/green-grading"
import { SampleScoreModel, preProcessorSampleScore } from 'models/sample-score'
import { sampleImageProps } from './sample-image-props'
import { defaultSampleProps} from "./default-sample.props"
import { CuppingResultAggregateModel } from "./cupping-result-agregate"

export const sampleProps = {
  ...defaultSampleProps,

  sampleId: types.string,
  farmerNotes: types.string,
  storySubject: types.string,
  storyDescription: types.string,
  isScoreTarget: types.maybeNull(types.boolean),
  cachedAverageScore: types.union(types.string, types.number),
  status: types.maybeNull(types.string),
  uniqueToken: types.optional(types.string, ''),
  images: types.optional(types.array(types.model(sampleImageProps)), []),

  selectedGreenGrading: types.maybeNull(GreenGradingModel),
  greenGradings: types.optional(types.array(
    types.snapshotProcessor(GreenGradingModel, {
      preProcessor: (sn: any) => preProcessorGreenGrading(sn)
  })), []),

  selectedScore: types.optional(SampleScoreModel, {}),
  scores: types.optional(types.array(
    types.snapshotProcessor(SampleScoreModel, {
      preProcessor: (sn: any) => preProcessorSampleScore(sn)
  })), []),
  sampleTypeNotRequired: types.optional(types.boolean, false),
  tab: types.optional(types.string, 'descriptive'),

  masterId: types.maybeNull(types.number),
  masterSampleUniqueToken: types.maybeNull(types.string),
  masterSampleName: types.maybeNull(types.string),
  sampleUniqueNumber: types.maybeNull(types.string),

  comboCuppingFlag: types.maybeNull(types.optional(types.boolean, false)),
  numberOfCupsFlag: types.maybeNull(types.optional(types.boolean, false)),
  cupsPerSample: types.maybeNull(types.optional(types.number, 0)),

  cuppingResultAggregate: types.optional(CuppingResultAggregateModel, {}),
  orderPosition: types.maybeNull(types.number),
  latestCuppingSessionSampleCachedAveragedScore: types.optional(types.string, '-'),
}

