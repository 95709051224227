/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-03-06 18:36:40
 * @modify date 2022-03-06 18:36:40
 */

import React, {useState} from 'react'
import clsx from 'clsx'
import moment from "moment"
import { getName } from 'country-list'
import { observer } from 'mobx-react-lite'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {isMobile, isDesktop} from "react-device-detect"
import { styled, width } from '@mui/system'
import {
	toString,
	isArray,
	truncate,
	startCase,
	isEmpty,
	cloneDeep,
	orderBy,
	some,
	every,
	has,
	chain,
	clone,
	includes,
	pull,
	toNumber,
	remove,
} from 'lodash'
import {
  Button,
  Box,
  Chip,
  Grid,
  Typography,
  Divider,
  TextField,
  SvgIcon,
  Stack,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  Menu,
  MenuItem,
  FormControlLabel,
  Switch,
  FormGroup,
  Checkbox,
  Autocomplete,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Accordion,
  AccordionSummary,
  AccordionDetails, ListItemText, Select,
} from '@mui/material'
import {
  VisibilityOutlined,
  VisibilityOffOutlined,
  KeyboardArrowDown,
  HelpOutline,
  Sync, Output, SummarizeOutlined, ExpandMore,
} from '@mui/icons-material'
import ReactHtmlParser from 'react-html-parser'
import CurrencyTextField from '@kylebeikirch/material-ui-currency-textfield'

import { useStores } from 'models'
import {
	flavorDescriptorTranslation,
	countryName,
	globalAction,
	optionsTranslation,
	pointerMessages,
	validateEmail
} from 'utils'
import { IconWarning } from 'assets/images'
import { findRoute } from 'constants/routes'
import {
  HeaderTitleWide,
  AvatarProfile,
  ModalWrapper,
  FormInput,
  ModalConfirm,
  ModalMessage, MenuIcon,
  LightTooltip,
} from 'components'
import { sampleStatuses } from 'constants/form'
import {basketCodes} from "constants/basket-codes"
import { colors as colorAsset } from 'assets'
import { ANONYMOUS_NAME, CUPPING_SESSION_OWNER_TITLE } from "config/env"

import {
  SampleInformation,
  ScaInformation,
  SucafinaTastingInformation,
  CommercialInformation
} from './components'
import { useStyles } from './cupping-session-review.styles'
import {CuppingProtocol} from "../../constants/constant";
import { ReportConfigurationModal } from 'components/report-configuration-modal/report-configuration-modal'
import { SimplifiedInformation } from './components/simplified-information/simplified-information'

export const CuppingSessionReview: React.FC = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const sampleToken = searchParams.get('sample-id') || ''

  const {
    userStore: { isAuthenticated, languagePreference, userId, isAdmin },
    companyStore: { scaFeature, isSucafinaFeature, isBayers },
    cuppingSessionStore: { selectedCuppingSession, exportReviews, getCuppingSession },
    sampleStore: { tmpId, orderedSamples, samples, saveSample, updateCalibrationStatus, discardSample, saveAggregate, setValue: setSampleValue, selectedSample,
      selectedSample: {
        sampleId, id , sampleType, countryCode, referenceNumber, status, cachedAverageScore, uniqueToken, farmerNotes, storySubject,
        storyDescription, selectedGreenGrading, scores, setScore, updateScore,
        addGreenGrading, saveStatus, isScoreTarget, purchaseContractReference, salesContractReference,
        cupsPerSample, sampleUniqueNumber, varietalsTags, isDecaf, basketCode, otaTestValue, contractWeight,
	      sampleReference, shipmentDate,
	      setValue: setSelectedSampleValue, selectPersonalScore,
        selectedScore: { id: scoreId, sampleInformation, saveScore, saveIsSelected, setScoreValue, setWithoutScoreValue },
        cuppingResultAggregate: { totalScore, descriptors, internalNotes, sampleResult, syncBy, syncAt, setValue: setAggregateValue }
      }
    },
    guideStore: { guideSample },
    sampleReportStore: {getSampleReport},
    reviewSampleStore: { shareSamples, share: { to, message, setValue: setShareValue }},
    notificationStore
  } = useStores()

  const [currentSample, setCurrentSample] = React.useState()
  const [tab, setTab] = React.useState('')
  const [isEdit, setIsEdit] = React.useState(false)
  const [isEditScore, setIsEditScore] = React.useState(false)
  const [isEditSample, setIsEditSample] = React.useState(false)
  const [isEditGreenGrading, setIsEditGreenGrading] = React.useState(false)
  const [isEditDescriptor, setIsEditDescriptor] = React.useState(false)
  const [isGuideSample] = React.useState(guideSample)
  const reviewSamplesLink = findRoute('reviewSamples')
  const homeLink = findRoute('home')
  const sampleInformationLink = findRoute('sampleInformation')
  const sampleReviewLink = findRoute('sampleReview')
  const [isShowCuppingTargetScore, setIsShowCuppingTargetScore] = useState(false)
  const [showButtonCuppingTargetScore, setShowButtonCuppingTargetScore] = useState(false)
  const [groupScore] = useState<any[]>([])
  const [scoreIdOwner, setScoreIdOwner] = useState('')
  const [cachedAverageScoreOwnerData, setCachedAverageScoreOwnerData] = useState('')
  const [anchorExport, setAnchorExport] = useState<null | HTMLElement>(null)
  const openExport= Boolean(anchorExport)
  const [openShare, setOpenShare] = useState(false)
  const [shareErrors, setShareErrors] = useState<any>({})
  const [ids, setIds] = useState<String[]>([])
  const [checked, setChecked] = useState({})
  const [checkedAll, setCheckedAll] = useState(false)
  const [editTotalScore, setEditTotalScore] = useState(false)
  const [openSyncSummary, setOpenSyncSummary] = useState(false)
  const [openSyncModal, setOpenSyncModal] = useState(false)
  const [openSuccess, setOpenSuccess] = useState(false)
  const [openSuccessSave, setOpenSuccessSave] = useState(false);
  const [isSyncSelectedSample, setIsSyncSelectedSample] = useState(false);
  const [selectedSampleTokenBeforeActionToken, setSelectedSampleTokenBeforeAction] = useState(selectedSample?.uniqueToken || '');
  const [isOpenReportConfiguration, setOpenReportConfiguration] = useState<boolean>(false);
  const [navigateUrl, setNavigateUrl] = useState<string>('');
  const [tempScoreId, setTempScoreId] = useState()
  const [expanded, setExpanded] = useState<string | false>(false)
  const [isOpenSnapshotReportConfig, setOpenSnapshotReportConfig] = useState<boolean>(false);

  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorMenu)

  const isPublicSession = selectedCuppingSession?.isPublic
  const isSca = selectedSample?.cuppingProtocol?.includes('sca')
  const isSucafinaTasting = selectedSample?.cuppingProtocol === 'sucafina_tasting'
  const isCommercial = selectedSample?.cuppingProtocol === 'commercial'
	const isSimplifiedCommon = selectedSample?.cuppingProtocol === CuppingProtocol.SIMPLIFIED_COMMON
  const isCuppingSessionSimplifiedCommon = selectedCuppingSession?.cuppingProtocol === CuppingProtocol.SIMPLIFIED_COMMON
	const hasTeamScore = isSca ?
    selectedSample?.scores?.some((score: any) => score.cupperId === toNumber(userId)) :
    true
  const fiveCups = cupsPerSample === 5

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip placement="top-start" {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#B12028',
      color: 'rgba(0, 0, 0, 0.87)',
      border: '1px solid #dadde9'
    },
  }));

  const tableItems = !isSimplifiedCommon ? [
    {label: 'sample.referenceNumber', value: referenceNumber ? referenceNumber : '-'},
    {label: 'sample.samples.cuppingSessionId', value: id ?? '-'},
    {label: 'sample.country', value: countryCode ? getName(countryCode) : '-'},
    {label: 'sample.type', value: sampleType ? startCase(sampleType) : '-'},
    ...(isBayers ? [
      {label: 'sample.varietals', value: varietalsTags},
      {label: 'sample.decaf', value: optionsTranslation('boolean', isDecaf?.toString())},
      {label: 'sample.otaTestValue', value: otaTestValue ? `${otaTestValue} ppb` : '-'},
      {label: 'sample.basketCode', value: basketCode},
      {label: 'sample.contractWeight', value: contractWeight},
    ] : []),
    {label: 'sample.averageScore', value: cachedAverageScore},
    // {label: 'report.standardDeviation', value: '0.6'},
    {label: 'sample.samples.salesContract', value: salesContractReference ? salesContractReference : '-'},
    {label: 'sample.samples.purchaseContract', value: purchaseContractReference ? purchaseContractReference : '-'},
    {label: 'sample.status.title', value: optionsTranslation('status', status, true)},
  ] : [
	  {label: 'sample.sampleReference', value: sampleReference ?? '-'},
	  {label: 'sample.samples.purchaseContract', value: purchaseContractReference ? purchaseContractReference : '-'},
	  {label: 'sample.samples.salesContract', value: salesContractReference ? salesContractReference : '-'},
	  {label: 'sample.shipmentDate', value: shipmentDate ?? '-'},
	  {label: 'sample.sampleType', value: optionsTranslation('sampleType', sampleType, true)},
  ]

  const tableItemCuppingTargetScore = !isSimplifiedCommon ?
	  tableItems.splice(5, 0, {label: 'sample.targetScore', value: cachedAverageScoreOwnerData}) :
	  tableItems

  const groupScoreFormat = ["roastLevel","fragrance","acidity","body","salt","bittersweet","flavor","aftertaste","mouthfeel","balance","cleanCupScore","sweetnessScore","overall","uniformity","cleanCup","sweetness","defects","notes"]

  const setValue = (setAction: any, attribute: string, value: any) => {
    if (!fiveCups && (attribute === 'uniformity' || attribute === 'cleanCup' || attribute === 'sweetness')) {
      attribute = `${attribute}Score`
    }

    if (setAction === setScoreValue) {
      setIsEditScore(true)

      if (isArray(value)) {
        value.forEach((v, i) => setScoreValue(`${attribute}${i + 1}`, v))
        setScoreValue(`${attribute}Score`, value.filter(Boolean).length * 2)
        return
      }
    }

    if (setAction === setSelectedSampleValue) setIsEditSample(true)
    if (setAction === selectedGreenGrading?.setValue || setAction === selectedGreenGrading?.setDefectScore) setIsEditGreenGrading(true)

    setAction(attribute, value)
  }

  const onSave = async () => {
    const { setNotification, setValue: setNotificationValue } = notificationStore

    setIsEdit(false)

    try{
      setNotificationValue('loading', true)
      let actions: Array<any> = []
      if (isEditSample) actions.push(saveSample(id))
      if (isEditGreenGrading) actions.push(addGreenGrading(selectedGreenGrading.defects))
      if (isEditScore || isEditDescriptor) actions.push(saveScore(id))

      const [resSample, resGreenGrading, resScore] = await Promise.all(actions)
      if (isEditScore) await getCuppingSession(selectedCuppingSession.uniqueToken)
      resSample?.base?.map(message => setNotification(message))
      setIsEditSample(false)

      resGreenGrading?.base?.map(message => setNotification(message))
      setIsEditGreenGrading(false)

      resScore?.base?.map(message => setNotification(message))
      setIsEditScore(false)
      setIsEditDescriptor(false)

      setCurrentSample(cloneDeep(selectedSample))
    } finally {
      setNotificationValue('loading', false)
    }
  }

  const onGetCuppingSession = async () => {
    try{
      notificationStore.setLoading(true)
      setTempScoreId(scoreId)
      await getCuppingSession(selectedCuppingSession.uniqueToken)
    } finally {
      notificationStore.setLoading(false)
    }
  }

  const changeStatus = (statusSample) => {
    const isRemoved = (status === statusSample)
    setSelectedSampleValue('status', statusSample)

    globalAction(notificationStore, {
      action: saveStatus
    })

    if (isRemoved) setSelectedSampleValue('status', '')
  }

  const changeCalibrationStatus = (status: boolean) => {
    samples.forEach(s => s.setValue('isScoreTarget', status))
    updateCalibrationStatus(true)
  }

  const onExport = (path) => globalAction(notificationStore, { action: async() => await exportReviews(path) })

  const getTmpScore = async () => {
    await onGetCuppingSession().then(() => {
      setSelectedSampleValue("id", isEmpty(tmpId) ? id : toNumber(tmpId))
      setSampleValue("tmpId", "")
      setTab(toString(id))
      setSampleValue('selectedSample', toString(id))
      setCurrentSample(cloneDeep(selectedSample))
    })
  }

  const setDefault = () => {
    setScore(scores.findIndex( x => x.cupperTitle === CUPPING_SESSION_OWNER_TITLE))

    samples.forEach(sample => {
      handleCheckbox(false, sample.id)
    })
  }

  const setIdOwnerData = () => {
    scores.forEach(sc => {
      if (sc.cupperTitle === CUPPING_SESSION_OWNER_TITLE) setScoreIdOwner(sc.id)
    })
  }

  const setCachedAverageScoreOwner = () => {
    scores.forEach(sc => {
      if (sc.cupperTitle === CUPPING_SESSION_OWNER_TITLE) setCachedAverageScoreOwnerData(sc.totalScore)
    })
  }

  const setGroupScoreData = (item) => {
    if (isShowCuppingTargetScore) {
      groupScoreFormat.map(format =>
        orderBy(item, "isScoreTarget", "asc").map((samples, index) =>
          samples.sampleInformation.map(sample => {
            if (sample.key === format) {
              if(sample.key === "roastLevel" && samples.isScoreTarget === 1) return
              if(sample.key === "notes") return

              //add data to variable groupScore
              groupScore.push({ id: samples.id, key: sample.key, label: sample.label, score: sample.score, descriptors: sample.descriptors, isScoreTarget: samples.isScoreTarget, value: [] })

              if (format === "uniformity") {
                sample.value.map(data =>
                  groupScore[groupScore.findIndex((obj => obj.key === 'uniformity')) + index]['value'].push(data)
                )
              }

              if (format === "cleanCup") {
                sample.value.map(data =>
                  groupScore[groupScore.findIndex((obj => obj.key === 'cleanCup')) + index]['value'].push(data)
                )
              }

              if (format === "sweetness") {
                sample.value.map(data =>
                  groupScore[groupScore.findIndex((obj => obj.key === 'sweetness')) + index]['value'].push(data)
                )
              }

            }
          })
        )
      )
    }
    else {
      groupScore.length = 0
    }

  }

  const onSyncSample = () => {
    setSelectedSampleTokenBeforeAction(selectedSample?.uniqueToken || '');
    setOpenSyncModal(false);

    const samplesToBeSynced = isSyncSelectedSample ? [selectedSample] : samples;
    const payloads = samplesToBeSynced.map(sample => {
      if (!sample) return;

      const { totalScore, descriptors, internalNotes, sampleScoresIds, sampleResult } = sample.cuppingResultAggregate

      return {
        masterSampleToken: sample.uniqueToken,
        totalScore,
        descriptors,
        internalNotes,
        sampleScoresIds,
        sampleResult,
      }
    })

    globalAction(notificationStore, {
      action: async() => await saveAggregate(payloads, true),
      afterAction: () =>  {
        setOpenSuccess(true);
        setIsSyncSelectedSample(false);
      }
    })
  }

  const onSaveSample = () => {
    setSelectedSampleTokenBeforeAction(selectedSample?.uniqueToken || '');
    const payloads = [selectedSample].map(sample => {
      if (!sample) return;

      const { totalScore, descriptors, internalNotes, sampleScoresIds, sampleResult } = sample.cuppingResultAggregate;

      return {
        masterSampleToken: sample.uniqueToken,
        totalScore,
        descriptors,
        internalNotes,
        sampleScoresIds,
        sampleResult,
      }
    })

    globalAction(notificationStore, {
      action: async() => await saveAggregate(payloads, false),
      afterAction: () =>  {
        setOpenSuccessSave(true);
      }
    })
  }

  const renderTitle = (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography variant="h4">
        {sampleToken ? t('report.cuppingSampleReview') : `${t('report.cuppingSession')} #${selectedCuppingSession?.id}`}
      </Typography>
      {selectedCuppingSession?.comboCuppingFlag && (
        <Chip
          label={t('report.comboCupping')}
          sx={{
            color: colorAsset.primary.main,
            backgroundColor: colorAsset.primary.o25,
          }}
        />
      )}
    </Stack>
  )

  const renderAggregateForm = (
    <Stack spacing={2} className={classes.aggregateForm}>
      <Typography fontSize="20px">{t('sampleAggregate.title')}</Typography>
      <Stack direction={isDesktop ? 'row' : 'column'} spacing={2}>
        <Stack direction="column" spacing={2} flex={1}>
          <Stack direction={isDesktop ? 'row' : 'column'} spacing={2}>
            <CurrencyTextField
              key={`totalScore-${id}${editTotalScore ? '' : totalScore}`}
              autoFocus={editTotalScore}
              fullWidth
              type='text'
              inputMode='numeric'
              variant='outlined'
              textAlign='left'
              currencySymbol=''
              outputFormat="number"
              decimalCharacter="."
              digitGroupSeparator=","
              minimumValue="0"
              decimalPlaces={2}
              placeholder={t('sampleAggregate.score')}
              style={{ width: '80px' }}
              onFocus={() => setEditTotalScore(true)}
              value={totalScore}
              onChange={(event, value) => {
                setAggregateValue('totalScore', value)
              }}
              InputProps={{ sx: { height: '40px', padding: '0px', fontSize: '1.25rem', '& .MuiOutlinedInput-notchedOutline': {
                  border: `1px solid ${colorAsset.primary.main}`,
                }  } }}
            />
            <Autocomplete
              disableClearable
              multiple
              freeSolo
              options={[]}
              sx={{ 
                flex: 1,
                '& .MuiInputBase-sizeSmall': {
                  // height: '40px',
                  padding: '4px 8px 4px 8px !important',
                  fontSize: '1.25rem',
                  fontWeight: 500,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: `1px solid ${colorAsset.primary.main}`,
                }
              }}
              value={descriptors.length > 0 ? descriptors : []}
              onChange={(_, value) => {
                setAggregateValue('descriptors', value ?? [])
              }}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => {
                  return (
                    <>{index > 0 ? ', ' : ''}{option}</>
                  );
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t('sampleAggregate.descriptorPlaceholder')}
                  variant="outlined"
                  size="small"
                />
              )}
              onKeyDown={(event) => {
                if (event.key === ',') {
                  event.key = 'Enter'
                }
              }}
            />
          </Stack>
          <TextField
          sx={{ 
            flex: 1,
            '& .MuiInputBase-sizeSmall': {
              height: '40px',
              fontSize: '1.25rem',
              fontWeight: 500,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${colorAsset.primary.main}`,
            }
          }}
            key={`internalNotes-${id}`}
            fullWidth
            placeholder={t('sampleAggregate.internalNotes')}
            variant="outlined"
            size="small"
            value={internalNotes}
            onChange={(event) => setAggregateValue('internalNotes', event.target.value)}
          />
          {
            isCuppingSessionSimplifiedCommon ? <TextField
              sx={{
                flex: 1,
                '& .MuiInputBase-sizeSmall': {
                  height: '40px',
                  fontSize: '1.25rem',
                  fontWeight: 500,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: `1px solid ${colorAsset.primary.main}`,
                }
              }}
              key={`sampleResult-${id}`}
              fullWidth
              placeholder={t('sample.sampleResult')}
              variant="outlined"
              size="small"
              value={sampleResult}
              onChange={(event) => setAggregateValue('sampleResult', event.target.value)}
            /> : null
          }
        </Stack>

        <Stack direction={isDesktop ? 'column' : 'row'} spacing={2}>
          <Button
            variant="contained"
            endIcon={<HelpOutline />}
            onClick={() =>  {
              setIsSyncSelectedSample(true);
              setOpenSyncModal(true);
            }}
            sx={{ height: '40px' }}
          >
            {t('sampleAggregate.sync')}
          </Button>
          <Button
            variant="contained"
            onClick={() => onSaveSample()}
            sx={{ height: '40px' }}
          >
            {t('sampleAggregate.save')}
          </Button>
        </Stack>
      </Stack>
      {syncBy && syncAt && (
        <Typography variant="body2" my={1}>
          {ReactHtmlParser(t('sampleAggregate.syncInformation', {
            name: syncBy, date: moment.utc(syncAt).local().format('L - LT')
          }))}
        </Typography>
      )}
    </Stack>
  )

  const renderSyncSummary = (
    <ModalWrapper open={openSyncSummary}>
      <Typography variant="h6">{t('sampleAggregate.syncSummary')}</Typography>
      <Typography>{t('sampleAggregate.syncSummaryDescription')}</Typography>

      <Table className={classes.tableSyncSummary}>
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>{t('sample.sampleName')}</TableCell>
            <TableCell>{t('sample.samples.purchaseContract')}</TableCell>
            <TableCell>{t('sample.samples.salesContract')}</TableCell>
            <TableCell>{t('sample.samples.averageScore')}</TableCell>
            <TableCell>{t('sampleAggregate.descriptor')}</TableCell>
            <TableCell>{t('sampleAggregate.internalNotes')}</TableCell>
          </TableRow>

        </TableHead>
        <TableBody>
          {orderedSamples.map(sample => {
            return (
              <TableRow key={sample.id}>
                <TableCell>{sample.sampleId}</TableCell>
                <TableCell>{sample.name}</TableCell>
                <TableCell>{sample.purchaseContractReference}</TableCell>
                <TableCell>{sample.salesContractReference}</TableCell>
                <TableCell>{sample.cuppingResultAggregate.totalScore}</TableCell>
                <TableCell>{sample.cuppingResultAggregate.descriptors?.join(', ')}</TableCell>
                <TableCell>{sample.cuppingResultAggregate.internalNotes}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>

      <Stack direction="row" spacing={2} mt={2} justifyContent="end">
        <Button
          variant="outlined"
          onClick={() => setOpenSyncSummary(false)}
        >
          {t('common.cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setOpenSyncSummary(false)
            setOpenSyncModal(true)
          }}
        >
          {t('sampleAggregate.sync')}
        </Button>
      </Stack>
    </ModalWrapper>
  )

  const renderButtonsCalibration = () => {
    return(
      <Stack spacing={1}>
        {showButtonCuppingTargetScore &&
          <Button
            variant="contained"
            size='small'
            color='warning'
            className={classes.buttonRoundedCalibration}
            onClick={() => { !isShowCuppingTargetScore ? setIsShowCuppingTargetScore(true) : setIsShowCuppingTargetScore(false) }}
          >
            {!isShowCuppingTargetScore ?
              <>
                <VisibilityOutlined
                  sx={{
                    color: 'warning',
                    width: 16,
                    height: 18,
                    mr: 1
                  }}
                />
                <Typography variant='body3'>{t('sample.show')} {t('sample.cuppingScoreTarget')}</Typography>
              </>
              :
              <>
                <VisibilityOffOutlined
                  sx={{
                    color: 'warning',
                    width: 16,
                    height: 18,
                    mr: 1
                  }}
                />
                <Typography variant='body3'>{t('sample.hide')} {t('sample.cuppingScoreTarget')}</Typography>
              </>
            }
          </Button>
        }
        {(scores.length > 1 && some(scores, ['cupperTitle', CUPPING_SESSION_OWNER_TITLE]) && isScoreTarget) &&
          <Button
            variant="contained"
            size='small'
            className={classes.buttonRoundedCalibration}
            onClick={() => {
              getSampleReport(uniqueToken)
              navigate(`/sample-report-calibration/${uniqueToken}`)
            }}
            disabled={isEdit || scores.length === 1}
          >
            <Typography variant='body3'>{t('sample.viewCalibration')}</Typography>
          </Button>
        }

        {(scores.length > 1 && !some(scores, ['cupperTitle', CUPPING_SESSION_OWNER_TITLE]) && !isScoreTarget) &&
          <HtmlTooltip
            title={
              <React.Fragment>
                <Grid container width={300}>
                  <Grid item xs={1} sx={{p: 1}}>
                    <SvgIcon component={IconWarning} inheritViewBox sx={{ height: 15, width: 15, mt: 0.2}}/>
                  </Grid>
                  <Grid item xs={11} sx={{p: 1}}>
                    <Typography variant='body2' sx={{color: 'white', fontWeight: 600}}>{ReactHtmlParser(t('sample.viewCalibrationWarningTitle'))}</Typography>
                    <Typography variant='body3' sx={{color: 'white', fontWeight: 400}}>{ReactHtmlParser(t('sample.viewCalibrationWarning'))}</Typography>
                  </Grid>
                </Grid>
              </React.Fragment>
            }
          >
            <span>
              <Button
                variant="contained"
                disabled={true}
                size='small'
                className={classes.buttonRoundedCalibration}
              >
                <Typography variant='body3'>{t('sample.viewCalibration')}</Typography>
              </Button>
            </span>
          </HtmlTooltip>
        }
      </Stack>
    )
  }

  const renderCalibration = () => {
    if (isSca || isSucafinaTasting || isCommercial) return

    return (
      <Stack direction="row" justifyContent="space-between">
        <Box sx={{alignSelf: {xs: 'baseline', md: 'auto'}}}>
          {scores.length > 1 &&
            <>
              <Typography variant='h6'>{t('report.sampleCalibration')}</Typography>
              <FormGroup>
                <FormControlLabel
                  label={t('report.enableCalibration')}
                  control={
                    <Switch
                      disabled={!isEdit}
                      checked={isScoreTarget}
                      onChange={(e) => {
                        setSelectedSampleValue('isScoreTarget', e.target.checked)
                        updateCalibrationStatus()
                      }}
                    />
                  }
                />
              </FormGroup>
            </>
          }
        </Box>

        {renderButtonsCalibration()}
      </Stack>
    )
  }

  const renderCuppers = (
    <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
      {scores.slice().sort((a) => a.cupperTitle === CUPPING_SESSION_OWNER_TITLE ? -1 : 1).map((sc, index) => {
        if (isPublicSession && sc.cupperName === ANONYMOUS_NAME) return

        return (
          <Box
            key={sc.id}
            className={clsx(classes.profile, sc.id === scoreId && 'active')}
            onClick={() => {
              setScore(scores.findIndex( x => x.id === sc.id))
              setIsShowCuppingTargetScore(false)
              groupScore.length = 0
              if(sc.cupperTitle === CUPPING_SESSION_OWNER_TITLE){
                setShowButtonCuppingTargetScore(false)
                return
              }
              setShowButtonCuppingTargetScore(selectedSample.isScoreTarget && selectedSample.selectedScore.cupperTitle !== CUPPING_SESSION_OWNER_TITLE)
            }}
          >
            {isAdmin &&
              <Switch
                  checked={sc.isSelected}
                  onChange={(e) => handleIsSelected(e.target.checked, id, sc.id)}
                  onClick={(e) => e.stopPropagation()}
              />
            }

            <AvatarProfile
              isReport
              name={sc.cupperName}
              title={t(`sampleScore.${sc.cupperTitle}`)}
              active={true}
            />
            <Chip
              label={sc.totalScore}
              color={sc.id === scoreId ? "primary" : "default"}
              className={classes.profileScore}
            />
          </Box>
        )
      })}
    </Stack>
  )

  const renderDescription = (
    <Grid container spacing={2}>
      <Grid item xs={12} className={classes.carouselWrapper}>
        <Stack spacing={2} direction="row" width="100%">
          {isAuthenticated &&
            <Button
              fullWidth
              variant="contained"
              disabled={isShowCuppingTargetScore || isEdit }
              onClick={() => {
                setIsEdit(true)
                setShowButtonCuppingTargetScore(false)
              }}
              sx={{mb: 1}}
            >
              {t('report.editSummary')}
            </Button>
          }
          {(hasTeamScore || !isAdmin || isAuthenticated) &&
            <Button
              fullWidth
              variant="contained"
              disabled={isShowCuppingTargetScore || isEdit }
              onClick={() => {
                setSampleValue("tmpId", toString(id))
                const query = selectedGreenGrading ? `?green_grading=${selectedGreenGrading.id}` : ''
                setNavigateUrl(`/sample-report/${uniqueToken}${query}`);
                handleOpenReportConfigurationModal();
              }}
              sx={{ mb: 1 }}
            >
              {t('report.seeReport')}
            </Button>
          }
          {isAuthenticated && hasTeamScore &&
            <Button
              fullWidth
              variant="contained"
              disabled={isShowCuppingTargetScore || isEdit}
              onClick={() => setOpenShare(true)}
              sx={{ mb: 1 }}
            >
              {t('sample.shareSample')}
            </Button>
          }
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box>
          <Typography variant="body1">
            <b>{selectedCuppingSession?.name}</b> ({sampleUniqueNumber})
          </Typography>
          <Typography variant="body3">
            {t('cuppingSession.cuppingProtocol')}&nbsp;
            <b style={{color: colorAsset.primary.main}}>{optionsTranslation('cuppingProtocol', selectedSample?.cuppingProtocol)}</b>
          </Typography>
        </Box>
        <Box sx={{maxWidth: 'fit-content'}}>
          <table className={classes.table}>
            <tbody>
              {!isShowCuppingTargetScore ?
                <>
                  {tableItems.map((item, index) => {
                    if(selectedSample?.cuppingProtocol ==="sca_descriptive" && item.label === "sample.averageScore") return

                    if (isEdit && (selectedCuppingSession?.isOwner || isAdmin)) {
                      if (item.label === 'sample.basketCode') {
                        return (
                          <tr key={index}>
                            <td>{t(item.label)}</td>
                            <td>
                              <Select
                                multiple
                                size='small'
                                value={basketCode?.split(',') ?? []}
                                onChange={(e) => {
                                  const { value } = e.target
                                  remove(value, v => v === '')

                                  setValue(setSelectedSampleValue, 'basketCode',  typeof value === 'string' ? value : value.join(','))
                                }}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={{ PaperProps: { style: { maxHeight: 300 }}}}
                                sx={{width: 200}}
                              >
                                {basketCodes.map((bc) => (
                                  <MenuItem key={bc.code} value={bc.value} sx={{py: 0}}>
                                    <Checkbox checked={basketCode?.includes(bc.code)} />
                                    <ListItemText primary={bc.value} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </td>
                          </tr>
                        )
                      }

                      if (item.label === 'sample.status.title') {
                        return (
                          <tr key={index}>
                            <td>{t(item.label)}</td>
                            <td>
                              <Stack direction='row' spacing={2}>
                                {sampleStatuses.map(sampleStatus =>
                                  <Button
                                    key={sampleStatus.key}
                                    variant={sampleStatus.key === status ? 'contained' : 'outlined'}
                                    onClick={() => changeStatus(sampleStatus.key)}
                                    color={sampleStatus.key === 'rejected' ? 'error' : 'primary'}
                                  >
                                    {t(sampleStatus.label)}
                                  </Button>
                                )}
                              </Stack>
                            </td>
                          </tr>
                        )
                      }
                    }

                    return (
                      <tr key={index}>
                        <td>{t(item.label)}</td>
                        <td style={{wordBreak: 'break-word'}}>{item.value}</td>
                      </tr>
                    )
                  })}
                </> :
                <>
                  {tableItemCuppingTargetScore.map((item, index) => {
                    if(selectedSample?.cuppingProtocol ==="sca_descriptive" && item.label === "sample.averageScore") return

                    if (index === (tableItemCuppingTargetScore.length - 1) && isEdit && (selectedCuppingSession?.isOwner || isAdmin)) {
                      return (
                        <tr key={index}>
                          <td>{t(item.label)}</td>
                          <td>
                            <Stack direction='row' spacing={2}>
                              {sampleStatuses.map(sampleStatus =>
                                <Button
                                  key={sampleStatus.key}
                                  variant={sampleStatus.key === status ? 'contained' : 'outlined'}
                                  onClick={() => changeStatus(sampleStatus.key)}
                                  color={sampleStatus.key === 'rejected' ? 'error' : 'primary'}
                                >
                                  {t(sampleStatus.label)}
                                </Button>
                              )}
                            </Stack>
                          </td>
                        </tr>
                      )
                    }

                  return (
                    <>
                      {item.label ==='sample.targetScore' ?
                        <tr key={index}>
                          <td><Typography variant='subtitle2' sx={{ mr: 2, color: colorAsset.secondary.main }}>{t(item.label)}</Typography></td>
                          <td><Typography variant='subtitle2' sx={{ mr: 2, color: colorAsset.secondary.main }}>{item.value}</Typography></td>
                        </tr>
                        :
                        <tr key={index}>
                          <td>{t(item.label)}</td>
                          <td>{item.value}</td>
                        </tr>
                      }
                    </>
                  )
                })}
              </>
            }

            </tbody>
          </table>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        {renderCalibration()}
        <Typography variant='body3'>{t('report.farmerNotes')}</Typography>
        <Divider sx={{mt: 0.5, mb: 1}} />
        {isEdit ?
          <TextField
            fullWidth
            multiline
            minRows={3}
            value={farmerNotes}
            onChange={(e) => setValue(setSelectedSampleValue, 'farmerNotes', e.target.value)}
          /> :
          <Typography variant='body3' color='text.disabled' sx={{maxWidth: 265, wordBreak: 'break-word'}}>
            {farmerNotes || t('report.addNotesHere')}
          </Typography>
        }
      </Grid>
    </Grid>
  )

  const renderSampleInformation = () => {
    if (isSca && selectedSample && selectedSample.selectedScore) {
      return <ScaInformation
                key={`${selectedSample.key}${tab}`}
                sampleId={sampleId}
                cupsPerSample={cupsPerSample}
                score={selectedSample.selectedScore}
                isEdit={isEdit}
                setIsEditScore={() => setIsEditScore(true)}
                setIsEditDescriptor={() => setIsEditDescriptor(true)}
                language={languagePreference}
                cuppingProtocol={selectedSample?.cuppingProtocol}
                fiveCups={fiveCups}
              />
    }

    if (isSucafinaTasting && selectedSample && selectedSample.selectedScore) {
      return <SucafinaTastingInformation
                key={`${selectedSample.key}${tab}`}
                sampleId={sampleId}
                cupsPerSample={cupsPerSample}
                score={selectedSample.selectedScore}
                isEdit={isEdit}
                setIsEditScore={() => setIsEditScore(true)}
                setIsEditDescriptor={() => setIsEditDescriptor(true)}
                language={languagePreference}
              />
    }

    if (isCommercial && selectedSample && selectedSample.selectedScore) {
      return <CommercialInformation
        key={`${selectedSample.key}${tab}`}
        sampleId={sampleId}
        cupsPerSample={cupsPerSample}
        score={selectedSample.selectedScore}
        isEdit={isEdit}
        setIsEditScore={() => setIsEditScore(true)}
        setIsEditDescriptor={() => setIsEditDescriptor(true)}
        language={languagePreference}
      />
    }

    if (isSimplifiedCommon && selectedSample && selectedSample.selectedScore) {
      return (
        <SimplifiedInformation
          key={`${selectedSample.key}${tab}`}
          score={selectedSample.selectedScore}
          isEdit={isEdit}
          setIsEditScore={() => setIsEditScore(true)}
        />
      )
    }

    if (!isShowCuppingTargetScore) {
      return (
        <>
          {sampleInformation.map(sample =>
            <SampleInformation
              key={`${sample.key}${tab}`}
              {...sample}
              uniqKey={sample.key}
              label={t(sample.label)}
              isEdit={isEdit}
              isCoe={selectedSample?.cuppingProtocol === 'cup_of_excellence'}
              language={languagePreference}
              onChange={(score) => setValue(setScoreValue, sample.key, score)}
              isScoreTarget={0}
              setIsEditDescriptor={() => setIsEditDescriptor(true)}
              setDescriptor={setScoreValue}
              sampleId={sampleId}
              fiveCups={fiveCups}
            />
          )}
          {setGroupScoreData(scores)}
        </>
      )
    }

    return (
      <>
        {setGroupScoreData(scores)}
        {groupScore.filter(x => (x.id === scoreId || x.id === scoreIdOwner)).map(sample =>
          <SampleInformation
            key={`${sample.key}${tab}`}
            {...sample}
            uniqKey={sample.key}
            label={t(sample.label)}
            isEdit={isEdit}
            isCoe={selectedSample?.cuppingProtocol === 'cup_of_excellence'}
            language={languagePreference}
            onChange={(score) => setValue(setScoreValue, sample.key, score)}
            isScoreTarget={sample.isScoreTarget}
          />
        )}
      </>
    )
  }

  const renderSampleItem = (
    <Box className={classes.tabPanelWrapper}>
      {isSucafinaFeature && renderAggregateForm}
      <Divider sx={{mb: 3}} />
      {renderCuppers}
      {renderDescription}

      {scoreId &&
        <Box sx={{mt: 3}}>
          <Typography variant='h6' sx={{mb: 2}}>{isSimplifiedCommon? t('report.sampleSensoryInformation') : t('report.sampleTypeInformation')}</Typography>
          {renderSampleInformation()}
        </Box>
      }   
      <Box sx={{mt: 5}}>
        <Typography variant='h6' sx={{mb: 3}}>{t('report.sampleStory')}</Typography>
        {isEdit ?
          <Box>
            <TextField
              fullWidth
              size='small'
              placeholder={t('report.subject')}
              value={storySubject}
              onChange={(e) => setValue(setSelectedSampleValue, 'storySubject', truncate(e.target.value, {'length': 200, 'omission': ''}))}
              margin='dense'
            />
            <TextField
              fullWidth
              multiline
              size='small'
              minRows={3}
              placeholder={t('report.writeStoriesDescription')}
              value={storyDescription}
              onChange={(e) => setValue(setSelectedSampleValue, 'storyDescription', truncate(e.target.value, {'length': 300, 'omission': ''}))}
              margin='dense'

            />
          </Box> :
          <Box>
            <Typography variant='body2' sx={{wordBreak: 'break-word'}}>{storySubject || '-' }</Typography>
            <Typography variant='body3' sx={{wordBreak: 'break-word'}}>{storyDescription || '-' }</Typography>
          </Box>
        }
      </Box>

      {isEdit &&
        <Box sx={{mt: 5}}>
          <Button
            variant="contained"
            onClick={onSave}
            className='wide'
            sx={{mr: 1}}
          >
            {t('common.save')}
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setShowButtonCuppingTargetScore((selectedSample.isScoreTarget && selectedSample.selectedScore.cupperTitle !== CUPPING_SESSION_OWNER_TITLE))
              setIsEdit(false)
              discardSample(currentSample)
            }}
            className='wide'
            sx={{mr: 1}}
          >
            {t('common.discard')}
          </Button>
        </Box>
      }
    </Box>
  )

  const renderButtons = () => {
    if (!isAuthenticated) return

    if (isMobile) {
      return (
        <>
          <Button
            fullWidth
            variant="contained"
            endIcon={<KeyboardArrowDown />}
            onClick={(event) => setAnchorMenu(event.currentTarget)}
          >
            {t('common.action')}
          </Button>

          <Menu
            anchorEl={anchorMenu}
            open={openMenu}
            onClose={() => setAnchorMenu(null)}
            onClick={() => setAnchorMenu(null)}
            sx={{
              '& .MuiPaper-root': {
                width: "100%"
              }
            }}
          >
            <MenuItem onClick={() => setOpenSyncSummary(true)}>
              <MenuIcon><Sync /></MenuIcon>
              {t("sampleAggregate.sync")}
            </MenuItem>
            {scaFeature && (
              <MenuItem onClick={() => onExport('contingency_table')}>
                <MenuIcon><Output /></MenuIcon>
                {t('report.exportContingencyTable')}
              </MenuItem>
            )}
            <MenuItem onClick={() => onExport('summary')}>
              <MenuIcon><Output /></MenuIcon>
              {t('report.exportSummary')}
            </MenuItem>
            <MenuItem onClick={() => onExport('detailed_report')}>
              <MenuIcon><Output /></MenuIcon>
              {t('report.exportDetailedReport')}
            </MenuItem>
            <MenuItem disabled={isCuppingSessionSimplifiedCommon} onClick={() => navigate(`/individual-report/${selectedCuppingSession?.uniqueToken}`)}>
              <MenuIcon><SummarizeOutlined /></MenuIcon>
              {t('report.seeIndividualReport')}
            </MenuItem>
            {isPublicSession && (
              <MenuItem disabled={isCuppingSessionSimplifiedCommon} onClick={() => navigate(`/sample-report-collective/${selectedCuppingSession?.uniqueToken}`)}>
                <MenuIcon><SummarizeOutlined /></MenuIcon>
                {t('report.seeCollectiveReport')}
              </MenuItem>
            )}
          </Menu>
        </>
      )
    }

    return (
      <Stack direction="row" spacing={1} justifyContent="end">
        {(selectedCuppingSession?.isOwner && scores.length > 1) &&
          <FormControlLabel
            label={t('report.enableAllCalibration')}
            control={
              <Switch
                checked={every(samples, 'isScoreTarget')}
                onChange={(e) => changeCalibrationStatus(e.target.checked)}
              />
            }
          />
        }
        {isSucafinaFeature && (
          <Button
            variant="contained"
            endIcon={<HelpOutline />}
            onClick={() => setOpenSyncSummary(true)}
          >
            {t('sampleAggregate.sync')}
          </Button>
        )}
        <Button
          variant="contained"
          onClick={() => {
            setOpenSnapshotReportConfig(true)
            setNavigateUrl(`/cupping-sessions-snapshot/${selectedCuppingSession?.uniqueToken}`)
          }}
        >
          {t('report.snapshot')}
        </Button>
        {
          isCuppingSessionSimplifiedCommon ?
            <LightTooltip title={t('report.disableReportMessage')}>
              <span>
                <Button
                  variant="contained"
                  disabled
                  endIcon={<HelpOutline />}
                >
                  {t('report.seeIndividualReport')}
                </Button>
              </span>
            </LightTooltip> :
            <Button
              variant="contained"
              onClick={() => navigate(`/individual-report/${selectedCuppingSession?.uniqueToken}`)}
            >
              {t('report.seeIndividualReport')}
            </Button>
        }

        {isPublicSession ? isCuppingSessionSimplifiedCommon ?
          <LightTooltip title={t('report.disableReportMessage')}>
            <span>
              <Button
                variant="contained"
                disabled
                endIcon={<HelpOutline />}
              >
                {t('report.seeCollectiveReport')}
              </Button>
            </span>
          </LightTooltip> :
          <Button
            variant="contained"
            onClick={() => navigate(`/sample-report-collective/${selectedCuppingSession?.uniqueToken}`)}
          >
            {t('report.seeCollectiveReport')}
          </Button> : null
        }

        {isSca ? (
          <Button
            variant="contained"
            onClick={() => onExport('sca_reports')}
          >
            {t('report.exportReport')}
          </Button>
        ) : (
          <Button
            variant="contained"
            endIcon={<KeyboardArrowDown />}
            onClick={e => setAnchorExport(e.currentTarget)}
          >
            {t('report.exportOptions')}
          </Button>
        )}
        <Menu
          anchorEl={anchorExport}
          open={openExport}
          onClose={() => setAnchorExport(null)}
        >
          <MenuItem
            disabled={!scaFeature}
            onClick={() => {
              setAnchorExport(null)
              onExport('contingency_table')
            }}
          >
            {t('report.exportContingencyTable')}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorExport(null)
              onExport('summary')
            }}
          >
            {t('report.exportSummary')}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorExport(null)
              onExport('detailed_report')
            }}
          >
            {t('report.exportDetailedReport')}
          </MenuItem>
        </Menu>
      </Stack>
    )
  }

  const onShareSamples = () => {
    setShareErrors({})
    const validateEmails = chain(to).split(',')
        .map(email => { return validateEmail(email.replace(/\s/g, ""))})
        .every(Boolean).value()

    if (!validateEmails) {
      setShareErrors({ "email": [{ message: t('sample.error.emailIsInvalid') }] })
      return
    }

    setShareValue('samplesToShare', ids.join(','))

    globalAction(notificationStore, {
      action: shareSamples,
      afterAction: () => {
        setIds([])
        setOpenShare(false)
      }
    })
  }

  const handleCheckboxAll = (checked: boolean) => {
    setCheckedAll(checked);
    samples.forEach(sample => {
      handleCheckbox(checked, sample.id)
    })
  };

  const onCheckAll = () => {
    if(checkedAll) return ids.length = 0

    samples.forEach(sample => {
      ids.push(sample.id)
    })
  }

  const handleCheckbox =(checked: boolean, id:string)  => {
    setChecked((values) => ({
      ...values,
      [id]: checked
    }));
  }

  const onCheck = (id: string) => {
    const newIds = clone(ids)

    if (includes(ids, id)) {
      pull(newIds, id)

    } else {
      newIds.push(id)
    }

    setIds(newIds)
  }

  const handleExpand = (id) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? id : false)
  }

  const handleIsSelected = async (checked, sampleId, scoreId) => {
    try {
      notificationStore.setLoading(true)
      await saveIsSelected(checked, sampleId, scoreId)
      selectedSample.scores[selectedSample.scores.findIndex( x => x.id === scoreId)].setValue('isSelected', checked)
      selectedSample.setValue('selectedScore', cloneDeep(selectedSample.scores[selectedSample.scores.findIndex( x => x.id === scoreId)]))
    } finally {
      selectPersonalScore()
      notificationStore.setLoading(false)
    }
  }

  const renderSampleInfo = (sample, isActive) => {
    return (
      <Stack spacing={0.5}>
        <Box>
          <Typography display="inline" color={isActive ? "primary" : ""} variant="body2" fontWeight={600}>
            {sample.sampleId}
          </Typography>&nbsp;
          <Typography display="inline" variant="body2" fontWeight={600}>
            {sample.name ?? '-'}
          </Typography>
        </Box>
        <Typography variant="caption">
          {sample.sampleUniqueNumber} - {optionsTranslation('cuppingProtocol', sample.cuppingProtocol)}
        </Typography>
        <Typography variant="caption">
          {optionsTranslation('species', sample.species, true)} | {countryName(sample.countryCode) ?? '-'} | {optionsTranslation('sampleType', sample.sampleType, true)}
        </Typography>
      </Stack>
    )
  }

  const renderSampleListItem = (sample) => {
    const isActive = selectedSample.id === sample.id

    return (
      <Stack
        key={sample.id}
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        className={clsx(classes.sampleNav, (isActive && !isMobile) && 'active')}
        onClick={() => {
          if (isEdit) return

          setSampleValue('selectedSample', sample.id)
        }}
      >
        {renderSampleInfo(sample, isActive)}
        <Box textAlign="center">
          <Typography fontSize="10px" fontWeight={600}>{t('sample.avgScore')}</Typography>
          <Typography variant="h6" color={isActive || isMobile ? "primary" : ""}>{sample.cachedAverageScore}</Typography>
        </Box>
      </Stack>
    )
  }

  const renderWeb = (
    <Box display="flex" mt={4}>
      <Box width="342px" className={classes.sampleNavWrapper}>
        {orderedSamples.map((sample) => renderSampleListItem(sample))}
      </Box>
      <Box flex={1}>
        {renderSampleItem}
      </Box>
    </Box>
  )

  const renderMobile = (
    <Box mt={2}>
      {orderedSamples.map((sample) => {
        if (!!expanded && expanded !== sample.id) return null

        return (
          <Accordion
            key={sample.id}
            elevation={0}
            expanded={expanded === sample.id}
            onChange={handleExpand(sample.id)}
          >
            <AccordionSummary expandIcon={<ExpandMore color='primary'/>}>
              {renderSampleListItem(sample)}
            </AccordionSummary>
            <AccordionDetails>
              {renderSampleItem}
            </AccordionDetails>
          </Accordion>
        )
      })}
    </Box>
  )

  const handleOpenReportConfigurationModal = () => {
    setOpenReportConfiguration(true);
  }

  const handleCloseReportConfigurationModal = () => {
    setOpenReportConfiguration(false);
    setOpenSnapshotReportConfig(false)
  }

  const setDefaultSelectedSample = (sampleToken?: string) => {
    if (!sampleToken) {
      return;
    }

    const defaultSelectedSample = samples.find(sample => sample.uniqueToken === sampleToken);
    if (!defaultSelectedSample) {
      return;
    }

    setSampleValue('selectedSample', defaultSelectedSample);
    setTab(toString(defaultSelectedSample.id));
    setCurrentSample(cloneDeep(defaultSelectedSample));
  }

  const handleActionOnSuccess = () => {
    if (!selectedSampleTokenBeforeActionToken) {
      return;
    }

    setDefaultSelectedSample(selectedSampleTokenBeforeActionToken);
  }

  React.useEffect(() => {
    setShareValue('message', t('sample.shareSampleText'))

    if (!guideSample && isGuideSample) navigate(homeLink)
  }, [guideSample])

  React.useEffect(() => {
    updateScore()
    setIdOwnerData()
    setCachedAverageScoreOwner()
  }, [sampleInformation])

  React.useEffect(() => {
    setTab(toString(id))
    setCurrentSample(cloneDeep(selectedSample))
  }, [id])

  React.useEffect(() => {
    if (scores.length > 0 && !selectedSample?.selectedScore?.id) {
      const score = selectedSample.scores.filter(score => {
        if (tempScoreId) {
          return score.id === tempScoreId
        }

        return score.cupperName !== ANONYMOUS_NAME
      })?.[0]
      if (!score) return

      selectedSample.setValue('selectedScore', cloneDeep(score))
    }
  }, [scores.length, id, scoreId])

  const initData = async () => {
    setDefault();
    if(isAdmin) { 
      await getTmpScore();
    }
    setDefaultSelectedSample(sampleToken);
  }

  React.useEffect(() => {
    initData();
  }, [])

  return (
    <Box px={isMobile ? 0 : 4} py={2.5}>
      <Box px={isMobile ? 2 : 0}>
        <HeaderTitleWide
          {...(isAuthenticated && {
            dataLinks: [
              {
                backUrl: reviewSamplesLink ,
                breadcrumb: t('common.backTo', { menu: t('menu.reviewSamples')}),
              },
              {
                backUrl: sampleInformationLink ,
                breadcrumb: t('common.goTo', { menu: t('menu.sampleInformation')}),
              },
              ...(sampleToken ? [{
                backUrl: sampleReviewLink,
                breadcrumb: t('common.goTo', { menu: t('menu.cuppingSessionReview')}),
              }] : [])
            ]
          })}
          titleNode={renderTitle}
        />

        {renderButtons()}
      </Box>

      {isMobile ? renderMobile : renderWeb}

      <ModalWrapper
        maxWidth='sm'
        open={openShare}
        onClose={() => {
          setOpenShare(false);
        }}
      >
        <Typography variant='h5' sx={{mb: 2}}>{t('sample.shareSampleList')}</Typography>
        <Divider />

        <FormInput
            label={t('sample.sampleName')}
        />
        <Grid item xs={12}>
          <Grid container spacing={1} >
            <Grid item xs={1} >
              <Checkbox sx={{
                color: "#8D57B2",
                '&.Mui-checked': {
                  color: "#8D57B2",
                },
              }}
              checked={checkedAll}
              onChange={(event, checked) => {
                onCheckAll()
                handleCheckboxAll(checked)
              }}
              />
            </Grid>
            <Grid item xs={8} sx={{mt: 1.5}}>
              <Typography variant='body2'>{checkedAll ? t('cuppingSession.clearAll'):t('cuppingSession.selectAll')}</Typography>
            </Grid>
          </Grid>
        </Grid>

        {orderedSamples.map((sample) =>
          <Grid key={sample.id} item xs={12}>
            <Grid container spacing={1} >
              <Grid item xs={1} >
                <Checkbox sx={{
                  color: "#8D57B2",
                  '&.Mui-checked': {
                    color: "#8D57B2",
                  },
                }}
                checked={checked[sample.id]}
                onChange={(event, checked) => {
                  onCheck(sample.id)
                  handleCheckbox(checked, sample.id)
                }} />
              </Grid>

              {isEmpty(sample.name) ?
                <Grid item xs={8} sx={{mt: 1.5}}>
                  <Typography variant='body2'>{sample.sampleId}</Typography>
                </Grid> :
                <Grid item xs={8} sx={{mt: 1.5}}>
                  <Typography variant='body2'>{sample.sampleId} - {sample.name}</Typography>
                </Grid>
              }
            </Grid>
          </Grid>
        )}
        <FormInput
            label={t('sample.to')}
            textFieldProps={{
              value: to,
              onChange: (e) => setShareValue('to', e.target.value),
              helperText: has(shareErrors, 'email') ? pointerMessages('email', shareErrors) : t('report.emailShareSampleDescription'),
              error: has(shareErrors, 'email')
            }}
        />
        <FormInput
            label={t('sample.message')}
            textFieldProps={{
              multiline: true,
              rows: 3,
              value: message,
              onChange: (e) => setShareValue('message', e.target.value)
            }}
        />

        <Button
            variant='contained'
            className='wide'
            sx={{mt: 3}}
            disabled={!ids || !to || !message}
            onClick={onShareSamples}
        >
          {t('report.send')}
        </Button>
      </ModalWrapper>

      {renderSyncSummary}
      <ModalConfirm
        rtl
        open={openSyncModal}
        title={t('sampleAggregate.syncModalTitle')}
        description={t('sampleAggregate.syncModalDescription')}
        onConfirm={onSyncSample}
        onCancel={() => setOpenSyncModal(false)}
        confirm={t('sampleAggregate.sync')}
      />

      <ModalMessage
        open={openSuccess}
        onSubmit={() => {
          setOpenSuccess(false)
          onGetCuppingSession().then(handleActionOnSuccess)
        }}
        description={t('sampleAggregate.successSync')}
        buttonText={t('common.backToPage')}
      />

      <ModalMessage
        open={openSuccessSave}
        onSubmit={() => {
          setOpenSuccessSave(false);
          onGetCuppingSession().then(handleActionOnSuccess);
        }}
        description={t('sampleAggregate.successSave')}
        buttonText={t('common.backToPage')}
      />

      <ReportConfigurationModal
        isSimplifiedCommon={isCuppingSessionSimplifiedCommon}
        isOpen={isOpenReportConfiguration || isOpenSnapshotReportConfig}
        type="cupping"
        navigateUrl={navigateUrl}
        onClose={handleCloseReportConfigurationModal}
        reportType={isOpenReportConfiguration ? "seeReport" : "snapshotReport"}
       />
    </Box>
  )
})

export default CuppingSessionReview
