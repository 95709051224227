import React, { useEffect, useState } from 'react';
import { Box, Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DataGrid, GridColDef, GridEventListener, useGridApiContext, useGridApiRef } from "@mui/x-data-grid";
import { observer } from "mobx-react-lite";
import { useStores } from "models";
import {
	simplifiedCommonSelections,
	simplifiedCommonExpectations,
	simplifiedCommonSampleResults
} from "constants/form";
import { flavorDescriptorList } from 'constants/flavor-descriptors'

import { useStyles } from "./simplified-common.styles";
import { EditSampleModal } from "./edit-sample-modal";
import { colors, theme } from 'assets';
import { SimplifiedCommonDefaultSelectInput, SimplifiedCommonDescriptorInput, SimplifiedCommonSelectInput, SimplifiedCommonStickyColumn, SimplifiedCommonTextInput } from './simplified-common-input';
import './simplified-common.css';
import { getInitialSimplifiedCommonCuppingData, saveSimplifiedCommonCuppingData } from './simplified-common-utils';
import { upperFirst } from 'lodash';
import { flatListDescriptors } from 'utils';

const getCellId = (field: string, id: string) => `${field}-${id}`;

const SimplifiedCommonUsing = () => {
	const { t } = useTranslation();
	return (
		<Stack direction='row' spacing={1}>
			<Typography>
				{t('sampleScore.currentlyUsing')}
			</Typography>
			<Typography fontWeight='bold' color={colors.primary.main}>
				{t('options.cuppingProtocol.simplifiedCommon')}
			</Typography>
		</Stack>
	)
}

interface SimplifiedCommonProps {
	actionButton?: JSX.Element;
	isAuthenticated: boolean;
}

export const SimplifiedCommon = observer((props: SimplifiedCommonProps) => {
	const { actionButton, isAuthenticated } = props;
	const { t } = useTranslation()
	const classes = useStyles()
	const apiRef = useGridApiRef();
	const [rows, setRows] = useState<any[]>(() => [])
	const [openEditSampleModal, setOpenEditSampleModal] = useState(false)

	const {
		sampleStore: { samples },
		cuppingSessionStore: { selectedCuppingSession },
	} = useStores();

	const processRowUpdate = (newRow) => {
		setRows(rows.map((row) => (row.id === newRow.id ? newRow : row)));

		const sample = samples.find(s => s?.id === newRow.id)
		if (!sample) return

		sample.setValue('selectedScore', {
			...sample.selectedScore,
			totalScore: newRow.totalScore ? parseFloat(newRow.totalScore.toFixed(2)) : 0,
			flavorDescriptors: !!newRow.flavorDescriptors ? newRow.flavorDescriptors : [],
			acidityNote: !!newRow.acidityNote ? newRow.acidityNote : '',
			sweetnessNote: !!newRow.sweetnessNote ? newRow.sweetnessNote : '',
			physicalAspectDescription: newRow.physicalAspectDescription,
			physicalAspectRate: newRow.physicalAspectRate,
			overallNote: !!newRow.overallNote ? newRow.overallNote : '',
			result: newRow.result,
			internalNotes: newRow.internalNotes,
		});

		saveEditingSamples();
	};

	const saveEditingSamples = async () => {
		if (!selectedCuppingSession) return;
		await saveSimplifiedCommonCuppingData(selectedCuppingSession.id, samples);
	}

	const updateSampleReference = () => {
		setRows(prevRows => {
			const newRows = [...prevRows]
			newRows.forEach(row => {
				const sample = samples.find(s => s?.id === row.id)
				if (!sample) return

				row.sampleReference = sample.sampleReference;
				row.name = sample.name;
			})

			return newRows
		})
	}
	const commonSelections = simplifiedCommonSelections.filter(item => item !== 'Phenol');
	const simplifiedFlavorDescriptorList = (flatListDescriptors(flavorDescriptorList) || []).map((item) => ({
		id: item.id,
		value: item.label,
		label: upperFirst(item.label),
	}));
	const columns: GridColDef[] = [
		{ field: 'id' },
		{
			field: 'sample',
			headerName: t('sampleScore.sample'),
			width: 200,
			renderCell: (params) => <SimplifiedCommonStickyColumn params={params} />,
			cellClassName: "sticky-name",
			headerClassName: "sticky-name"
		},
		{
			field: 'sampleReference',
			headerName: t('sample.sampleReference'),
			width: 250,
			renderCell: (params) =>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						overflow: 'hidden',
						width: '100%',
						height: '100%',
						justifyContent: 'center',
					}}
				>
					<Typography
						title={params.value ? params.value : '-'}
						fontSize={16}
						sx={{
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
							display: 'block',
						}}
					>
						{params.value ? params.value : '-'}
					</Typography>
				</Box>,
			cellClassName: "sticky-reference", headerClassName: "sticky-reference"
		},
		{
			field: 'totalScore',
			headerName: t('descriptor.score'),
			editable: true,
			type: 'number',
			width: 100,
			renderCell: (params) =>
				<SimplifiedCommonTextInput params={params} editingCellId={editingCellId} handleChange={handleChange} type='number' />
		},
		{
			field: 'flavorDescriptors',
			headerName: t('descriptor.flavor'),
			editable: true,
			type: 'singleSelect',
			valueOptions: flavorDescriptorList,
			width: 350,
			renderCell: (params) => {
				const displayValues = params.value
					.map(value => simplifiedFlavorDescriptorList.find(option => option.value === value)?.label)
					.filter(Boolean)
					.join(', ');
				return <SimplifiedCommonDefaultSelectInput value={displayValues} />
			},
			renderEditCell: (params) => {			  
				const { id, field } = params;
				const rowValue = apiRef.current.getRow(id)?.[field] || []; 
			  
				return (
				  <SimplifiedCommonDescriptorInput
					params={{ ...params, value: params.value ?? rowValue }}
					valueOptions={simplifiedFlavorDescriptorList}
				  />
				);
			  },
		},
		{
			field: 'acidityNote',
			headerName: t('descriptor.acidity'),
			editable: true,
			type: 'singleSelect',
			valueOptions: commonSelections,
			width: 200,
			renderCell: (params) => <SimplifiedCommonDefaultSelectInput value={params.value} />,
			renderEditCell: (params) => {
				const { id, field } = params;
				const rowValue = apiRef.current.getRow(id)[field];

				return (
					<SimplifiedCommonSelectInput
						params={{ ...params, value: params.value ?? rowValue }}
						options={commonSelections}
					/>
				);
			},
		},
		{
			field: 'sweetnessNote',
			headerName: t('descriptor.sweetness'),
			editable: true,
			type: 'singleSelect',
			valueOptions: commonSelections,
			width: 200,
			renderCell: (params) => <SimplifiedCommonDefaultSelectInput value={params.value} />,
			renderEditCell: (params) => {
				const { id, field } = params;
				const rowValue = apiRef.current.getRow(id)[field];

				return (
					<SimplifiedCommonSelectInput
						params={{ ...params, value: params.value ?? rowValue }}
						options={commonSelections}
					/>
				);
			},
		},
		{
			field: 'physicalAspectDescription',
			headerName: t('descriptor.physicalAspectDescription'),
			editable: true,
			width: 200,
			renderCell: (params) =>
				<SimplifiedCommonTextInput params={params} editingCellId={editingCellId} handleChange={handleChange} />
		},
		{
			field: 'physicalAspectRate',
			headerName: t('descriptor.physicalAspectRate'),
			editable: true,
			type: 'singleSelect',
			valueOptions: simplifiedCommonExpectations,
			width: 200,
			renderCell: (params) => <SimplifiedCommonDefaultSelectInput value={params.value} />,
			renderEditCell: (params) => {
				const { id, field } = params;
				const rowValue = apiRef.current.getRow(id)[field];

				return (
					<SimplifiedCommonSelectInput
						params={{ ...params, value: params.value ?? rowValue }}
						options={simplifiedCommonExpectations}
					/>
				);
			},
		},
		{
			field: 'overallNote',
			headerName: t('descriptor.overallExpectation'),
			editable: true,
			type: 'singleSelect',
			valueOptions: simplifiedCommonExpectations,
			width: 200,
			renderCell: (params) => <SimplifiedCommonDefaultSelectInput value={params.value} />,
			renderEditCell: (params) => {
				const { id, field } = params;
				const rowValue = apiRef.current.getRow(id)[field];

				return (
					<SimplifiedCommonSelectInput
						params={{ ...params, value: params.value ?? rowValue }}
						options={simplifiedCommonExpectations}
					/>
				);
			},
		},
		{
			field: 'result',
			headerName: t('descriptor.sampleResult'),
			editable: true,
			type: 'singleSelect',
			valueOptions: simplifiedCommonSampleResults,
			width: 200,
			renderCell: (params) => <SimplifiedCommonDefaultSelectInput value={params.value} />,
			renderEditCell: (params) => {
				const { id, field } = params;
				const rowValue = apiRef.current.getRow(id)[field];

				return (
					<SimplifiedCommonSelectInput
						params={{ ...params, value: params.value ?? rowValue }}
						options={simplifiedCommonSampleResults}
					/>
				);
			},
		},
		{
			field: 'internalNotes',
			headerName: t('descriptor.internalNotes'),
			editable: true,
			width: 200,
			renderCell: (params) =>
				<SimplifiedCommonTextInput params={params} editingCellId={editingCellId} handleChange={handleChange} />
		},
	]

	const handleCellKeyDown = (params, event) => {
		if (event.key === 'Tab') {
			event.preventDefault(); // Prevent the default tab behavior

			const { id: rowId, field } = params; // Current cell's row and field
			const columnIndex = columns.findIndex((col) => col.field === field);

			let nextColumnIndex;
			let nextRowId = rowId;

			if (event.shiftKey) {
				// Shift + Tab: Move to the previous cell
				nextColumnIndex = (columnIndex - 1 + columns.length) % columns.length;
				if (nextColumnIndex === columns.length - 1) {
					// Move to the previous row if we are at the first column
					nextRowId = rowId - 1 < rows[0].id ? rows[rows.length - 1].id : rowId - 1;
				}
			} else {
				// Tab: Move to the next cell
				nextColumnIndex = (columnIndex + 1) % columns.length;
				if (nextColumnIndex === 0) {
					// Move to the next row if we are at the last column
					nextRowId = rowId + 1 > rows.length ? rows[0].id : rowId + 1;
				}
			}

			const nextField = columns[nextColumnIndex].field;
			apiRef.current.setCellFocus(nextRowId, nextField);
		}
	};

	const [editingCellId, setEditingCellId] = useState<string | null>(null);

	const handleCellClick: GridEventListener<'cellClick'> = (params) => {
		const { id, field } = params;
		setEditingCellId(getCellId(field, id as string));
	};

	const handleChange = (id: number, field: string, value: string) => {
		setRows((prevRows) =>
			prevRows.map((row) =>
				row.id === id ? { ...row, [field]: value } : row
			)
		);
	};

	const init = async () => {
		if (!selectedCuppingSession) return;
		const initSamples = await getInitialSimplifiedCommonCuppingData(selectedCuppingSession.id, samples);
		const rows = initSamples.map(sample => ({
			id: sample?.id!,
			sampleUniqueNumber: sample?.sampleUniqueNumber,
			name: sample?.name,
			sampleReference: sample?.sampleReference,
			totalScore: sample?.selectedScore.totalScore,
			flavorDescriptors: sample?.selectedScore.flavorDescriptors,
			acidityNote: sample?.selectedScore.acidityNote,
			sweetnessNote: sample?.selectedScore.sweetnessNote,
			physicalAspectDescription: sample?.selectedScore.physicalAspectDescription,
			physicalAspectRate: sample?.selectedScore.physicalAspectRate,
			overallNote: sample?.selectedScore.overallNote,
			result: sample?.selectedScore.result,
			internalNotes: sample?.selectedScore.internalNotes,
		}));
		setRows(rows);

		// Update the samples with the latest data from store
		const newRowsMap = rows.reduce((acc, row) => { acc[row.id] = row; return acc; }, {});
		for (let i = 0; i < samples.length; i++) {
			const sample = samples[i];
			if (!sample) continue;
			const newRow = newRowsMap[sample.id];
			if (newRow) {
				sample.setValue('selectedScore', {
					...sample.selectedScore,
					totalScore: newRow.totalScore,
					flavorDescriptors: newRow.flavorDescriptors,
					acidityNote: newRow.acidityNote,
					sweetnessNote: newRow.sweetnessNote,
					physicalAspectDescription: newRow.physicalAspectDescription,
					physicalAspectRate: newRow.physicalAspectRate,
					overallNote: newRow.overallNote,
					result: newRow.result,
					internalNotes: newRow.internalNotes,
				});
			}
		}
	}

	useEffect(() => {
		init();
	}, [])

	return (
		<>
			<Box>
				<Stack direction='row' justifyContent='space-between' alignItems='end' marginBottom={theme.spacing(4)}>
					<SimplifiedCommonUsing />
					<Stack direction='row' alignItems='center' marginTop={-32}>
						{isAuthenticated ? <Button variant='contained' onClick={() => setOpenEditSampleModal(true)}>
							{t('sample.button.editSample')}
						</Button> : null}
						{actionButton}
					</Stack>
				</Stack>
				<Box style={{ height: rows.length > 8 ? window.innerHeight - 300 : 'unset', width: "100%", overflowX: "auto", position: "relative" }}>
					<DataGrid
						rows={rows}
						columns={columns}
						columnVisibilityModel={{ id: false }}
						disableColumnMenu
						hideFooter
						className={classes.dataGrid}
						disableVirtualization
						disableColumnResize
						processRowUpdate={processRowUpdate}
						disableColumnSorting
						rowHeight={60}
						onProcessRowUpdateError={() => null}
						apiRef={apiRef}
						onCellKeyDown={handleCellKeyDown}
						onCellClick={handleCellClick}
						autoHeight
						getRowHeight={() => 'auto'}
						sx={{
							"& .MuiDataGrid-row": {
							  minHeight: "60px !important",
							},
						  }}
					/>
				</Box>
				<EditSampleModal
					open={openEditSampleModal}
					setOpen={setOpenEditSampleModal}
					updateSampleReference={updateSampleReference}
				/>
			</Box>
		</>
	);
});
