/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import {useLocation, useParams} from 'react-router-dom'
import { useStores } from 'models'
import {cloneDeep, concat, isEmpty} from "lodash";

import { findRoute } from 'constants/routes'
import { HeaderTitleWide } from 'components'
import { globalAction, optionsTranslation } from "utils"

import {useStyles} from "./cupping-session-snapshot.styles";
import {
  Box,
  Button,
  FormControlLabel,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  SvgIcon
} from "@mui/material";
import { SystemUpdateAlt } from '@mui/icons-material'
import {colors} from "../../assets";
import { IconReportShare } from 'assets/images'
import { CuppingProtocol } from 'constants/constant'

export const CuppingSessionsSnapshot: React.FC = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const params = useParams()
  const locations = useLocation();
  const { state } = locations;

  const sampleLink = findRoute('reviewSamples')
  const sampleInformationLink = findRoute('sampleInformation')
  const sampleReviewLink = findRoute('sampleReview')

  const {
    sampleStore: { getSamples,samples },
    userStore: { isAuthenticated },
    cuppingSessionStore: { exportSnapshotReport },
    notificationStore
  } = useStores()

  const [loading, setLoading] = React.useState(false)
  const [tempSamples, setTempSamples] = React.useState<any[]>([])
  const [removeInternalScore, setRemoveInternalScore] = React.useState(true)

  const snapshotConfiguration = state ? (state as any).snapshotStore : undefined;

  const onExport = (id, removeInternalScore) =>
    globalAction(notificationStore, { 
      action: async() => await exportSnapshotReport(id, removeInternalScore, getExportAttributes()) 
    });

  const checkReportConfiguration = (att: string) => {
    if (!snapshotConfiguration) {
      return true;
    }

    return snapshotConfiguration[att] === true;
  }

  const getExportAttributes = () => {
    const attributes: string[] = [];
    if (checkReportConfiguration('sampleId')) {
      attributes.push('sample_unique_number');
    }
    if (checkReportConfiguration('sampleName')) {
      attributes.push('name');
    }
    if (checkReportConfiguration('purchaseGrade')) {
      attributes.push('purchase_grade');
    }
    if (checkReportConfiguration('sampleType')) {
      attributes.push('sample_type');
    }
    if (checkReportConfiguration('purchaseContract')) {
      attributes.push('purchase_contract_reference');
    }
    if (checkReportConfiguration('supplier')) {
      attributes.push('supplier_name');
    }
    if (checkReportConfiguration('salesContract')) {
      attributes.push('sales_contract_reference');
    }
    if (checkReportConfiguration('customer')) {
      attributes.push('customer');
    }
    if (checkReportConfiguration('referenceNumber')) {
      attributes.push('reference_number');
    }
    if (checkReportConfiguration('sampleReference')) {
      attributes.push('sample_reference');
    }
    if (checkReportConfiguration('containerNumber')) {
      attributes.push('container_number');
    }
    if (checkReportConfiguration('cargoNumber')) {
      attributes.push('cargo_number');
    }
    if (checkReportConfiguration('cupper')) {
      attributes.push('cupper_name');
    }
    if (checkReportConfiguration('score')) {
      attributes.push('total_score');
    }
    if (checkReportConfiguration('descriptor')) {
      attributes.push('descriptor');
    }
    if (checkReportConfiguration('internalNotes')) {
      attributes.push('notes');
    }
    if (checkReportConfiguration('sampleResult')) {
      attributes.push('sample_result');
    }

    return attributes;
  }

  const makeComma = (arr) => {
    if (arr.length === 1) return arr[0]
    const firsts = arr.slice(0, arr.length)
    return firsts.join(', ')
  }

  const sumTmpSample = (id) => {
    return tempSamples.filter( x => x.sampleUniqueNumber === id).length
  }

  const setTmpSample = (data) =>{
    let no = 1
    data.map((samples, index) => {
      samples.scores.filter( data => data.isSelected).map((sc,i) => {
        if(!sc.isSelected) return
        setTempSamples( tempSamples => [
          ...tempSamples,
          {
            "no": no++,
            "id":i === 0 ? samples.sampleUniqueNumber: "",
            "sampleUniqueNumber":isEmpty(samples.sampleUniqueNumber) ? "-" : samples.sampleUniqueNumber,
            "sampleName": isEmpty(samples.name) ? "-" : samples.name ,
            "sampleReference": isEmpty(samples.sampleReference) ? "-" : samples.sampleReference,
            "salesContractReference": isEmpty(samples.salesContractReference) ? "-" : samples.salesContractReference,
            "purchaseGrade": isEmpty(samples.purchaseGrade) ? "-" : samples.purchaseGrade,
            "sampleType": isEmpty(samples.sampleType) ? "-" : optionsTranslation('sampleType',samples.sampleType),
            "cupper": isEmpty(sc.cupperName) ? t('report.guestCupper'): sc.cupperName,
            "totalScore": removeInternalScore ? sc.totalScore : samples.cuppingResultAggregate.totalScore,
            "des": removeInternalScore ?  makeComma(concat(sc.acidityDescriptors,
              sc.aftertasteDescriptors, sc.balanceDescriptors, sc.bodyDescriptors, sc.defectsDescriptors, sc.flavorDescriptors,
              sc.fragranceDescriptors, sc.saltDescriptors, sc.mouthfeelDescriptors, sc.cleanCupDescriptors,
              sc.fragranceAromaDescriptors, sc.flavorAftertasteDescriptors, sc.tastingDescriptors, sc.sweetnessDescriptors)) : makeComma(samples.cuppingResultAggregate.descriptors),
            "notes": removeInternalScore ? samples.cuppingProtocol === CuppingProtocol.SIMPLIFIED_COMMON ? sc.internalNotes : sc.notes : samples.cuppingResultAggregate.internalNotes,
            "purchaseContractReference": isEmpty(samples.purchaseContractReference) ? "-" : samples.purchaseContractReference,
            "supplier": isEmpty(samples.supplierName) ? "-" : samples.supplierName,
            "customer": isEmpty(samples.customer) ? "-" : samples.customer,
            "referenceNumber": isEmpty(samples.referenceNumber) ? "-" : samples.referenceNumber,
            "containerNumber": isEmpty(samples.containerNumber) ? "-" : samples.containerNumber,
            "cargoNumber": isEmpty(samples.cargoNumber) ? "-" : samples.cargoNumber,
            "results": removeInternalScore ? sc.result : samples.cuppingResultAggregate.sampleResult,
            "flag": "cupper",
          }])
      })

      if(isEmpty(samples.cuppingResultAggregate.totalScore.toString()) || samples.cuppingResultAggregate.totalScore === 0) return

      setTempSamples( tempSamples => [
        ...tempSamples,
        {
          "no": no++,
          "id":"",
          "sampleUniqueNumber":samples.sampleUniqueNumber,
          "sampleName": "",
          "sampleReference": "",
          "purchaseContractReference": "",
          "salesContractReference": "",
          "purchaseGrade":"",
          "sampleType": "",
          "cupper":<Typography variant="body2" sx={{fontWeight: 600}}> {t('report.officialScore')}</Typography>,
          "totalScore": samples.cuppingResultAggregate.totalScore,
          "des": <Typography variant="body2" sx={{fontWeight: 600}}>{t('report.officialDescriptor')}</Typography>,
          "notes": <Typography variant="body2" sx={{fontWeight: 600}}>{samples.cuppingProtocol === CuppingProtocol.SIMPLIFIED_COMMON ? t('report.officialInternalNotes') : t('report.internalNote')}</Typography>,
          "results": <Typography variant="body2" sx={{fontWeight: 600}}>{t('report.officialSampleResult')}</Typography>,
          "flag": "officialScore"
        }])

      setTempSamples( tempSamples => [
        ...tempSamples,
        {
          "no": no++,
          "id":"",
          "sampleUniqueNumber":samples.sampleUniqueNumber,
          "sampleName": "",
          "sampleReference": "",
          "purchaseContractReference": "",
          "salesContractReference": "",
          "purchaseGrade":"",
          "sampleType": "",
          "cupper": "",
          "totalScore": "",
          "des": isEmpty(samples.cuppingResultAggregate.descriptors) ? "-" : makeComma(samples.cuppingResultAggregate.descriptors),
          "notes": isEmpty(samples.cuppingResultAggregate.internalNotes) ? "-" : samples.cuppingResultAggregate.internalNotes,
          "results": isEmpty(samples.cuppingResultAggregate.sampleResult) ? "-" : samples.cuppingResultAggregate.sampleResult,
          "flag": "officialScoreDesInternalScore"
        }])
    })
  }


  React.useEffect(() => {
    (async () => {
      try {
        notificationStore.setLoading(true)
        if(samples.length === 0) await getSamples(params.id)
      } finally {
        tempSamples.length = 0
        setTmpSample(samples)
        notificationStore.setLoading(false)
      }
    })()
  }, [removeInternalScore])

  const isSimplifiedCommon = samples[0].cuppingProtocol === CuppingProtocol.SIMPLIFIED_COMMON;
  return (
    <Box mx={4}>
      <HeaderTitleWide
          title={t('report.cuppingSessionSnapshot')}
          //rightContent={renderButtons}
          sx={{mb: '0 !important'}}
          {...(isAuthenticated && {
            dataLinks: [
              {
                backUrl: sampleLink ,
                breadcrumb: t('common.backTo', { menu: t('menu.reviewSamples')}),
              },
              {
                backUrl: sampleInformationLink ,
                breadcrumb: t('common.goTo', { menu: t('menu.sampleInformation')}),
              },
              {
                backUrl: sampleReviewLink ,
                breadcrumb: t('common.goTo', { menu: t('menu.sampleReview')}),
              }
            ]
          })}
      />
      <Stack
        direction="row"
        justifyContent="flex-end"
      >
      <FormControlLabel
        label={t('report.showIndividualCupper')}
        control={
          <Switch
          checked={removeInternalScore}
          onChange={(e) => {
            setRemoveInternalScore(e.target.checked)
          }}
        />
        }
      />
       <Button
        variant="contained"
        startIcon={<SvgIcon component={IconReportShare} />}
        sx={{ borderRadius: 2 }}
        onClick={()=> onExport(params.id, removeInternalScore)}
      >
        {t('cuppingSession.export')}
      </Button>
      </Stack>
      <TableContainer
        component={Paper}
        className={classes.table}
        sx={{mt: 5, mx:0}}
        >
        <Table
          size="small"
        >
            <TableHead>
            <TableRow>
              {["sampleId", "sampleName", "purchaseGrade", "sampleType", "purchaseContract", "supplier", "salesContract", "customer", "referenceNumber", "sampleReference", "containerNumber", "cargoNumber"]
                .filter(checkReportConfiguration)
                .map((key) => (
                  <TableCell key={key} className={classes.tableHeader}>{t(`report.${key === "sampleId" ? "id" : key}`)}</TableCell>
                ))}
              {removeInternalScore ? (
                <>
                  {checkReportConfiguration('cupper') && <TableCell className={classes.tableHeader}>{t("report.cupper")}</TableCell>}
                  {checkReportConfiguration('score') && <TableCell className={classes.tableHeader}>{t("report.score")}</TableCell>}
                  {checkReportConfiguration('descriptor') && <TableCell className={classes.tableHeader}>{t("report.descriptor")}</TableCell>}
                  {checkReportConfiguration('internalNotes') && <TableCell className={classes.tableHeader}>{isSimplifiedCommon ? t('report.internalNote') : t("report.notes")}</TableCell>}
                  {checkReportConfiguration('sampleResult') && isSimplifiedCommon ? <TableCell className={classes.tableHeader}>{t("report.sampleResult")}</TableCell> : null}
                </>
              ) : (
                <>
                  {checkReportConfiguration('score') && <TableCell className={classes.tableCell}>{t("report.officialScore")}</TableCell>}
                  {checkReportConfiguration('descriptor') && <TableCell className={classes.tableCell}>{t("report.officialDescriptor")}</TableCell>}
                  {checkReportConfiguration('internalNotes') && <TableCell className={classes.tableCell}>{t("report.internalNote")}</TableCell>}
                  {checkReportConfiguration('sampleResult') && isSimplifiedCommon ? <TableCell className={classes.tableCell}>{t("report.officialSampleResult")}</TableCell> : null}
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {tempSamples.map((data,i) => {
              return(
                <TableRow sx={{backgroundColor: (data.flag === "officialScore" || data.flag === "officialScoreDesInternalScore") ? colors.primary.o25 : "white"}}>
                  {!isEmpty(data.id) &&
                    <>
                      {(checkReportConfiguration('sampleId') && removeInternalScore) && <TableCell className={clsx(classes.tableRow, isEmpty(data.id) && 'row')} rowSpan={sumTmpSample(data.id)}>{data.id}</TableCell>}
                      {(checkReportConfiguration('sampleName') && removeInternalScore) && <TableCell className={clsx(classes.tableRow, isEmpty(data.id) && 'row')} rowSpan={sumTmpSample(data.id)}>{data.sampleName}</TableCell>}
                      {(checkReportConfiguration('purchaseGrade') && removeInternalScore) && <TableCell className={clsx(classes.tableRow, isEmpty(data.id) && 'row')} rowSpan={sumTmpSample(data.id)}>{data.purchaseGrade}</TableCell>}
                      {(checkReportConfiguration('sampleType') && removeInternalScore) && <TableCell className={clsx(classes.tableRow, isEmpty(data.id) && 'row')} rowSpan={sumTmpSample(data.id)}>{data.sampleType}</TableCell>}
                      {(checkReportConfiguration('purchaseContract') && removeInternalScore) && <TableCell className={clsx(classes.tableRow, isEmpty(data.id) && 'row')} rowSpan={sumTmpSample(data.id)}>{data.purchaseContractReference}</TableCell>}
                      {(checkReportConfiguration('supplier') && removeInternalScore) && <TableCell className={clsx(classes.tableRow, isEmpty(data.id) && 'row')} rowSpan={sumTmpSample(data.id)}>{data.supplier}</TableCell>}                      
                      {(checkReportConfiguration('salesContract') && removeInternalScore) && <TableCell className={clsx(classes.tableRow, isEmpty(data.id) && 'row')} rowSpan={sumTmpSample(data.id)}>{data.salesContractReference}</TableCell>}
                      {(checkReportConfiguration('customer') && removeInternalScore) && <TableCell className={clsx(classes.tableRow, isEmpty(data.id) && 'row')} rowSpan={sumTmpSample(data.id)}>{data.customer}</TableCell>}
                      {(checkReportConfiguration('referenceNumber') && removeInternalScore) && <TableCell className={clsx(classes.tableRow, isEmpty(data.id) && 'row')} rowSpan={sumTmpSample(data.id)}>{data.referenceNumber}</TableCell>}
                      {(checkReportConfiguration('sampleReference') && removeInternalScore) && <TableCell className={clsx(classes.tableRow, isEmpty(data.id) && 'row')} rowSpan={sumTmpSample(data.id)}>{data.sampleReference}</TableCell>}
                      {(checkReportConfiguration('containerNumber') && removeInternalScore) && <TableCell className={clsx(classes.tableRow, isEmpty(data.id) && 'row')} rowSpan={sumTmpSample(data.id)}>{data.containerNumber}</TableCell>}
                      {(checkReportConfiguration('cargoNumber') && removeInternalScore) && <TableCell className={clsx(classes.tableRow, isEmpty(data.id) && 'row')} rowSpan={sumTmpSample(data.id)}>{data.cargoNumber}</TableCell>}
                      

                      {(checkReportConfiguration('sampleId') && !removeInternalScore) && <TableCell className={classes.tableHeader}>{data.id}</TableCell>}
                      {(checkReportConfiguration('sampleName') && !removeInternalScore) && <TableCell className={classes.tableHeader}>{data.sampleName}</TableCell>}
                      {(checkReportConfiguration('purchaseGrade') && !removeInternalScore) && <TableCell className={classes.tableHeader}>{data.purchaseGrade}</TableCell>}
                      {(checkReportConfiguration('sampleType') && !removeInternalScore) && <TableCell className={classes.tableHeader}>{data.sampleType}</TableCell>}
                      {(checkReportConfiguration('purchaseContract') && !removeInternalScore) && <TableCell className={classes.tableHeader}>{data.purchaseContractReference}</TableCell>}
                      {(checkReportConfiguration('supplier') && !removeInternalScore) && <TableCell className={classes.tableHeader}>{data.supplier}</TableCell>}
                      {(checkReportConfiguration('salesContract') && !removeInternalScore) && <TableCell className={classes.tableHeader}>{data.salesContractReference}</TableCell>}
                      {(checkReportConfiguration('customer') && !removeInternalScore) && <TableCell className={classes.tableHeader}>{data.customer}</TableCell>}
                      {(checkReportConfiguration('referenceNumber') && !removeInternalScore) && <TableCell className={classes.tableHeader}>{data.referenceNumber}</TableCell>}
                      {(checkReportConfiguration('sampleReference') && !removeInternalScore) && <TableCell className={classes.tableHeader}>{data.sampleReference}</TableCell>}
                      {(checkReportConfiguration('containerNumber') && !removeInternalScore) && <TableCell className={classes.tableHeader}>{data.containerNumber}</TableCell>}
                      {(checkReportConfiguration('cargoNumber') && !removeInternalScore) && <TableCell className={classes.tableHeader}>{data.cargoNumber}</TableCell>}

                      {checkReportConfiguration('score') && !removeInternalScore && <TableCell className={classes.tableCell}><Typography variant="body2" sx={{color: colors.primary.main }}>{data.totalScore}</Typography></TableCell>}
                      {checkReportConfiguration('descriptor') && !removeInternalScore && <TableCell className={classes.tableCell}><Typography variant="body2" sx={{color: colors.primary.main }}>{isEmpty(data.des)? "-" : data.des}</Typography></TableCell>}
                      {checkReportConfiguration('internalNotes') && !removeInternalScore && <TableCell className={classes.tableCell}>{isEmpty(data.notes)? "-" : data.notes}</TableCell>}
                      {checkReportConfiguration('sampleResult') && !removeInternalScore && <TableCell className={classes.tableCell}>{isEmpty(data.results)? "-" : data.results}</TableCell>}
                    </>
                  }
                  {(data.flag === "officialScore" && removeInternalScore) &&
                    <>
                      {checkReportConfiguration('cupper') && <TableCell className={clsx(classes.tableRow, data.flag === "officialScoreDesInternalScore" && 'row')} rowSpan={2}>
                          <Typography variant="body2" >{(data.cupper)}</Typography>
                      </TableCell>}
                      {checkReportConfiguration('score') && <TableCell className={clsx(classes.tableRow, data.flag === "officialScoreDesInternalScore" && 'row')} rowSpan={2}>
                          <Typography variant="body2" sx={{color: colors.primary.main }}>{data.totalScore}</Typography>
                      </TableCell>}
                    </>
                  }
                  {(data.flag === "cupper" && removeInternalScore) &&
                    <>
                      {checkReportConfiguration('cupper') && <TableCell className={classes.tableRow}>
                          <Typography variant="body2" >{data.cupper}</Typography>
                      </TableCell>}
                      {checkReportConfiguration('score') && <TableCell className={classes.tableRow}>
                          <Typography variant="body2" sx={{color: colors.primary.main }}>{data.totalScore}</Typography>
                      </TableCell>}
                    </>
                  }
                  {removeInternalScore && 
                    <>
                      {checkReportConfiguration('descriptor') && <TableCell className={classes.tableRow}>
                        <Typography variant="body2" sx={{color: data.flag === "officialScore" ? colors.text.primary :  colors.primary.main }}>{isEmpty(data.des)? "-" : data.des}</Typography>
                      </TableCell>}
                      {checkReportConfiguration('internalNotes') && <TableCell className={classes.tableRow}>{isEmpty(data.notes)? "-" : data.notes}</TableCell>}
                      {
                        checkReportConfiguration('sampleResult') && isSimplifiedCommon ? <TableCell className={classes.tableRow}>{isEmpty(data.result) ? data.results : data.result}</TableCell> : null
                      }
                    </>
                  }
                </TableRow>
                )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
})

export default CuppingSessionsSnapshot
