import React from "react";
import { useTranslation } from "react-i18next";
import {
    MenuItem,
    Select,
} from "@mui/material";
import { optionsTranslation } from "utils";



interface FilterSelectProps {
    value: string | null;
    onChange: (e) => void;
    options: string[];
    translationKey: string;
}

export const FilterSelect = (props: FilterSelectProps) => {
    const { t } = useTranslation();
    const { value, onChange, options, translationKey } = props;
    return (
        <Select
            size="small"
            displayEmpty
            defaultValue=""
            value={value || ''}
            onChange={onChange}
            sx={{
                color: value ? 'inherit' : '#00000080',
                backgroundColor: 'white',
                width: '100%',
                textAlign: 'left',
            }}
        >
            <MenuItem value="" sx={{ height: 34, color: '#00000080' }}>
                {t('common.select')}
            </MenuItem>
            {options.map((item) => (
                <MenuItem key={item} value={item}>
                    {optionsTranslation(translationKey, item)}
                </MenuItem>
            ))}
        </Select>
    )
}