/**
 * @author Rohman Widiyanto
 * @email rohmansca@gmail.com
 * @create date 2022-02-06 21:22:33
 * @modify date 2022-02-06 21:22:33
 */
import React from 'react'
import clsx from 'clsx'
import moment from 'moment'
import { t } from 'i18next'
import {camelCase, truncate} from 'lodash'
import { useNavigate } from 'react-router-dom'
import { MoreHoriz, DeleteOutline, CloudUpload, Settings as SettingsIcon } from '@mui/icons-material'
import {
  Card, Grid, Button, IconButton, Menu, MenuItem, Typography, SvgIcon, Tooltip, Stack,
  Box,
  Checkbox,
  FormControlLabel
} from '@mui/material'
import QrCode2Icon from '@mui/icons-material/QrCode2';

import { MenuIcon } from 'components'
import {IconStartCupping, IconReview, IconEdit, IconPlayCircle, IconPauseCircle, IconLabel} from 'assets/images'
import { formatDateWithTime } from 'constants/form'

import { SessionItemProps } from './session-item.props'
import { useStyles } from './session-item.styles'
import { isMobile } from 'react-device-detect'

export const SessionItem: React.FC<SessionItemProps> = (props: SessionItemProps) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const {
    id, numberForCompany, ownerName, startsAt, name, location, cuppingProtocol, samplesWithInfoCount, samplesCount, uniqueToken, isPublic, isPaused,
    addSampleInfoTooltipMessage = '', reasonCanNotCupNow = '', reasonCanNotBeReviewed = '', reasonCanNotBeDeleted = '', samples,
    canNotAddSampleInfo, canBeStarted, canNotCupNow, canNotResendToWebhook, canNotBeDeleted, canNotBeEdited, isSelected, onResendWebhook,
    onStartCupping, onCuppingNow, onEditSampleInfo, onReviewCuppingSession, onRemoveCuppingSession, onPrintQrCode, onPlayOrPause, onSettingQrCode,
    onClickRightReviewCuppingSession, onSelectItem, onPrintAllLabel
  } = props

  const tooltipCanNotBeEdited = canNotBeEdited ? t('cuppingSession.youAreNotTheOwnerOfCuppingSession') : ''
  const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null)
  const [checked, setChecked] = React.useState(isSelected);
  const openMenu = Boolean(anchorMenu)

  const cuppingNow = () => {
    if (canBeStarted) return onStartCupping()

    onCuppingNow()
  }

  const onReview = () => {

    onReviewCuppingSession()
  }

  const handleRightClick = (event) => {

    onClickRightReviewCuppingSession(event)
  };

  const editCuppingSession = () => {
    navigate(`/cupping-sessions/${uniqueToken}`)
  }

  const handlePrintAllLabel = () => {
    onPrintAllLabel(samples);
  }

  const renderMenuItems = (
    <>
      <IconButton
        size="small"
        color="primary"
        onClick={(event) => setAnchorMenu(event.currentTarget)}
        className={clsx(classes.iconMore)}
      >
        <MoreHoriz />
      </IconButton>

      <Menu
        anchorEl={anchorMenu}
        open={openMenu}
        onClose={() => setAnchorMenu(null)}
        onClick={() => setAnchorMenu(null)}
      >
        {isPublic &&
          <MenuItem sx={{display: {sm: 'block', md: 'none'}}} disabled={canNotBeEdited} onClick={onPlayOrPause}>
            <MenuIcon><SvgIcon component={isPaused ? IconPlayCircle : IconPauseCircle} inheritViewBox /></MenuIcon>
            {t(`cuppingSession.${isPaused ? 'start' : 'pause'}`)}
          </MenuItem>
        }
        <Tooltip title={tooltipCanNotBeEdited} placement='left' arrow>
          <span>
            <MenuItem sx={{display: {sm: 'block', md: 'none'}}} disabled={canNotBeEdited} onClick={editCuppingSession}>
              <MenuIcon><SvgIcon component={IconEdit} inheritViewBox /></MenuIcon>
              {t('common.edit')}
            </MenuItem>
          </span>
        </Tooltip>
          <MenuItem sx={{display: {sm: 'block', md: 'none'}}} onClick={onReviewCuppingSession}>
            <MenuIcon><SvgIcon component={IconReview} inheritViewBox /></MenuIcon>
            {t('cuppingSession.review')}
          </MenuItem>
        {!canNotResendToWebhook &&
          <MenuItem disabled={canNotResendToWebhook} onClick={onResendWebhook}>
            <MenuIcon><CloudUpload /></MenuIcon>
            {t('cuppingSession.sendToWebhook')}
          </MenuItem>
        }
        <Tooltip title={reasonCanNotBeDeleted || ''} placement='left' arrow>
          <span>
            <MenuItem disabled={canNotBeDeleted} onClick={onRemoveCuppingSession}>
              <MenuIcon><DeleteOutline /></MenuIcon>
              {t('common.remove')}
            </MenuItem>
          </span>
        </Tooltip>

        {isPublic &&
          <MenuItem onClick={onPrintQrCode}>
            <MenuIcon><QrCode2Icon/></MenuIcon>
            {t('sample.printQRCode')}
          </MenuItem>
        }

        {isPublic &&
          <MenuItem onClick={onSettingQrCode}>
            <MenuIcon><SettingsIcon /></MenuIcon>
            {t('cuppingSession.setting.qrCodeSetting')}
          </MenuItem>
        }

        <MenuItem onClick={handlePrintAllLabel}>
          <MenuIcon><IconLabel /></MenuIcon>
          {t('cuppingSession.setting.printAllLabel')}
        </MenuItem>
      </Menu>
    </>
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    onSelectItem(id);
  };

  const renderCheckBox = () => {
    return (
      <Grid item xs={1}>
        <Box textAlign="center">
          <Checkbox
            sx={isMobile ? {paddingLeft: 0} : {}}
            checked={isSelected}
            onChange={handleChange}
          />
        </Box>
      </Grid>
    )
  }

  const renderGridItem = (label, value, gridMd = 1, options: any = {}) => {
    return (
      <Grid item xs={12} md={gridMd} sx={{cursor: 'pointer'}} {...options?.sx} onClick={onReview} onContextMenu={(event)=>handleRightClick(event)}>
        <Grid container spacing={1}>
          <Grid item xs={3} className={classes.gridHeader}>{label}</Grid>
          <Grid item xs={9} md={12} className={clsx(classes.gridItem, options?.left && 'left')}>
            {value}
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderMdGridItem = (
    <Grid item md={5} sx={{display: {xs: 'none', md: 'block'}}}>
      <Grid container sx={{textAlign: 'center'}} alignItems='center'>
        <Grid item xs={2}>
          <Tooltip title={addSampleInfoTooltipMessage || ''} arrow>
            <span>
              <IconButton
                size='small'
                color='primary'
                disabled={canNotAddSampleInfo}
                className={clsx('bg', classes.iconButton)}
                onClick={onEditSampleInfo}
              >
                <SvgIcon component={IconEdit} inheritViewBox />
              </IconButton>
            </span>
          </Tooltip>
          <Typography variant='body3' sx={{ml: 0.5}}>
            {`${samplesWithInfoCount}/${samplesCount}`}
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{wordBreak: 'initial'}}>
          <Tooltip title={reasonCanNotCupNow || ''} arrow>
            <span>
              <Button
                size='small'
                variant='contained'
                disabled={!canBeStarted && canNotCupNow}
                startIcon={<SvgIcon component={IconStartCupping} fontSize='medium' inheritViewBox />}
                onClick={cuppingNow}
                className={classes.cupNowButton}
              >
                {canBeStarted ? t('cuppingSession.start') : t('cuppingSession.cupNow')}
              </Button>
            </span>
          </Tooltip>
        </Grid>
        <Grid item xs={2}>
          <Tooltip title={tooltipCanNotBeEdited} arrow>
            <span>
              <IconButton
                size='small'
                color='primary'
                disabled={canNotBeEdited}
                onClick={editCuppingSession}
                className={clsx('bg', classes.iconButton)}
              >
                <SvgIcon component={IconEdit} inheritViewBox />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
        <Grid item xs={2}>
          <IconButton
            size='small'
            color='primary'
            onClick={onReviewCuppingSession}
            className={clsx('bg', classes.iconButton)}
          >
            <SvgIcon component={IconReview} inheritViewBox />
          </IconButton>
        </Grid>
        <Grid item xs={2}>
          {isPublic &&
            <IconButton
              size='small'
              color={isPaused ? 'secondary' : 'primary'}
              disabled={canNotBeEdited}
              onClick={onPlayOrPause}
              className={clsx('bg', classes.iconButton, isPaused && 'paused')}
            >
              <SvgIcon
                component={isPaused ? IconPlayCircle : IconPauseCircle}
                inheritViewBox
              />
            </IconButton>
          }
        </Grid>
        <Grid item xs={1}>
          {renderMenuItems}
        </Grid>
      </Grid>
    </Grid>
  )

  const renderXsGridItem = (
    <>
      {renderGridItem(
        t('cuppingSession.samplesInformation'),
        `${samplesWithInfoCount}/${samplesCount}`,
        2,
        {sx: {display: {xs: 'block', md: 'none'}}}
      )}

      <Grid item xs={12} sx={{display: {xs: 'block', md: 'none'}}}>
        <Stack direction='row' spacing={2} sx={{my: 2}}>
          <Tooltip title={addSampleInfoTooltipMessage || ''} arrow>
            <span className={classes.tooltipHalfWidth}>
              <Button
                fullWidth
                variant='outlined'
                disabled={canNotAddSampleInfo}
                startIcon={<SvgIcon component={IconEdit} inheritViewBox />}
                onClick={onEditSampleInfo}
              >
                {t('cuppingSession.editInformation')}
              </Button>
            </span>
          </Tooltip>
          <Tooltip title={reasonCanNotCupNow || ''} arrow>
            <span className={classes.tooltipHalfWidth}>
              <Button
                fullWidth
                variant='contained'
                disabled={!canBeStarted && canNotCupNow}
                startIcon={<SvgIcon component={IconStartCupping} inheritViewBox />}
                onClick={cuppingNow}
              >
                {canBeStarted ? t('cuppingSession.start') : t('cuppingSession.cupNow')}
              </Button>
            </span>
          </Tooltip>
        </Stack>
      </Grid>

      <Grid item xs={12} className={classes.buttonWrapper}>
        {renderMenuItems}
      </Grid>
    </>
  )

  return (
    <Card className={classes.wrapper}>
      <Grid container spacing={1} rowSpacing={2} alignItems='center'>
        <Grid item xs={12} md={3}>
          <Grid container spacing={1} rowSpacing={2}>
            {renderCheckBox()}
            {renderGridItem(t('cuppingSession.id'), numberForCompany, 2, {sx: { display: 'flex', alignItems:'center'}})}
            {renderGridItem(t('cuppingSession.owner'), ownerName, 4, {sx: { display: 'flex', alignItems:'center'}})}
            {renderGridItem(t('cuppingSession.time'), moment(startsAt).format(formatDateWithTime), 5, {sx: { display: 'flex', alignItems:'center'}})}
          </Grid>
        </Grid>

        {renderGridItem(t('cuppingSession.name'), truncate(name, {'length': 70}), 2, {left: true})}
        {renderGridItem(t('cuppingSession.location'), location, 1, {left: true})}
        {renderGridItem(t('cuppingSession.cuppingForm'), t(`options.cuppingProtocol.${camelCase(cuppingProtocol)}`), 1, {left: true})}

        {renderMdGridItem}
        {renderXsGridItem}
      </Grid>
    </Card>
  )
}
