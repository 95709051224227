import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {useNavigate, useParams} from "react-router-dom";
import {ceil, cloneDeep} from "lodash";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button, Checkbox,
  Divider,
  Stack,
  SvgIcon,
  Typography
} from "@mui/material";
import {Delete, Edit, ExpandMore, Visibility} from "@mui/icons-material";
import {isMobile} from "react-device-detect";

import {BasicTable, ModalConfirm} from "components";
import {useStores} from "models";
import {emptyGreenGrading, IconDefects} from "assets/images";
import {optionsTranslation} from "utils";

import {GreenGradingOptionsModal} from "./green-grading-options-modal/green-grading-options";
import {GreenGradingModal} from "./green-grading-modal/green-grading-modal";
import {useStyles} from "pages/sample-details/sample-details.styles";
import { ReportConfigurationModal } from "components/report-configuration-modal/report-configuration-modal";

const columnHelper = createColumnHelper<any>()

export const GreenGradings = observer((props: any) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const params = useParams()
  const navigate = useNavigate()
  const { isReport, selectedGreenGrading, setSelectedGreenGrading } = props

  const {
    masterSampleStore: { getCuppingSessionSamples },
    greenGradingStore: { page, totalPage, greenGradings, getGreenGradings, getGreenGrading, removeGreenGrading, selectedGreenGrading: selectedGreenGradingTmp,setValue: setGreenGradingValue },
    modalStore: { greenGrading, setValue: setModalValue }
  } = useStores()

  const [isOpenReportConfiguration, setOpenReportConfiguration] = useState<boolean>(false);
  const [navigateUrl, setNavigateUrl] = useState<string>('');
  const [isFetching, setIsFetching] = useState(false)
  const [sampleId, setSampleId] = useState('')
  const data = cloneDeep(greenGradings)
  const [openConfirmDelete, setConfirmDelete] = useState(false)

  const handleOpenReportConfigurationModal = () => {
    setOpenReportConfiguration(true);
  }

  const handleCloseReportConfigurationModal = () => {
    setOpenReportConfiguration(false);
    loadGreenGradings()
  }

  const renderButtons = (row) => {
    return (
      <Stack direction="row" spacing={1} justifyContent="center">
        <Button
          fullWidth={isMobile}
          variant="contained"
          onClick={() => {
            getGreenGrading(row.id)
            setNavigateUrl(`/green-gradings/${row.id}`);
            handleOpenReportConfigurationModal();
          }}
          startIcon={<Visibility />}
          className={classes.button}
        >
          {isMobile && t('sample.button.preview')}
        </Button>
        <Button
          fullWidth={isMobile}
          variant="contained"
          onClick={() => {
            setGreenGradingValue('selectedGreenGrading', row)
            setModalValue("greenGrading", true)
          }}
          startIcon={<Edit />}
          className={classes.button}
        >
          {isMobile && t('common.edit')}
        </Button>
        <Button
          fullWidth={isMobile}
          variant="contained"
          onClick={() => {
            setGreenGradingValue('selectedGreenGrading', row)
            setConfirmDelete(true)
          }}
          startIcon={<Delete />}
          className={classes.button}
        >
          {isMobile && t('common.delete')}
        </Button>
      </Stack>
    )
  }

  const columns = [
    ...(isReport ? [
      columnHelper.accessor('id', {
        id: 'id',
        header: () => <Box />,
        cell: info => {
          const value = info.renderValue().toString()
          const isSelected = selectedGreenGrading === value

          return (
            <Box textAlign="left">
              <Checkbox
                disabled={selectedGreenGrading && !isSelected}
                checked={isSelected}
                onChange={() => setSelectedGreenGrading(isSelected ? '' : value)}
              />
            </Box>
          )
        }
      }),
    ] : []),
    columnHelper.accessor('sequenceNumber', {
      id: 'sequenceNumber',
      header: () => <Box textAlign="left">{t('sample.id')}</Box>,
      cell: info => <Box fontWeight={600}>{info.renderValue()}</Box>,
    }),
    columnHelper.accessor('author', {
      id: 'author',
      header: () => <Box textAlign="left">{t('sample.detail.gradedBy')}</Box>,
      cell: info => <Box fontWeight={600}>{info.renderValue()}</Box>,
    }),
    columnHelper.accessor('createdAt', {
      id: 'createdAt',
      header: () => <Box textAlign="left">{t('sample.detail.gradedOn')}</Box>,
      cell: info => <Box>{moment(info.renderValue()).format('L')}</Box>,
    }),
    columnHelper.accessor('screenSize', {
      id: 'screenSize',
      header: () => <Box textAlign="left">{t('sample.detail.screenSize')}</Box>,
      cell: info => <Box>16/19 - {ceil(info.renderValue())}%</Box>,
    }),
    columnHelper.accessor('smell', {
      id: 'smell',
      header: () => <Box textAlign="left">{t('sample.detail.smell')}</Box>,
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor('color', {
      id: 'color',
      header: () => <Box textAlign="left">Color</Box>,
      cell: info => <Box>{info.renderValue() ? optionsTranslation('color', info.renderValue()) : ''}</Box>,
    }),
    columnHelper.accessor('moisture', {
      id: 'moisture',
      header: () => t('greenGrading.moisture'),
      cell: info => {
        return <Box textAlign="center">{info.renderValue()}%</Box>
      },
    }),
    columnHelper.accessor('total', {
      id: 'total',
      header: () => t('sample.detail.totalDefects'),
      cell: info => {
        const total = JSON.parse(info.renderValue())
        return <Box textAlign="center">{total.all}</Box>
      },
    }),
    ...(isReport ? [] : [
      columnHelper.accessor('id', {
        id: 'id',
        header: () => 'Action',
        cell: info => renderButtons(info.row.original),
      })
    ])
  ]

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })


  const renderMobile = (row) => {
    const total = JSON.parse(row.total)

    return (
      <Accordion key={row.id} className={classes.accordion}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box>
            <Typography variant='h6'>{row.author}</Typography>
            <Stack
              direction="row"
              spacing={0.5}
              divider={<Divider orientation="vertical" flexItem sx={{borderColor: "#999FA4"}} />}
            >
              <Box display="flex" alignItems="center">
                <SvgIcon component={IconDefects} inheritViewBox color="primary" sx={{mr: 0.5}} />
                <Typography variant="h6">{total.all}</Typography>
              </Box>
              <Typography variant="body2" color="#999FA4">16/19 - {optionsTranslation('cuppingProtocol', row.cuppingProtocol)}</Typography>
            </Stack>
          </Box>
        </AccordionSummary>
        <AccordionDetails className="content">
          <table cellPadding={2}>
            <tr>
              <td>{t('sample.detail.gradedOn')}</td>
              <td>{moment(row.createdAt).format('L')}</td>
            </tr>
            <tr>
              <td>{t('sample.detail.smell')}</td>
              <td>{row.smell}</td>
            </tr>
            <tr>
              <td>{t('greenGrading.color')}</td>
              <td>{optionsTranslation('color', row.color)}</td>
            </tr>
          </table>

          {renderButtons(row)}
        </AccordionDetails>
      </Accordion>
    )
  }

  const renderContent = () => {
    if (isMobile) {
      return data.map((item) => renderMobile(item))
    }

    return (
      <BasicTable
        table={table}
        page={page}
        totalPage={totalPage}
        isFetching={isFetching}
        onChangePage={(_, p) => setGreenGradingValue('page', p)}
        {...(isReport && {paginationStyle: {mb: 0}})}
      />
    )
  }

  const loadGreenGradings = async () => {
    try {
      setIsFetching(true)
      await getGreenGradings(params.id)
    } finally {
      setIsFetching(false)
    }
  }

  const deleteGreenGrading = async (id) => {
    try {
      setIsFetching(true)
      await removeGreenGrading(id)
      await getGreenGradings(params.id)
    } finally {
      setConfirmDelete(false)
      setIsFetching(false)
    }
  }

  useEffect(() => {
    loadGreenGradings()
  }, [page])

  useEffect(() => {
    setGreenGradingValue('page', 1)
  }, [])

  if (isReport) return renderContent()

  return (
    <Box px={2} py={2.5}>
      <Box mb={2} textAlign="right">
        <Button
          size="small"
          variant="contained"
          onClick={async () => {
            await getCuppingSessionSamples(params.id)
            setModalValue("greenGradingOptions", true)
          }}
        >
          + {t('sample.detail.createGreenGrading')}
        </Button>
      </Box>

      {greenGradings.length > 0 ? renderContent()
        : (
          <Box py={4} textAlign="center">
            <img src={emptyGreenGrading} />
            <Typography mt={2} variant="h5">{t('sample.detail.emptyGreenGrading')}</Typography>
          </Box>
        )
      }

      <GreenGradingOptionsModal
        sampleId={sampleId}
        setSampleId={setSampleId}
      />

      <GreenGradingModal
        sampleId={sampleId}
        open={greenGrading}
        onClose={() => {
          setModalValue("greenGrading", false)
          loadGreenGradings()
          setSampleId('')
        }}
        onSubmit={(gd) => {
          setModalValue("greenGrading", false);
          setNavigateUrl(`/green-gradings/${gd.id}`);
          handleOpenReportConfigurationModal();
        }}
      />

      <ReportConfigurationModal
        isOpen={isOpenReportConfiguration}
        type="green-grading"
        navigateUrl={navigateUrl}
        onClose={handleCloseReportConfigurationModal}
      />

      <ModalConfirm
        open={openConfirmDelete}
        onClose={() => setConfirmDelete(false)}
        onCloseIcon={() => setConfirmDelete(false)}
        title={t('sample.detail.confirmDeleteGreenGrading.title')}
        description={t('sample.detail.confirmDeleteGreenGrading.description')}
        confirm={t('common.delete')}
        onConfirm={()=> deleteGreenGrading(selectedGreenGradingTmp.id)}
      />
    </Box>
  )
})
