import { Instance, SnapshotOut, types } from "mobx-state-tree"

import {
  withEnvironment,
  withGeneralError,
  withSetValue,
  withReset,
  withRootStore
} from "models/extensions"
import {defaultSampleProps} from "models/sample/default-sample.props";
import {withContractActions} from "./contract-action";
import {contractDetailPreProcessor} from "./contract.pre-processor";
import {sampleProps} from "../review-sample-store/sample-props";
import {sampleProps as sampleModelProps} from "../sample/sample.props";
import {filterProps} from "../review-sample-store/filter-props";
import {chain, isEmpty} from "lodash";

/**
 * Model description here for TypeScript hints.
 */
export const ContractStoreModel = types
  .model("ContractStore")
  .props({
    contractDetails: types.optional(types.model({
      id: types.string,
      averageScore: types.maybeNull(types.string),
      certification: types.maybeNull(types.string),
      coffeeProcessing: types.maybeNull(types.string),
      country: types.maybeNull(types.string),
      cropYear: types.maybeNull(types.string),
      numberOfUnit: types.maybeNull(types.string),
      producer: types.maybeNull(types.string),
      purchaseContractReference: types.maybeNull(types.string),
      sampleGrade: types.maybeNull(types.string),
      species: types.maybeNull(types.string),
      weight: types.maybeNull(types.string),
      aggregatedFlavorWheelGroups: types.optional(types.string, '{}'),
      aggregatedFlavorWheelOnlyFlavor: types.optional(types.string, '{}'),
      aggregatedFlavorWheelOnlyFragrance: types.optional(types.string, '{}'),
    }), contractDetailPreProcessor({})),
    samples: types.optional(types.array(types.model({
      averageScore: types.maybeNull(types.string),
      salesContract: types.string,
      customer: types.string,
      sampleName: types.string,
      sampleType: types.string,
      sampleUniqueNumber: types.string,
      topThreeDescriptors: types.string,
      id: types.maybeNull(types.string),
      latestCuppingSession: types.optional(types.model({
        uniqueToken: types.maybeNull(types.union(types.string, types.number))
      }), {}),
      latestGreenGrading: types.optional(types.model({
        id: types.maybeNull(types.union(types.string, types.number)),
        sequenceNumber: types.maybeNull(types.union(types.string, types.number)),
        screenSize: types.maybeNull(types.union(types.string, types.number)),
        total: types.optional(types.model({
          all: types.maybeNull(types.union(types.string, types.number)),
          primary: types.maybeNull(types.union(types.string, types.number)),
          secondary: types.maybeNull(types.union(types.string, types.number))
        }), {}),
      }), {}),
    })), []),
    salesContracts: types.optional(types.array(types.model({
      averageScore: types.maybeNull(types.string),
      country: types.string,
      customer: types.string,
      quantity: types.string,
      salesContractReference: types.string,
      sampleName: types.string,
      sampleType: types.string,
      sampleUniqueNumber: types.string,
      id: types.string,
    })), []),
    shipments: types.optional(types.array(types.model({
      customer: types.string,
      salesContract: types.string,
      sampleType: types.string,
      shipmentNumber: types.maybeNull(types.string),
      shippingDate: types.string,
      shippingStatus: types.string,
    })), []),
    sampleNonContract: types.optional(types.array(sampleProps), []),
    page: types.optional(types.number, 1),
    totalPage: types.optional(types.number, 0),
    records: types.optional(types.number, 0),
    nextUrl: types.maybe(types.string),
    search: types.optional(filterProps, {}),
    newSamples: types.optional(types.array(types.model({
      id: types.identifierNumber,
      ...sampleModelProps
    })), []),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .extend(withGeneralError)
  .extend(withSetValue)
  .extend(withReset)
  .extend(withContractActions)
  .views(self => ({
    get searchValues() {
      return chain(self.search)
        .omitBy(isEmpty)
        .assign({
          ...(self.search.generalSearch && {generalSearch: self.search.generalSearch}),
          ...(self.search.scoredByMe && {scoredByMe: self.search.scoredByMe})
        })
        .value()
    }
  }))

type ContractStoreType = Instance<typeof ContractStoreModel>
export interface ContractStore extends ContractStoreType {}
type ContractStoreSnapshotType = SnapshotOut<typeof ContractStoreModel>
export interface ContractStoreSnapshot extends ContractStoreSnapshotType {}
