import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography, Button, Box, Checkbox, FormControlLabel, Collapse } from '@mui/material';

import { ModalWrapper } from 'components';

import { ExportConfigurationModalProps } from './export-configuration-modal.props';
import { useStyles } from './export-configuration-modal.styles';
import { convertConfigurationData, saveConfigurationData } from './export-configuration-modal-utils';
import { IconArrowDown, IconArrowUp } from 'assets/images';

interface ExportConfigurationOption {
    label: string,
    value: boolean
}

export const ExportConfigurationModal: React.FC<ExportConfigurationModalProps> = (props: ExportConfigurationModalProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { isOpen, onClose, onExport } = props;

    const [sampleInformationConfiguration, setSampleInformationConfiguration] = useState<ExportConfigurationOption[]>([]);
    const [cuppingSummaryConfiguration, setCuppingSummaryConfiguration] = useState<ExportConfigurationOption[]>([]);
    const [cuppingDetailConfiguration, setCuppingDetailConfiguration] = useState<ExportConfigurationOption[]>([]);

    const [expandedSection, setExpandedSection] = useState<string | null>('sampleInformation');

    const toggleExpand = (section: string) => {
        setExpandedSection((prev) => (prev === section ? null : section));
    };

    const onExportData = async () => {
        const samppleInformationStore = sampleInformationConfiguration.reduce((acc, { label, value }) => {
            acc[label] = value;
            return acc;
        }, {});
        const cuppingSummaryStore = cuppingSummaryConfiguration.reduce((acc, { label, value }) => {
            acc[label] = value;
            return acc;
        }, {});
        const cuppingDetailStore = cuppingDetailConfiguration.reduce((acc, { label, value }) => {
            acc[label] = value;
            return acc;
        }, {});
        await saveConfigurationData(samppleInformationStore, cuppingSummaryStore, cuppingDetailStore);
        onExport({
            sampleInformationAttributes: samppleInformationStore,
            cuppingSummaryAttributes: cuppingSummaryStore,
            cuppingDetailedAttributes: cuppingDetailStore
        });
    };

    const initData = async () => {
        const { sampleInformation: sampleInformationConfiguration, cuppingSummary: cuppingSummaryConfiguration,
            cuppingDetail: cuppingDetailConfiguration } = await convertConfigurationData();
        setSampleInformationConfiguration(sampleInformationConfiguration);
        setCuppingSummaryConfiguration(cuppingSummaryConfiguration);
        setCuppingDetailConfiguration(cuppingDetailConfiguration);
    };

    useEffect(() => {
        if (isOpen) {
            initData();
        }
    }, [isOpen]);

    return (
        <ModalWrapper
            containerProps={{
                sx: {
                    width: '900px',
                },
            }}
            hideClose
            open={isOpen}
            onClose={onClose}
        >
            <Box className={classes.wrapper}>
                <Typography className={classes.title}>
                    {t('sample.samples.selectExportInformation')}
                </Typography>
                <Typography className={classes.description}>
                    {t('sample.samples.exportConfigurationDescription')}
                </Typography>

                <Box>
                    <Box className={classes.sectionTitle} onClick={() => toggleExpand('sampleInformation')}>
                        <Typography sx={{ cursor: 'pointer', fontWeight: '600' }}>
                            {t('sample.samples.sampleInformation')}
                        </Typography>
                        {expandedSection === 'sampleInformation' ? <IconArrowUp /> : <IconArrowDown />}
                    </Box>
                    <Collapse in={expandedSection === 'sampleInformation'}>
                        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', paddingTop: '8px' }}>
                            {sampleInformationConfiguration.map((item, index) => (
                                <FormControlLabel
                                    sx={{ height: '32px' }}
                                    key={index}
                                    label={t(`sample.${item.label}`)}
                                    control={
                                        <Checkbox
                                            checked={item.value}
                                            onChange={() =>
                                                setSampleInformationConfiguration((prev) => {
                                                    const updated = [...prev];
                                                    updated[index].value = !updated[index].value;
                                                    return updated;
                                                })
                                            }
                                        />
                                    }
                                />
                            ))}
                        </Box>
                    </Collapse>
                </Box>

                <Box>
                    <Box className={classes.sectionTitle} onClick={() => toggleExpand('cuppingSummary')}>
                        <Typography sx={{ cursor: 'pointer', fontWeight: '600' }}>
                            {t('sample.samples.cuppingSummary')}
                        </Typography>
                        {expandedSection === 'cuppingSummary' ? <IconArrowUp /> : <IconArrowDown />}
                    </Box>
                    <Collapse in={expandedSection === 'cuppingSummary'}>
                        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', paddingTop: '8px' }}>
                            {cuppingSummaryConfiguration.map((item, index) => (
                                <FormControlLabel
                                    sx={{ height: '32px' }}
                                    key={index}
                                    label={t(`sample.${item.label}`)}
                                    control={
                                        <Checkbox
                                            checked={item.value}
                                            onChange={() =>
                                                setCuppingSummaryConfiguration((prev) => {
                                                    const updated = [...prev];
                                                    updated[index].value = !updated[index].value;
                                                    return updated;
                                                })
                                            }
                                        />
                                    }
                                />
                            ))}
                        </Box>
                    </Collapse>
                </Box>

                <Box>
                    <Box className={classes.sectionTitle} onClick={() => toggleExpand('cuppingDetail')}>
                        <Typography sx={{ cursor: 'pointer', fontWeight: '600' }}>
                            {t('sample.samples.cuppingDetail')}
                        </Typography>
                        {expandedSection === 'cuppingDetail' ? <IconArrowUp /> : <IconArrowDown />}
                    </Box>
                    <Collapse in={expandedSection === 'cuppingDetail'}>
                        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', paddingTop: '8px' }}>
                            {cuppingDetailConfiguration.map((item, index) => (
                                <FormControlLabel
                                    sx={{ height: '32px' }}
                                    key={index}
                                    label={t(`sample.${item.label}`)}
                                    control={
                                        <Checkbox
                                            checked={item.value}
                                            onChange={() =>
                                                setCuppingDetailConfiguration((prev) => {
                                                    const updated = [...prev];
                                                    updated[index].value = !updated[index].value;
                                                    return updated;
                                                })
                                            }
                                        />
                                    }
                                />
                            ))}
                        </Box>
                    </Collapse>
                </Box>

                <Stack direction="row" justifyContent="center" spacing={2} mt={4}>
                    <Button
                        sx={{ height: '40px', minWidth: '185px' }}
                        variant="contained"
                        onClick={onExportData}
                    >
                        {t('sample.export')}
                    </Button>
                    <Button
                        sx={{ height: '40px', minWidth: '185px' }}
                        variant="outlined"
                        onClick={onClose}
                    >
                        {t('common.cancel')}
                    </Button>
                </Stack>
            </Box>
        </ModalWrapper>
    );
};
