import { SIMPPLIFIED_COMMON_CUPPING_SESSION_SAMPLES_STORAGE_KEY } from 'config/env';
import { loadString, saveString } from 'utils/storage';

  export const getInitialSimplifiedCommonCuppingData = async (
	selectedCuppingSessionId: string,
	apiSamples: any[]
  ) => {
	const dataString = await loadString(SIMPPLIFIED_COMMON_CUPPING_SESSION_SAMPLES_STORAGE_KEY);
	if (!dataString) {
	  return apiSamples;
	}
  
	const parsedData = JSON.parse(dataString);
	if (!parsedData[selectedCuppingSessionId]) {
	  return apiSamples;
	}
  
	const storeSamples = parsedData[selectedCuppingSessionId];
	
	const storeSamplesMap = new Map(storeSamples.map(sample => [sample.id, sample]));
	const apiSamplesMap = new Map(apiSamples.map(sample => [sample.id, sample]));
  
	const commonSamples: any = [];
	for (const [id, sample] of storeSamplesMap) {
	  if (apiSamplesMap.has(id)) {
		commonSamples.push(sample);
	  }
	}
  
	const newSamples: any = [];
	for (const [id, sample] of apiSamplesMap) {
	  if (!storeSamplesMap.has(id)) {
		newSamples.push(sample);
	  }
	}
  
	return [...commonSamples, ...newSamples];
  };

export const saveSimplifiedCommonCuppingData = async (selectedCuppingSessionId: string, modifiedSamples: any[]) => {
	const dataString = await loadString(SIMPPLIFIED_COMMON_CUPPING_SESSION_SAMPLES_STORAGE_KEY);
	if (!dataString) {
		await saveString(SIMPPLIFIED_COMMON_CUPPING_SESSION_SAMPLES_STORAGE_KEY, JSON.stringify({
			[selectedCuppingSessionId]: modifiedSamples
		}));
		return;
	}

	const parsedData = JSON.parse(dataString);
	parsedData[selectedCuppingSessionId] = modifiedSamples;

	await saveString(SIMPPLIFIED_COMMON_CUPPING_SESSION_SAMPLES_STORAGE_KEY, JSON.stringify(parsedData));
}

export const clearStoreSimplifiedCommonCuppingData = async (selectedCuppingSessionId: string) => {
	const dataString = await loadString(SIMPPLIFIED_COMMON_CUPPING_SESSION_SAMPLES_STORAGE_KEY);
	if (!dataString) {
		return;
	}

	const parsedData = JSON.parse(dataString);
	delete parsedData[selectedCuppingSessionId];
	await saveString(SIMPPLIFIED_COMMON_CUPPING_SESSION_SAMPLES_STORAGE_KEY, JSON.stringify(parsedData));
}