import React from "react"
import { uniqueId, upperFirst} from "lodash"
import {useTranslation} from "react-i18next"
import {Autocomplete, Box, Grid, TextField, Typography} from "@mui/material"
import {
	simplifiedCommonSelections,
	simplifiedCommonExpectations,
	simplifiedCommonSampleResults
} from "constants/form";
import { flavorDescriptorList } from 'constants/flavor-descriptors'
import { flatListDescriptors } from 'utils';


import { useStyles } from "./simplified-information.styles"

export const SimplifiedInformation = (
  {
    score,
    isEdit,
    setIsEditScore,
  }: {
    score: any,
    isEdit: boolean,
    setIsEditScore: () => void,
  }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { flavorDescriptors, acidityNote, sweetnessNote, physicalAspectDescription, 
    physicalAspectRate, overallNote, result, internalNotes, setWithoutScoreValue, totalScore
  } = score

  const [selectedValues, setSelectedValues] = React.useState(
    flavorDescriptors.map(label => ({
      id: uniqueId(),
      value: label,
      label: upperFirst(label),
    }))
  );
  const commonSelections = simplifiedCommonSelections.filter(item => item !== 'Phenol');
  const simplifiedFlavorDescriptorList = (flatListDescriptors(flavorDescriptorList) || []).map((item) => ({
      id: item.id,
      value: item.label,
      label: upperFirst(item.label),
  }));
  
  const [flavorDescriptorsTmp, setFlavorDescriptorsTmp] = React.useState([flavorDescriptors]);

  const [inputValueScore, setInputValueScore] = React.useState(totalScore ? totalScore.toString() : '0');

  const [valueAcidity, setValueAcidity,] = React.useState<string | null>(acidityNote);
  const [inputValueAcidity, setInputValueAcidity,] = React.useState('')

  const [valueSweetness, setValueSweetness] = React.useState<string | null>(sweetnessNote);
  const [inputValueSweetness, setInputValueSweetness] = React.useState('');

  const [valuePhysicalAspectRate, setValuePhysicalAspectRate] = React.useState<string | null>(physicalAspectRate);
  const [inputValuePhysicalAspectRate, setInputValuePhysicalAspectRate] =  React.useState('');

  const [valueOverallNote, setValueOverallNote] = React.useState<string | null>(overallNote);
  const [inputValueOverallNote, setInputValueOverallNote] =  React.useState('');

  const [valueResult, setValueResult] = React.useState<string | null>(result);
  const [inputValueResult, setInputValueResult] =  React.useState('');

  const [inputValuePhysicalAspectDescription, setInputPhysicalAspectDescription] = React.useState(physicalAspectDescription);

  const [inputValueInternalNotes, setInputInternalNotes] = React.useState(internalNotes);

  const setValueData = (key, value) => {
		setWithoutScoreValue(key, value)
    setIsEditScore()
  }

  const handleUpdateData = (newValue) => {
    // Extract labels from newValue
    let updatedLabels = newValue.map((option) =>
      typeof option === "string"
        ? upperFirst(option)
        : upperFirst(option.label)
    )

      // Remove duplicates
      updatedLabels = [...new Set(updatedLabels)]

      // Find newly added items
      const newItems = updatedLabels.filter(
        (label) => !flavorDescriptors.includes(label)
      )

      // Update `flavorDescriptors` only if new items exist
      if (newItems.length > 0) {
        setFlavorDescriptorsTmp((prev) => {
          const newItems = [...new Set([...prev, ...updatedLabels])] // Merge new items
      
          // First, clear the value
          setValueData("flavorDescriptors", [])
      
          // Then, update with the new items
          setValueData("flavorDescriptors", newItems.flat())
          
          return newItems
        })
      }

    // Convert updated labels back to objects for selectedValues
    setSelectedValues(
      updatedLabels.map((label) => ({
        id: uniqueId(),
        value: label,
        label: label,
      }))
    )
  }

  const handleDelete = (valueToDelete) => {
    setSelectedValues((prev) =>
      prev.filter((item) => item.value !== valueToDelete)
    )
    // Update flavorDescriptorsTmp and ensure data is properly set
    setFlavorDescriptorsTmp((prev) => {
      const updatedList = prev.filter((label) => label !== valueToDelete)

      // **Clear the value first**
      setValueData("flavorDescriptors", [])

      // **Then set the updated list**
      setValueData("flavorDescriptors", updatedList.flat())

      return updatedList
    })
  }

  const renderComponentAutocompleteFlavor = () =>{
    return(
      <Box display="flex" alignItems="center" width="100%" position='relative'>
         <Autocomplete
            multiple
            disableClearable
            id="fixed-tags-demo"
            value={selectedValues}
            onChange={(event, newValue) => {
              handleUpdateData(newValue)
            }}
            options={simplifiedFlavorDescriptorList}
            getOptionLabel={(option) => option.label}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => {
                return (
                  <>{index > 0 ? ', ' : ''}{option.label}</>
                );
              })
            }
            style={{ width: 500 }}
            renderInput={(params) => (
              <TextField
                {...params}
                onKeyDown={(event) => {
                  // Detect backspace when input is empty and selectedValues has items
                  if (
                    event.key === "Backspace" &&
                    params.inputProps.value === "" &&
                    selectedValues.length > 0
                  ) {
                    const lastItem = selectedValues[selectedValues.length - 1]
                    handleDelete(lastItem.value)
                  }
                }}
              />
            )}
          />
      </Box>
    )
  }

  const renderSimplifiedCommonSelect = (value, setValue, inputValue, setInputValue, key, options) => (
    <Box display="flex" alignItems="center" width="100%" position="relative">
      <Autocomplete
        value={value}
        disableClearable
        onChange={(event, newValue) => {
          setValue(newValue);
          setValueData(key, newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        options={options}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} />}
      />
    </Box>
  )

  const renderSimplifiedCommonTextInput = (value,setValue, key) => (
    <Box display="flex" alignItems="center" width="100%" position="relative">
    <TextField 
      fullWidth
      value={value}
      onChange={(e) => {
        setValue(e.target.value)
        setValueData(key , e.target.value)
      }}
    />
    </Box>
  )

  const renderSimplifiedCommonNumberInput = (value, setValue, key) => (
    <Box display="flex" alignItems="center" width="100%" position="relative">
      <TextField
        type="number"
        fullWidth
        value={value}
        inputProps={{ step: "0.01", min: "0" }}
        onChange={(e) => {
          const score = e.target.value ? parseFloat(parseFloat(e.target.value).toFixed(2)) : 0;
          setValue(score.toString())
          setValueData(key, score)
        }}
      />
    </Box>
  )

  const renderComponentScore = (key) =>
    renderSimplifiedCommonNumberInput(inputValueScore, setInputValueScore, key);

  const renderComponentAutocompleteAcidity = (key) =>
    renderSimplifiedCommonSelect(valueAcidity, setValueAcidity, inputValueAcidity, setInputValueAcidity, key, commonSelections)
  
  const renderComponentAutocompleteSweetness = (key) =>
    renderSimplifiedCommonSelect(valueSweetness, setValueSweetness, inputValueSweetness, setInputValueSweetness, key, commonSelections)

  const renderComponentPhysicalAspectRate = (key) =>
    renderSimplifiedCommonSelect(valuePhysicalAspectRate, setValuePhysicalAspectRate, inputValuePhysicalAspectRate, setInputValuePhysicalAspectRate, key, simplifiedCommonExpectations)

  const renderComponentAutocompleteOverallNote = (key) =>
    renderSimplifiedCommonSelect(valueOverallNote, setValueOverallNote, inputValueOverallNote, setInputValueOverallNote, key, simplifiedCommonExpectations)

  const renderComponentAutocompleteResult = (key) =>
    renderSimplifiedCommonSelect(valueResult, setValueResult, inputValueResult, setInputValueResult, key, simplifiedCommonSampleResults)

  const renderComponentPhysicalAspectDescription = (key) =>
    renderSimplifiedCommonTextInput(inputValuePhysicalAspectDescription, setInputPhysicalAspectDescription, key)

  const renderComponentInternalNote= (key) =>
    renderSimplifiedCommonTextInput(inputValueInternalNotes, setInputInternalNotes, key)
  
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box className={classes.scoreWrapper}>
          <Box width="30%">
            <Typography variant="body2">{t('descriptor.score')}</Typography>
          </Box>
          <Box className={classes.descriptor}>
            {isEdit ? renderComponentScore('totalScore') : <Typography className={classes.descriptorText} variant="body2">{totalScore}</Typography>}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.scoreWrapper}>
          <Box width="30%">
            <Typography variant="body2">{t('descriptor.flavor')}</Typography>
          </Box>
          <Box className={classes.descriptor}>
            {isEdit ? renderComponentAutocompleteFlavor() : <Typography className={classes.descriptorText} variant="body2">{flavorDescriptors.join(", ")}</Typography>}           
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.scoreWrapper}>
          <Box width="30%">
            <Typography variant="body2">{t('descriptor.acidity')}</Typography>
          </Box>
          <Box className={classes.descriptor}>
            {isEdit ? renderComponentAutocompleteAcidity("acidityNote") :  <Typography className={classes.descriptorText} variant="body2">{acidityNote}</Typography>}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.scoreWrapper}>
          <Box width="30%">
            <Typography variant="body2">{t('descriptor.sweetness')}</Typography>
          </Box>
          <Box className={classes.descriptor}>
          {isEdit ? renderComponentAutocompleteSweetness("sweetnessNote") : <Typography className={classes.descriptorText} variant="body2">{sweetnessNote}</Typography>}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.scoreWrapper}>
          <Box width="30%">
            <Typography variant="body2">{t('descriptor.physicalAspectRate')}</Typography>
          </Box>
          <Box className={classes.descriptor}>
            {isEdit ? renderComponentPhysicalAspectRate("physicalAspectRate") : <Typography className={classes.descriptorText} variant="body2">{physicalAspectRate}</Typography>}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.scoreWrapper}>
          <Box width="30%">
            <Typography variant="body2">{t('descriptor.overallExpectation')}</Typography>
          </Box>
          <Box className={classes.descriptor}>
            {isEdit ? renderComponentAutocompleteOverallNote("overallNote") : <Typography className={classes.descriptorText} variant="body2">{overallNote}</Typography>}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.scoreWrapper}>
          <Box width="30%">
            <Typography variant="body2">{t('descriptor.sampleResult')}</Typography>
          </Box>
          <Box className={classes.descriptor}>
            {isEdit ? renderComponentAutocompleteResult("result") : <Typography className={classes.descriptorText} variant="body2">{result}</Typography>}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.scoreWrapper}>
          <Box width="30%">
            <Typography variant="body2">{t('descriptor.physicalAspectDescription')}</Typography>
          </Box>
          <Box className={classes.descriptor}>
            {isEdit ? renderComponentPhysicalAspectDescription("physicalAspectDescription") : <Typography className={classes.descriptorText} variant="body2">{physicalAspectDescription}</Typography>}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.scoreWrapper}>
          <Box width="30%">
            <Typography variant="body2">{t('descriptor.internalNotes')}</Typography>
          </Box>
          <Box className={classes.descriptor}>
            {isEdit ? renderComponentInternalNote("internalNotes") : <Typography className={classes.descriptorText} variant="body2">{internalNotes}</Typography>}
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}
